import React from 'react';

const AppointmentNavigation = ({ startOver, back }) => {
        return (
                <div className="row" id="appointment-navigation">
                    <div className="col-6"><button className="btn btn-link" onClick={back}>&lsaquo; Back</button></div>
                    <div className="col-6 text-right"><button className="btn btn-link" onClick={startOver}>&laquo; Start Over</button></div>
                </div>
        );
}

AppointmentNavigation.defaultProps = {}

export default AppointmentNavigation;