import API from "./API";

const getCalendars = async () => {
	    return await API.get(process.env.REACT_APP_API_BASE_URL + "/calendars/all");
}

const CalendarService = {
	    getCalendars,
}

export default CalendarService;