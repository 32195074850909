import React, { Fragment} from 'react';

const UtilizationPeriodReport = () => {
    return (
        <Fragment>
        </Fragment>
    );
}

UtilizationPeriodReport.defaultProps = {}

export default UtilizationPeriodReport;