import API from "./API";

const getTelehealthGroups = async () => {
    return await API.get(process.env.REACT_APP_API_BASE_URL + "/telehealthgroups/groups");
}

const getProviderTelehealthGroups = async(providerId) => {
    return await API.get(process.env.REACT_APP_API_BASE_URL + "/telehealthgroups/providergroups/" + providerId);
}

const getTelehealthGroupById = async (groupId) => {
    return await API.get(process.env.REACT_APP_API_BASE_URL + "/telehealthgroups/groups/" + groupId)
}

const updateTelehealthGroup = async (telehealthGroup) => {
    return await API.patch(process.env.REACT_APP_API_BASE_URL + "/telehealthgroups/telehealthgroup", telehealthGroup);
}

const addTelehealthGroup = async (telehealthGroup) => {
    return await API.post(process.env.REACT_APP_API_BASE_URL + "/telehealthgroups/add", telehealthGroup);
}


const TelehealthGroupService = {
    getTelehealthGroups,
    getProviderTelehealthGroups,
    getTelehealthGroupById,
    updateTelehealthGroup,
    addTelehealthGroup,
}

export default TelehealthGroupService;