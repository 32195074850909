import React, { Fragment, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";

const MedicalHistorySurgeries = ({ appointmentSurgeries, handleSetAppointmentSurgeries, handleNextClick }) => {
	const [surgeries, setSurgeries] = useState([{ description: "" }]);
	const [noSelection, setNoSelection] = useState(false);

	useEffect(() => {
		if (appointmentSurgeries) {
			setSurgeries(appointmentSurgeries);
		}
	}, [appointmentSurgeries]);

	const addSurgery = () => {
		const surgeryList = surgeries.concat({ description: "" });
		handleSetAppointmentSurgeries(surgeryList);
	};

	function handleChange(index, event) {
		const values = [...surgeries];
		if (event.target.name === "description") {
			values[index].description = event.target.value;
		}

		handleSetAppointmentSurgeries(values);
	}

	const handleRemoveSurgery = (index) => {
		const values = [...surgeries];
		values.splice(index, 1);
		handleSetAppointmentSurgeries(values);
	};

	const handleSelectNone = (event) => {
		let isChecked = event.target.checked;
		setNoSelection(isChecked);

		if (isChecked) {
			handleSetAppointmentSurgeries([{ description: "" }]);
		}
	};

	return (
		<div id="appointment-surgeries">
			<h1 className="my-3">Have you had any surgeries?</h1>

			<ul className="list-group my-5">
				<li className={`list-group-item ${noSelection && "active"}`}>
					<div className="form-check">
						<input type="checkbox" className="form-check-input" onChange={handleSelectNone} /> 
						<label className="form-check-label">No surgeries</label>
					</div>					
				</li>
			</ul>

			{!noSelection && (
				<Fragment>
					{surgeries.map((surgery, index) => (
						<AddSurgery
							handleRemove={handleRemoveSurgery}
							onChange={(event) => handleChange(index, event)}
							surgery={surgery}
							index={index}
							key={index}
						/>
					))}

					<p>
						<button className="btn btn-outline-primary btn-block" onClick={addSurgery}>
							+ Add Surgery
						</button>
					</p>
				</Fragment>
			)}
			<p className="my-3">
				<button className="btn btn-primary btn-block" onClick={handleNextClick}>
					NEXT &raquo;
				</button>
			</p>
		</div>
	);
};

const AddSurgery = ({ onChange, handleRemove, surgery, index }) => (

	<div className="form-row" id="surgery-list">
		<div className="form-group col-12">
			<div className="input-group">
				<input
					type="text"
					placeholder="Surgery description"
					className="form-control"
					name="description"
					value={surgery.description}
					onChange={onChange}
				/>
				<div className="input-group-append">
							
					<button className="btn" onClick={() => handleRemove(index)}>
						<FontAwesomeIcon icon={faWindowClose} style={{ color: "#FC4C4C" }} />
					</button>
					
				</div>
			</div>
		</div>
	</div>

);

MedicalHistorySurgeries.defaultProps = {};

export default MedicalHistorySurgeries;
