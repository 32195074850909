import React from "react";

const Footer = () => {
  return (

      <footer className="footer mt-5 bg-dark pt-3">
        <div className="container">
          <p className="text-white pt-2">
            <img src="https://app.archerhealth.com/img/logo_white.svg" alt="Archer Health" width="150" />
          </p>
        </div>
        <div className="copyright py-3">
          <div className="container">
          <small className="text-white">&copy; 2023 Archer Health. All rights reserved.</small>
          </div>
        </div>
      </footer>

  );
};

Footer.defaultProps = {};

export default Footer;
