import React, { Fragment} from 'react';

const PrivacyPolicy = () => {
	return (
		<Fragment>
			<div className="card my-3">
				<div className="card-body">
					<h5 className="card-title text-center">Notice of Privacy Practices</h5>
					<div className="small">
						<p className="text-center font-weight-bold">ARCHER HEALTH</p>

						<p className="text-center font-weight-bold">
							Effective Date: January 19, 2021
						</p>

						<p>
							THIS PRIVACY NOTICE (THE “NOTICE”) DESCRIBES HOW MEDICAL INFORMATION ABOUT YOU MAY BE
							USED AND
							DISCLOSED AND HOW YOU CAN GET ACCESS TO THIS INFORMATION. PLEASE REVIEW IT CAREFULLY.
						</p>

						<p>
							Archer Health (“we” or “us”) creates a record of the care and services furnished to
							you in order to
							provide you with medical care. This record contains protected health information,
							which is protected
							under the Health Insurance Portability and Accountability Act (“HIPAA”). We are
							required by law to
							maintain the privacy of your protected health information, provide you with this
							Notice describing
							our legal duties regarding your protected health information, abide by the terms of
							this Notice, and
							to notify you in the event of any breach of your unsecured protected health
							information.
						</p>

						<p>
							Generally, protected health information is health information, including demographic
							information,
							collected from you or created or received by a health care provider, a health care
							clearinghouse, a
							health plan, or your employer on behalf of a group health plan, from which it is
							possible to
							individually identify you and that relates to: (1) your past, present, or future
							physical or mental
							health or condition; (2) the provision of health care to you; or (3) the past,
							present, or future
							payment for the provision of health care to you.
						</p>

						<p>
							We reserve the right to make this Notice effective for all protected health
							information we maintain,
							and to change the terms of this Notice and to make new provisions regarding your
							protected health
							information as allowed or required by law. If we make any material change to this
							Notice, we will
							provide you with a copy of our revised Notice. A copy of our current Notice will be
							maintained on
							our website.
						</p>

						<p>
							If you have any questions regarding your privacy or any of the information contained
							in this Notice,
							please contact our Privacy Officer, Cole Hawkins (615) 767-6336.
						</p>

					</div>
				</div>
			</div>


</Fragment>
)
	;
}

export default PrivacyPolicy;