import React, { Fragment, useState, useEffect } from "react";
import AddDependent from "../Add";
import AccountService from "../../../../services/account.service";
import AppointmentService from '../../../../services/appointment.service';
import moment from "moment";
import { useHistory } from "react-router-dom";
import * as CONSTANT from "../../../../constants/constants"
import FlashMessage from "../../../FlashMessage";
import AuthService from "../../../../services/auth.service";

const SelectDependent = () => {
	const [patients, setPatients] = useState([]);
	const [selectedPatient, setSelectedPatient] = useState("");
	const [flashMessage, setFlashMessage] = useState("");
	const [flashMessageType, setFlashMessageType] = useState("");
    
    const history = useHistory();

	const handleAddNewPatient = (addPatient) => {
		let patient = {};
		patient.birthday = moment(addPatient.dob).format("MM-DD-YYYY");
		patient.firstName = addPatient.firstName;
		patient.lastName = addPatient.lastName;
		patient.gender = addPatient.gender;

		AccountService.addAccountPatient(patient).then((res) => {
			setPatients(res.data);
			setSelectedPatient(res.data[0]._id);

			setFlashMessage("Patient added to account.");
			setFlashMessageType(CONSTANT.ALERT_TYPE.SUCCESS);
		}).catch((error) => {
			const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

			setFlashMessage(resMessage);
			setFlashMessageType(CONSTANT.ALERT_TYPE.ERROR);
		});		
    };
    
    const handleCancelAddNewPatient = () => {
        setSelectedPatient("");
    }



	useEffect(() => {
		AccountService.getAccountPatients().then((res) => {
			setPatients(res.data);
		});

		let appointment = AppointmentService.getSessionAppointment();
		if (appointment) {
			setSelectedPatient(appointment.patient._id)			
		}
	}, []);

	useEffect(() => {
		AuthService.getPatientsByAccount(selectedPatient).then((patients) => {})
	})

	return (
		<Fragment>
			{selectedPatient !== "add-new" && (
				<Fragment>
					<h1>Patient Information</h1>

					{flashMessage.length > 0 && <FlashMessage message={flashMessage} messageType={flashMessageType} />}

					<div className="form-group">
						<select className="form-control" value={selectedPatient} onChange={(evt) => setSelectedPatient(evt.target.value)}>
							<option value="">- Select Patient -</option>
							{patients.map((patient) => (
								<option value={patient._id} key={patient._id}>
									{patient.fullName}
								</option>
							))}
							<option value="add-new">Add New</option>
						</select>
					</div>

					<p>
						<button className="btn btn-primary" onClick={() => history.push(`/book-appointment/${selectedPatient}`)} disabled={selectedPatient === ""}>Continue</button>
					</p>
				</Fragment>
			)}

			{selectedPatient === "add-new" && <AddDependent addPatient={handleAddNewPatient} cancel={handleCancelAddNewPatient} />}

			{/*<pre className="bg-dark text-white mt-5">{JSON.stringify(selectedPatient, null, 2)}</pre>*/}
		</Fragment>
	);
};

SelectDependent.defaultProps = {};

export default SelectDependent;
