import React, { Fragment } from "react";
import useForm from "../../../useForm";
import validate from "./AddPatientValidationRules";

const AddDependent = ({ addPatient, cancel }) => {
	const sendPatientData = () => {
		addPatient(values);
    };
    
    const handleCancel = () => {
        cancel();
    }

	const { values, errors, handleChange, handleSubmit } = useForm(sendPatientData, validate);
	return (
		<Fragment>
			<h3>Additional Information</h3>

			<div className="form-group">
				<label>First name</label>
				<input
					type="text"
					name="firstName"
					className={`form-control ${errors.firstName && "is-invalid"}`}
					value={values.firstName || ""}
					onChange={handleChange}
				/>
				<div className="invalid-feedback">{errors.firstName}</div>
			</div>

			<div className="form-group">
				<label>Last name</label>
				<input
					type="text"
					name="lastName"
					className={`form-control ${errors.lastName && "is-invalid"}`}
					value={values.lastName || ""}
					onChange={handleChange}
				/>
				<div className="invalid-feedback">{errors.lastName}</div>
			</div>

			<div className="form-group">
				<label>Date of birth</label>
				<input
					type="date"
					name="dob"
					className={`form-control ${errors.dob && "is-invalid"}`}
					value={values.dob || ""}
					onChange={handleChange}
				/>
				<div className="invalid-feedback">{errors.dob}</div>
			</div>

			<div className="form-group">
				<label>Gender</label>
				<select
					name="gender"
					className={`form-control ${errors.gender && "is-invalid"}`}
					value={values.gender || ""}
					onChange={handleChange}>
					<option value="">
						- Select -
					</option>
					<option value="male">Male</option>
					<option value="female">Female</option>
					<option value="unspecified">Other</option>
				</select>
				<div className="invalid-feedback">{errors.gender}</div>
			</div>

			<p>
				<button className="btn btn-primary" onClick={handleSubmit}>
					Save
				</button>

                <button className="btn btn-link" onClick={handleCancel}>Cancel</button>
			</p>

			{/*<pre className="bg-dark text-white mt-5">{JSON.stringify(values, null, 2)}</pre>*/}
		</Fragment>
	);
};

AddDependent.defaultProps = {};

export default AddDependent;
