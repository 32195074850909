import React, { Fragment} from 'react';
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Loader = ({show, message}) => {
    return (
        <Fragment>
            {show &&
                <p>
                    <FontAwesomeIcon icon={faCircleNotch} spin /> <em>{message}</em>
                </p>
            }
        </Fragment>
    );
}

Loader.defaultProps = {
    show: false,
    message: "Loading..."
}

export default Loader;