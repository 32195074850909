import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ReportService from "../../../../services/reports.service";
import AppointmentService from "../../../../services/appointment.service";
import FlashMessage from "../../../FlashMessage";
import * as CONSTANT from "../../../../constants/constants";
import { PieChart, Pie, Cell, Legend, BarChart, XAxis, YAxis, Bar, LabelList } from "recharts";
import Loader from "../../../UIHelpers/Loader";
import { format, addDays } from "date-fns";
import { enUS } from "date-fns/locale";
import parseISO from "date-fns/parseISO";

const UtilizationYTDReport = ({ currentUser }) => {
	const params = useParams();

	const [flashMessage, setFlashMessage] = useState("");
	const [flashMessageType, setFlashMessageType] = useState("");
	const [loading, setLoading] = useState(false);

	const [report, setReport] = useState(null);
	const [totalSavings, setTotalSavings] = useState(0);

	const [genderData, setGenderData] = useState([]);
	const [modalityData, setModalityData] = useState([]);
	const [visitReasonData, setVisitReasonData] = useState([]);
	const [locationData, setLocationData] = useState([]);

	const [locationLookup, setLocationLookup] = useState([]);

	//const [serviceCodes, setServiceCodes] = useState([]);
	//const [serviceAddOns, setServiceAddOns] = useState([]);

	const colors = [
		"#19B8E8",
		"#9515c4",
		"#158466",
		"#00A933",
		"#81D41A",
		"#FFFF00",
		"#FFAE42",
		"#FF6600",
		"#FF4500",
		"#FF0000",
		"#C71585",
		"#8A2BE2",
		"#6600FF",
	];

	const currencyOptions = { style: "currency", currency: "USD" };

	const RADIAN = Math.PI / 180;
	const customPercentageLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
		const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
		const x = cx + radius * Math.cos(-midAngle * RADIAN);
		const y = cy + radius * Math.sin(-midAngle * RADIAN);

		return (
			<text x={x} y={y} fill="white" textAnchor={x > cx ? "start" : "end"} dominantBaseline="central">
				{`${(percent * 100).toFixed(0)}%`}
			</text>
		);
	};

	/*useEffect(() => {
		AppointmentService.getAll().then((appointments) => {
			
			var employerAppointments = appointments.data.filter((appointment) => appointment.employer.id === params.employerId && (appointment.serviceCodes?.length > 0 || appointment.serviceAddons?.length > 0));
			var serviceCodes = [];
			employerAppointments.forEach(appointment => {
				serviceCodes = serviceCodes.concat(appointment.serviceCodes);
			});
			var serviceAddOns = [];
			employerAppointments.forEach((appointment) =>{
				serviceAddOns = serviceAddOns.concat(appointment.serviceAddons);
			});
			setServiceCodes(serviceCodes);
			setServiceAddOns(serviceAddOns);
		});
	}, [params.employerId]);*/

	useEffect(() => {
		AppointmentService.getLocations(params.employerId)
			.then((response) => {
				setLocationLookup(response.data);
			})
			.catch((error) => {
				const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

				setFlashMessage(resMessage);
				setFlashMessageType(CONSTANT.ALERT_TYPE.ERROR);
			});
	}, [params.employerId]);

	useEffect(() => {
		setLoading(true);

		ReportService.utilizationYTD(params.employerId, params.year)
			.then((response) => {
				setReport(response.data);
			})
			.catch((error) => {
				const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

				setFlashMessage(resMessage);
				setFlashMessageType(CONSTANT.ALERT_TYPE.ERROR);
			})
			.finally(() => setLoading(false));
	}, [params.employerId, params.year]);

	useEffect(() => {
		if (!report) return;

		if (report.alternatives["My primary care doctor"] === undefined) {
			report.alternatives["My primary care doctor"] = 0;
		}
		if (report.alternatives["A specialist"] === undefined) {
			report.alternatives["A specialist"] = 0;
		}
		if (report.alternatives["An  urgent care or walk-in clinic"] === undefined) {
			report.alternatives["An  urgent care or walk-in clinic"] = 0;
		}
		if (report.alternatives["The emergency room"] === undefined) {
			report.alternatives["The emergency room"] = 0;
		}

		let primary = report.alternatives["My primary care doctor"] * 220 + report.alternatives["My primary care doctor"] * 141;
		let specialist = report.alternatives["A specialist"] * 450 + report.alternatives["A specialist"] * 141;
		let urgent =
			report.alternatives["An  urgent care or walk-in clinic"] * 150 + report.alternatives["An  urgent care or walk-in clinic"] * 141;
		let er = report.alternatives["The emergency room"] * 1500 + report.alternatives["The emergency room"] * 141;

		let totalSavings = primary + specialist + urgent + er;

		setTotalSavings(totalSavings);

		let genderData = [
			{
				name: "Male",
				value: report.genderMaleAppts,
			},
			{
				name: "Female",
				value: report.genderFemaleAppts,
			},
		];

		setGenderData(genderData);

		if (locationLookup.length > 0) {
			let data = [];

			for (const key in report.locationUtilization) {
				let locationName = locationLookup.find(({ calendarID }) => calendarID.toString() === key)?.location || "No location";
				data.push({
					name: locationName.substring(0, 25),
					value: report.locationUtilization[key],
				});
			}

			setLocationData(data);
		}

		let modData = [
			{
				name: "On-Site",
				value: report.appointments,
			},
			{
				name: "On-Demand",
				value: report.onDemand,
			},
		];

		setModalityData(modData);

		//Gather totals
		let cat1 = ["Prescription Refill"];
		let cat2 = ["Biometric Screening (Please fast for 8 hours)", "Physical"];
		let cat3 = ["Acute Care", "Work-Related Injury", "Non-Work-Related Injury"];
		let cat4 = ["Primary Care"];
		let cat5 = ["Wellness Coaching"];
		let cat6 = ["Other", "Influenza Vaccine", "COVID-19 Test"];

		let cat1Total = 0;
		let cat2Total = 0;
		let cat3Total = 0;
		let cat4Total = 0;
		let cat5Total = 0;
		let cat6Total = 0;

		cat1.forEach((value) => {
			if (report[value]) {
				if (!isNaN(parseInt(report[value]))) {
					cat1Total = cat1Total + parseInt(report[value]);
				}
			}
		});

		cat2.forEach((value) => {
			if (report[value]) {
				if (!isNaN(parseInt(report[value]))) {
					cat2Total += parseInt(report[value]);
				}
			}
		});

		cat3.forEach((value) => {
			if (report[value]) {
				if (!isNaN(parseInt(report[value]))) {
					cat3Total += parseInt(report[value]);
				}
			}
		});

		cat4.forEach((value) => {
			if (report[value]) {
				if (!isNaN(parseInt(report[value]))) {
					cat4Total += parseInt(report[value]);
				}
			}
		});

		cat5.forEach((value) => {
			if (report[value]) {
				if (!isNaN(parseInt(report[value]))) {
					cat5Total += parseInt(report[value]);
				}
			}
		});

		cat6.forEach((value) => {
			if (report[value]) {
				if (!isNaN(parseInt(report[value]))) {
					cat6Total += parseInt(report[value]);
				}
			}
		});

		let reasonData = [
			{
				name: "Prescription Refill",
				value: cat1Total,
			},
			{
				name: "Checkup",
				value: cat2Total,
				include: "Biometric Screening, Physical",
			},
			{
				name: "Illness/Injury",
				value: cat3Total,
				include: "Acute Care, Work-Related Injury, Non-Work-Related Injury",
			},
			{
				name: "Primary Care",
				value: cat4Total,
			},
			{
				name: "Wellness Coaching",
				value: cat5Total,
			},
			{
				name: "Other",
				value: cat6Total,
				includes: "Influenza Vaccine, Other, COVID-19 Test",
			},
		];

		setVisitReasonData(reasonData);
	}, [report, locationLookup]);

	const copyTableData = () => {
		alert("copy");

		const elTable = document.querySelector("#admin-table-data");

		let range, sel;

		// Ensure that range and selection are supported by the browsers
		if (document.createRange && window.getSelection) {
			range = document.createRange();
			sel = window.getSelection();
			// unselect any element in the page
			sel.removeAllRanges();

			try {
				range.selectNodeContents(elTable);
				sel.addRange(range);
			} catch (e) {
				range.selectNode(elTable);
				sel.addRange(range);
			}

			document.execCommand("copy");
		}

		sel.removeAllRanges();
	};

	return (
		<Fragment>
			{flashMessage.length > 0 && <FlashMessage message={flashMessage} messageType={flashMessageType} />}

			<Loader show={loading} message="Loading Report..." />

			{report && (
				<div>
					<div className="card shadow-sm mb-3">
						<div className="card-body">
							<h5>Annualized Utilization</h5>
							<p>
								Annualized utilization represents the percentage of employees who are projected to utilize the on-site clinic
								over the course of one year, based on YTD data.
							</p>
							<table className="table text-center">
								<tbody>
									<tr>
										<td>
											<h5>Total Visits</h5>
											{report.totalVisits} x 12
											<hr />
											{report.periods.length} periods
										</td>
										<td>/</td>
										<td>
											<h5>Avg. YTD Employees</h5>
											{report.averageLives}
										</td>
										<td>=</td>
										<td>
											<h5>Projected Annualized Utilization</h5>
											<h2>{report.YTDUtilization} %</h2>
										</td>
									</tr>
								</tbody>
							</table>
							<small>Annualized Utilization % - (YTD Visits x 12 / # months accrued) / YTD average employees </small>
						</div>
					</div>

					<hr />

					<div className="card shadow-sm mb-3">
						<div className="card-body">
							<h5>Period Utilization</h5>
							<p>
								Period utilization represents the percentage of the employee population who used the on-site clinic during the
								period.
							</p>
							<table className="table">
								<thead>
									<tr>
										<td>Month</td>
										<td>Total Visits</td>
										<td>% Utilization</td>
									</tr>
								</thead>
								<tbody>
									{report.periods.map((period) => {
									var iso = parseISO(period.start);
									var adjustedPeriodStart = addDays(iso, 1);
									
									
									return (
										<tr key={period.start}>
											<td>{format(adjustedPeriodStart, "MMM yyyy", { locale: enUS })}</td>
											<td align="center">{period.appointments + period.ondemand}</td>
											<td align="center">
												{(((period.appointments + period.ondemand) / report.averageLives) * 100).toFixed(2)}%
											</td>
										</tr>
									)})}
								</tbody>
							</table>
						</div>
					</div>

					<hr />

					<div style={{ display: "block", pageBreakBefore: "always" }}></div>

					<div className="card shadow-sm mb-3">
						<div className="card-body">
							<h5>Care Redirection Savings</h5>
							<table className="table table-striped">
								<thead>
									<tr>
										<td colSpan="5">12 Month Redirection Savings</td>
									</tr>
									<tr>
										<td>Where your employees say they would have gone:</td>
										<td>Visits</td>
										<td>Episode of Care Savings</td>
										<td>Productivity Savings*</td>
										<td>Savings</td>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>Office Visit</td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
									</tr>
									<tr>
										<td>Primary Care Physician</td>
										<td>{report.alternatives["My primary care doctor"]}</td>
										<td>$220</td>
										<td>$141</td>
										<td>
											{new Intl.NumberFormat("en-US", currencyOptions).format(
												report.alternatives["My primary care doctor"] * 220 +
													report.alternatives["My primary care doctor"] * 141
											)}
										</td>
									</tr>
									<tr>
										<td>Specialist</td>
										<td>{report.alternatives["A specialist"]}</td>
										<td>$450</td>
										<td>$141</td>
										<td>
											{new Intl.NumberFormat("en-US", currencyOptions).format(
												report.alternatives["A specialist"] * 450 + report.alternatives["A specialist"] * 141
											)}
										</td>
									</tr>
									<tr>
										<td>Urgent Care</td>
										<td>{report.alternatives["An  urgent care or walk-in clinic"]}</td>
										<td>$150</td>
										<td>$141</td>
										<td>
											{new Intl.NumberFormat("en-US", currencyOptions).format(
												report.alternatives["An  urgent care or walk-in clinic"] * 150 +
													report.alternatives["An  urgent care or walk-in clinic"] * 141
											)}
										</td>
									</tr>
									<tr>
										<td>Emergency Room</td>
										<td>{report.alternatives["The emergency room"]}</td>
										<td>$1500</td>
										<td>$141</td>
										<td>
											{new Intl.NumberFormat("en-US", currencyOptions).format(
												report.alternatives["The emergency room"] * 1500 +
													report.alternatives["The emergency room"] * 141
											)}
										</td>
									</tr>
									<tr>
										<td>Nowhere</td>
										<td>{report.alternatives["Nowhere"]}</td>
										<td></td>
										<td>$141</td>
										<td></td>
									</tr>
									<tr>
										<td>Total</td>
										<td>{report.totalVisits}</td>
										<td></td>
										<td></td>
										<td>
											<strong>{new Intl.NumberFormat("en-US", currencyOptions).format(totalSavings)}</strong>
										</td>
									</tr>
								</tbody>
							</table>
							<p>
								*Note the productivity estimate is calculated by multiplying an estimated 3 hours saved per redirected visit
								by an average hourly wage of $29.18 (based on NIH data) multiplied by the average wage multiplier of 1.61 (the
								cost of an employer of an absence as a proportion of the hourly wage) and the number of redirected visits,
								excluding visits where the employee indicated that they would not have received care.
							</p>
						</div>
					</div>
					<hr />

					<div style={{ display: "block", pageBreakBefore: "always" }}></div>

					<h5>Utilization by Gender</h5>
					<PieChart width={500} height={500}>
						<Legend verticalAlign="top" height={36} />
						<Pie data={genderData} label={customPercentageLabel} labelLine={false} legendType="square" dataKey="value">
							{genderData.map((entry, index) => (
								<Cell key={`cell-${index}`} fill={colors[index]} />
							))}
						</Pie>
					</PieChart>
					<hr />

					<h5>Utilization by Location</h5>
					<PieChart width={800} height={500}>
						<Legend alphabetic align="left" verticalAlign="bottom" />
						<Pie data={locationData} label labelLine={true} legendType="square">
							{locationData.map((entry, index) => (
								<Cell key={`cell-${index}`} fill={colors[index]} />
							))}
						</Pie>
					</PieChart>

					<hr />

					<div style={{ display: "block", pageBreakBefore: "always" }}></div>

					<h5>Utilization by Reason for Visit</h5>
					<BarChart width={800} height={500} data={visitReasonData}>
						<XAxis dataKey="name" />
						<YAxis />
						<Bar dataKey="value" fill="#19B8E8">
							<LabelList position="top" />
						</Bar>
					</BarChart>

					<hr />

					<h5>Utilization by Visit Type</h5>
					<PieChart width={500} height={500}>
						<Legend verticalAlign="top" height={36} />
						<Pie data={modalityData} label={customPercentageLabel} labelLine={false} legendType="square">
							{modalityData.map((entry, index) => (
								<Cell key={`cell-${index}`} fill={colors[index]} />
							))}
						</Pie>
					</PieChart>

					{currentUser.role === "admin" && (
						<div className="card my-4">

							<table className="table">
								<thead>
								<tr>
									<th>Service Codes</th>
									<th>Description</th>
									<th>Times Reported</th>
									<th>Low Est.</th>
									<th>High Est.</th>
									<th>Median Cost/Savings</th>
								</tr>
								</thead>
								<tbody>
								{report.serviceCodes.map((code, index) => (

									<tr key={`code-${index}`}>
										<td>{code.code}</td>
										<td>{code.description}</td>
										<td>{code.count}</td>
										<td>{code.low}</td>
										<td>{code.high}</td>
										<td>{((code.high + code.low) / 2) * code.count}</td>
									</tr>

								))}
								</tbody>
							</table>


							<table className="table">
								<thead>
								<tr>
									<th>Service Add-Ons</th>
									<th>Description</th>
									<th>Times Reported</th>
									<th>Low Est.</th>
									<th>High Est.</th>
									<th>Median Cost/Savings</th>
								</tr>
								</thead>
								<tbody>
								{report.serviceAddons.map((code, index) => (

									<tr key={`code-${index}`}>
										<td>{code.code}</td>
										<td>{code.description}</td>
										<td>{code.count}</td>
										<td>{code.low}</td>
										<td>{code.high}</td>
										<td>{((code.high + code.low) / 2) * code.count}</td>
									</tr>

								))}
								</tbody>
							</table>


							<p>
								<button className="btn btn-primary btn-block" onClick={() => copyTableData()}>
									Copy Table Data
								</button>
							</p>
							<table id="admin-table-data" className="table table-bordered">
								<thead>
								<tr>
									<th>Total Visits</th>
									<th>Avg YTD Employees</th>
									<th>Number of Periods</th>
									<th>Projected Annulized Utilization</th>
									<th>Period Utilization</th>
									<th>Utilization by Location</th>
									<th>Utilization by Gender</th>
									<th>Redirection Savings</th>
									<th>Utilization by Reason for Visit</th>
									<th>Utilization by Visit Type</th>
								</tr>
								</thead>
								<tbody>
								<tr>
									<td>{report.totalVisits}</td>
									<td>{report.averageLives}</td>
									<td>{report.periods.length}</td>
									<td>{report.YTDUtilization}</td>
									<td>
										<table className="table table-sm">
											<tbody>
											{report.periods.map((period) => (
												<tr key={period.start}>
													<td>
														{format(parseISO(period.start), "MMM yyyy", { locale: enUS })}
													</td>
													<td align="center">{period.appointments + period.ondemand}</td>
													<td align="center">
														{(
															((period.appointments + period.ondemand) /
																report.averageLives) *
															100
														).toFixed(2)}
														%
													</td>
												</tr>
											))}
											</tbody>
										</table>
									</td>
									<td>
										<table>
											<tbody>
											{locationData.map((entry, index) => (
												<tr key={index}>
													<td>{entry.name}</td>
													<td>{entry.value}</td>
												</tr>
											))}
											</tbody>
										</table>
									</td>
									<td>
										<table>
											<tbody>
											{genderData.map((entry, index) => (
												<tr>
													<td>{entry.name}</td>
													<td>{entry.value}</td>
												</tr>
											))}
											</tbody>
										</table>
									</td>
									<td>
										<table className="table table-sm">
											<thead>
											<tr>
												<td colSpan="5">12 Month Redirection Savings</td>
											</tr>
											<tr>
												<td>Where your employees say they would have gone:</td>
												<td>Visits</td>
												<td>Episode of Care Savings</td>
												<td>Productivity Savings*</td>
												<td>Savings</td>
											</tr>
											</thead>
											<tbody>
											<tr>
												<td>Office Visit</td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
											</tr>
											<tr>
												<td>Primary Care Physician</td>
												<td>{report.alternatives["My primary care doctor"]}</td>
												<td>$220</td>
												<td>$141</td>
												<td>
													{new Intl.NumberFormat("en-US", currencyOptions).format(
														report.alternatives["My primary care doctor"] * 220 +
														report.alternatives["My primary care doctor"] * 141
													)}
												</td>
											</tr>
											<tr>
												<td>Specialist</td>
												<td>{report.alternatives["A specialist"]}</td>
												<td>$450</td>
												<td>$141</td>
												<td>
													{new Intl.NumberFormat("en-US", currencyOptions).format(
														report.alternatives["A specialist"] * 450 +
														report.alternatives["A specialist"] * 141
													)}
												</td>
											</tr>
											<tr>
												<td>Urgent Care</td>
												<td>{report.alternatives["An  urgent care or walk-in clinic"]}</td>
												<td>$150</td>
												<td>$141</td>
												<td>
													{new Intl.NumberFormat("en-US", currencyOptions).format(
														report.alternatives["An  urgent care or walk-in clinic"] * 150 +
														report.alternatives["An  urgent care or walk-in clinic"] *
														141
													)}
												</td>
											</tr>
											<tr>
												<td>Emergency Room</td>
												<td>{report.alternatives["The emergency room"]}</td>
												<td>$1500</td>
												<td>$141</td>
												<td>
													{new Intl.NumberFormat("en-US", currencyOptions).format(
														report.alternatives["The emergency room"] * 1500 +
														report.alternatives["The emergency room"] * 141
													)}
												</td>
											</tr>
											<tr>
												<td>Nowhere</td>
												<td>{report.alternatives["Nowhere"]}</td>
												<td></td>
												<td>$141</td>
												<td></td>
											</tr>
											</tbody>
										</table>
									</td>
									<td>
										<table>
											<tbody>
											{visitReasonData.map((entry, index) => (
												<tr>
													<td>{entry.name}</td>
													<td>{entry.value}</td>
												</tr>
											))}
											</tbody>
										</table>
									</td>
									<td>
										<table>
											<tbody>
											{modalityData.map((entry, index) => (
												<tr>
													<td>{entry.name}</td>
													<td>{entry.value}</td>
												</tr>
											))}
											</tbody>
										</table>
									</td>
								</tr>
								</tbody>
							</table>

						</div>
					)}
				</div>
			)}
		</Fragment>
	);
};

UtilizationYTDReport.defaultProps = {};

export default UtilizationYTDReport;
