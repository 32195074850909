import React, { Fragment, useEffect, useState } from "react";
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { TIMEZONE } from "../../../constants/constants";
import CalendarService from "../../../services/calendar.service";

const Summary = ({ oAppointment, employer }) => {
	const [calendarTimeZone, setCalendarTimeZone] = useState(TIMEZONE.CENTRAL);

	useEffect(() => {
		CalendarService.getCalendars()
			.then((res) => {
				if (res.data) {
					setCalendarTimeZone(res.data.find((calendar) => calendar.id === oAppointment?.location.calendarID)?.timezone || TIMEZONE.CENTRAL);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	}, [oAppointment.location?.calendarID]);

	return (
		<Fragment>
			{oAppointment && (
				<div id="appointment-summary" className="p-2">
					{/*TODO: Get Company from Profile */}
					Employer: {employer?.name}<br/>

					Appointment For: {oAppointment.patient?.firstName} {oAppointment.patient?.lastName}
					<br />
					{oAppointment.modality && (
						<Fragment>
							Get Care: {oAppointment.modality === "on-demand" ? "Telemedicine" : "Onsite Clinic"}
							<br />
						</Fragment>
					)}
					{oAppointment.product?.name && (
						<Fragment>
							Reason for Visit: {oAppointment.product?.name}
							<br />
						</Fragment>
					)}
					{oAppointment.datetime && (
						<Fragment>
							Date/Time: {format(utcToZonedTime(oAppointment.datetime, calendarTimeZone), "MMMM do, yyyy h:mm bbbb")}
							<br />
						</Fragment>
					)}
				</div>
			)}
		</Fragment>
	);
};

Summary.defaultProps = {};

export default Summary;
