import React, { Fragment, useState } from "react";
import useForm from "../useForm";
import validate from "./VerifyAccountValidationRules";
import AuthService from "../../services/auth.service";
import moment from "moment";
//import { useHistory } from "react-router-dom";
import * as CONSTANT from "../../constants/constants";
import FlashMessage from "../FlashMessage";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { addYears } from "date-fns";

const VerifyAccount = () => {
	const [account, setAccount] = useState({});
	const [buttonTitle, setButtonTitle] = useState("Continue");
	const [inputMaskType, setInputMaskType] = useState("password");
	const [flashMessage, setFlashMessage] = useState("");
	const [flashMessageType, setFlashMessageType] = useState("");

	//const history = useHistory();

	const handleCancel = (event) => {
		event.preventDefault();
		window.location.replace("/login");
	};

	const handleToggleShowPassword = () => {
		let maskType = inputMaskType === "password" ? "text" : "password";
		setInputMaskType(maskType);
	};

	const handleRegister = () => {	
		
		setFlashMessage("");
		setFlashMessageType("");

		//Search for account  matches pending activation
		if (!account._id) {
			let dob = values.dobYear + "/" + values.dobMonth + "/" + values.dobDay;
			values.birthday = moment(dob).format("MM-DD-YYYY");

			AuthService.findNewAccount(values)
				.then((response) => {
					setAccount(response.data.account);
					setButtonTitle("VERIFY ACCOUNT");

					setValues({
						...values,
						email: response.data.account.email,
						phone: response.data.account.mobile,
						accountId: response.data.account._id,
					});
				})
				.catch((error) => {
					const resMessage =
						(error.response && error.response.data && error.response.data.message) || error.message || error.toString();

					setFlashMessage(resMessage);
					setFlashMessageType(CONSTANT.ALERT_TYPE.ERROR);
				});
		}

		//Account exists, populate remaining data and finalize verification
		if (account._id) {						
			values.birthday = moment(values.dob).format("MM-DD-YYYY");
			values.mobile = values.phone.replaceAll("-", "");

			AuthService.register(values)
				.then((response) => {
					if (response.data.token) {
						sessionStorage.setItem("archer-user", JSON.stringify(response.data.profile));
						sessionStorage.setItem("archer-user-token", response.data.token);

						window.location.replace("/");
					}
				})
				.catch((error) => {
					const resMessage =
						(error.response && error.response.data && error.response.data.message) || error.message || error.toString();

					setFlashMessage(resMessage);
					setFlashMessageType(CONSTANT.ALERT_TYPE.ERROR);
				});
		}
	};

	const { setValues, values, errors, handleChange, handleSubmit } = useForm(handleRegister, validate);

	let years = [];
	for (var year = new Date().getFullYear() - 100; year <= new Date().getFullYear() - 16; year++) {
		years.push(year);
	}

	return (
		<Fragment>
			<h1>Verify Your Account</h1>

			<p className="alert alert-info">
				Have questions or need help? Please email <a href="mailto:tech@archerhealth.com?subject=Archer Health Website">tech@archerhealth.com</a>
			</p>


			{flashMessage.length > 0 && <FlashMessage message={flashMessage} messageType={flashMessageType} />}

			<form>
				<input type="hidden" name="accountId" value={account?._id || ""} />
				<div className="form-group">
					<label>
						Employer code <span className="text-danger">*</span>
					</label>
					<input
						type="text"
						name="companyCode"
						className={`form-control ${errors.companyCode && "is-invalid"}`}
						value={values.companyCode || ""}
						onChange={handleChange}
						disabled={account._id}
					/>
					<div className="invalid-feedback">{errors.companyCode}</div>
				</div>
				<div className="form-group">
					<label>
						Last name <span className="text-danger">*</span>
					</label>
					<input
						type="text"
						name="lastName"
						className={`form-control ${errors.lastName && "is-invalid"}`}
						value={values.lastName || ""}
						onChange={handleChange}
						disabled={account._id}
					/>
					<div className="invalid-feedback">{errors.lastName}</div>
				</div>
				<div className="form-group">
					<label>
						Date of birth <span className="text-danger">*</span>
					</label>
					{/*
					<input
						type="text"
						name="dob"
						placeholder="MM-DD-YYYY"
						className={`form-control ${errors.dob && "is-invalid"}`}
						value={values.dob || ""}
						onChange={handleChange}
						disabled={account._id}
					/>
					<div className="invalid-feedback">{errors.dob}</div>
					*/}
					<div className="form-row">
						<div className="col-lg-2 col-4">
							<select
								name="dobMonth"
								className={`form-control ${errors.dobMonth && "is-invalid"}`}
								value={values.dobMonth || ""}
								onChange={handleChange}
								disabled={account._id}>
								<option value="">Month</option>
								<option value="01">Jan</option>
								<option value="02">Feb</option>
								<option value="03">Mar</option>
								<option value="04">Apr</option>
								<option value="05">May</option>
								<option value="06">Jun</option>
								<option value="07">Jul</option>
								<option value="08">Aug</option>
								<option value="09">Sep</option>
								<option value="10">Oct</option>
								<option value="11">Nov</option>
								<option value="12">Dec</option>
							</select>
							<div className="invalid-feedback">{errors.dobMonth}</div>
						</div>

						<div className="col-lg-2 col-4">
							<select
								name="dobDay"
								className={`form-control ${errors.dobDay && "is-invalid"}`}
								value={values.dobDay || ""}
								onChange={handleChange}
								disabled={account._id}>
								<option value="">Day</option>
								<option value="01">01</option>
								<option value="02">02</option>
								<option value="03">03</option>
								<option value="04">04</option>
								<option value="05">05</option>
								<option value="06">06</option>
								<option value="07">07</option>
								<option value="08">08</option>
								<option value="09">09</option>
								<option value="10">10</option>
								<option value="11">11</option>
								<option value="12">12</option>
								<option value="13">13</option>
								<option value="14">14</option>
								<option value="15">15</option>
								<option value="16">16</option>
								<option value="17">17</option>
								<option value="18">18</option>
								<option value="19">19</option>
								<option value="20">20</option>
								<option value="21">21</option>
								<option value="22">22</option>
								<option value="23">23</option>
								<option value="24">24</option>
								<option value="25">25</option>
								<option value="26">26</option>
								<option value="27">27</option>
								<option value="28">28</option>
								<option value="29">29</option>
								<option value="30">30</option>
								<option value="31">31</option>
							</select>
							<div className="invalid-feedback">{errors.dobDay}</div>
						</div>

						<div className="col-lg-2 col-4">
							<select
								name="dobYear"
								className={`form-control ${errors.dobYear && "is-invalid"}`}
								value={values.dobYear || ""}
								onChange={handleChange}
								disabled={account._id}>
								<option value="">Year</option>
								{years.map((year) => (
									<option value={year} key={year}>{year}</option>
								))}
							</select>
							<div className="invalid-feedback">{errors.dobYear}</div>
						</div>
					</div>
				</div>
				{account._id && (
					<Fragment>
						<div className="form-group">
							<label>
								Phone <span className="text-danger">*</span>
							</label>
							<input
								type="text"
								name="phone"
								className={`form-control ${errors.phone && "is-invalid"}`}
								value={values.phone || ""}
								onChange={handleChange}
								disabled={account.mobile}
							/>
							<div className="invalid-feedback">{errors.phone}</div>
						</div>
						<div className="form-group">
							<label>Email</label>
							<input
								type="email"
								name="email"
								className={`form-control ${errors.email && "is-invalid"}`}
								value={values.email || ""}
								onChange={handleChange}
								disabled={account.email}
							/>
							<div className="invalid-feedback">{errors.email}</div>
						</div>

						<div className="form-group">
							<label>
								Create a password <span className="text-danger">*</span>
							</label>
							<div className="input-group">
								<input
									type={inputMaskType}
									name="password"
									className={`form-control ${errors.password && "is-invalid"}`}
									value={values.password || ""}
									onChange={handleChange}
								/>
								<div className="invalid-feedback">{errors.password}</div>

								<div className="input-group-append">
									<div className="input-group-text">
										<a href="#" onClick={() => handleToggleShowPassword()}>
											{inputMaskType === "password" && <FontAwesomeIcon icon={faEye} />}
											{inputMaskType === "text" && <FontAwesomeIcon icon={faEyeSlash} />}
										</a>
									</div>
								</div>
							</div>
							<small className="form-text text-muted">
								&bull; Use at least 10 characters
								<br />
								&bull; Use at least three of these four types of characters: lowercase, uppercase, digits, special characters
								(!@#$, etc.)
							</small>
						</div>

						<div className="card my-3">
							<div className="card-body">
								<h5 className="card-title">Notice of Privacy Practices</h5>
								<div className="small terms-text">
									<p className="text-center font-weight-bold">ARCHER HEALTH</p>

									<p className="text-center font-weight-bold">
										NOTICE OF PRIVACY PRACTICES
										<br />
										Effective Date: January 19, 2021
									</p>

									<p>
										THIS PRIVACY NOTICE (THE “NOTICE”) DESCRIBES HOW MEDICAL INFORMATION ABOUT YOU MAY BE USED AND
										DISCLOSED AND HOW YOU CAN GET ACCESS TO THIS INFORMATION. PLEASE REVIEW IT CAREFULLY.
									</p>

									<p>
										Archer Health (“we” or “us”) creates a record of the care and services furnished to you in order to
										provide you with medical care. This record contains protected health information, which is protected
										under the Health Insurance Portability and Accountability Act (“HIPAA”). We are required by law to
										maintain the privacy of your protected health information, provide you with this Notice describing
										our legal duties regarding your protected health information, abide by the terms of this Notice, and
										to notify you in the event of any breach of your unsecured protected health information.
									</p>

									<p>
										Generally, protected health information is health information, including demographic information,
										collected from you or created or received by a health care provider, a health care clearinghouse, a
										health plan, or your employer on behalf of a group health plan, from which it is possible to
										individually identify you and that relates to: (1) your past, present, or future physical or mental
										health or condition; (2) the provision of health care to you; or (3) the past, present, or future
										payment for the provision of health care to you.
									</p>

									<p>
										We reserve the right to make this Notice effective for all protected health information we maintain,
										and to change the terms of this Notice and to make new provisions regarding your protected health
										information as allowed or required by law. If we make any material change to this Notice, we will
										provide you with a copy of our revised Notice. A copy of our current Notice will be maintained on
										our website.
									</p>

									<p>
										If you have any questions regarding your privacy or any of the information contained in this Notice,
										please contact our Privacy Officer, Cole Hawkins (615) 767-6336.
									</p>

									<p className="font-weight-bold">
										<u>Summary of Important Information</u>
									</p>

									<p>
										<u>Acknowledgement of Privacy Practices:</u> We ask that you sign the attached acknowledgement form
										which states you have received this Notice.
									</p>

									<p>
										<u>Requirement for Written Authorization; Exceptions:</u> We will generally obtain your written
										permission before using your protected health information or sharing it with others outside of the
										care we provide to you or coordinate on your behalf. You may initiate transfer of your protected
										health information and medical records to another person by completing an authorization form. If you
										provide us with a written authorization, you may revoke that authorization at any time, except to
										the extent that we have already relied upon it. To revoke an authorization, please provide written
										notice of the revocation to our Privacy Officer.
									</p>

									<p>
										<u>Exceptions to Requirement for Written Authorization:</u> There are some situations when we do not
										need your written authorization before using your protected health information or sharing it with
										others. These situations include treatment, payment, health care operations, an emergency,
										communicating with your caregivers and family, and other circumstances described in more detail
										below.
										<br />
										<strong>
											<u>How Archer Health May Use Your Protected Health Information</u>
										</strong>
									</p>

									<p>
										Archer Health and its staff use your protected health information and share it with others in order
										to diagnose, evaluate, and treat your condition, as well as to run our normal business operations.
										Under HIPAA, we may use or disclose your protected health information without your permission for
										several reasons. Specific examples of how we may use this information without your authorization
										include:
									</p>

									<p>
										&bull; <u>Treatment:</u> We may use your protected health information to provide you with medical
										treatment or services. We may also disclose your protected health information to other health care
										providers, including doctors, nurses, and technicians, to assist them in caring for you. For
										example, we may share your protected health information with another health care provider to whom
										you have been referred for further care.
									</p>

									<p>
										&bull; <u>Payment:</u> We may use your protected health information or share it for payment
										purposes. For example, we may provide other health care providers involved in your care your
										protected health information to assist in their billing and payment activities.
									</p>

									<p>
										&bull; <u>Health Care Operations:</u> We may use your protected health information or share it with
										others in order to conduct our normal business operations, including measuring and improving
										quality, evaluating performance, conducting training, and conducting and arranging for medical
										review. For example, we may use information in your health record to assess the care and outcomes in
										your case and others like it. We may also share your protected health information with another
										company that performs business services for us. If so, we will have a written agreement to ensure
										that this company also protects the privacy of your protected health information.
									</p>

									<p>
										&bull; <u>Business Associates:</u> We may contract with individuals or entities known as Business
										Associates to perform various functions on our behalf or to provide certain types of services. In
										order to perform these functions or to provide these services, Business Associates will receive,
										create, maintain, transmit, use, and/or disclose your protected health information, but only after
										they agree in writing to implement appropriate safeguards regarding your protected health
										information. For example, we may disclose your protected health information to a Business Associate
										providing accounting services, but only after the Business Associate enters into a Business
										Associate contract with us.
									</p>

									<p>
										&bull; <u>Appointments, Treatment Alternatives, Benefits and Services:</u> We may use your protected
										health information when we contact you regarding the services provided to you or to provide certain
										types of services. We may use and disclose your protected health information to send you information
										about treatment alternatives or other health-related benefits and services that might be of interest
										to you.
									</p>

									<p>
										&bull; <u>As Required by Law:</u> We will disclose your protected health information when required
										to do so by federal, state, or local law. For example, we may disclose your protected health
										information when required by national security laws or public health disclosure laws.
									</p>

									<p>
										&bull; <u>To Avert a Serious Threat to Health or Safety:</u> We may use and disclose your protected
										health information when necessary to prevent a serious threat to your health and safety, or the
										health and safety of the public or another person. Any disclosure, however, would only be to someone
										able to help prevent the threat.{" "}
									</p>

									<p>
										&bull; <u>Public Health:</u> We may disclose your protected health information for public health
										activities and purposes to a public health authority that is permitted by law to collect or receive
										the information. The disclosure will be made for the purpose of controlling disease, injury or
										disability, including, but not limited to, the reporting of disease, injury, vital events such as
										birth or death, and the conduct of public health surveillance, public health investigations, and
										public health interventions. We may also disclose your protected health information, if directed by
										the public health authority, to a foreign government agency that is collaborating with the public
										health authority.
									</p>

									<p>
										&bull; <u>Communicable Diseases:</u> We may disclose your protected health information, if
										authorized by law, to a person who may have been exposed to a communicable disease or may otherwise
										be at risk of contracting or spreading the disease or condition.
									</p>

									<p>
										&bull; <u>Health Oversight:</u> We may disclose your protected health information to a health
										oversight agency for activities authorized by law, such as audits, investigations, and inspections.
										Oversight agencies seeking this information include government agencies that oversee the health care
										system, government benefits programs, other government regulatory programs and civil rights laws.
									</p>

									<p>
										&bull; <u>Abuse or Neglect:</u> We may disclose your protected health information to a public health
										authority that is authorized by law to receive reports of child abuse or neglect. In addition, we
										may disclose your protected health information if we believe that you have been a victim of abuse,
										neglect or domestic violence to the government entity or agency authorized to receive such
										information. In this case, the disclosure will be made consistent with the requirements of
										applicable federal and state laws.
									</p>

									<p>
										&bull; <u>Food and Drug Association:</u> We may disclose your protected health information to a
										person or company required by the Food and Drug Administration to report adverse events, product
										defects or problems, biologic deviations, track products, to enable product recalls, to make repairs
										or replacements, or to conduct marketing surveillance as required.{" "}
									</p>

									<p>
										&bull; <u>Organ and Tissue Donation:</u> If you are an organ donor, we may release your protected
										health information after your death to organizations that handle organ procurement or organ, eye, or
										tissue transplantation or to an organ donation bank, as necessary to facilitate organ or tissue
										donation and transplantation.
									</p>

									<p>
										&bull; <u>Legal Proceedings:</u> We may disclose your protected health information in the course of
										any judicial or administrative proceeding in response to an order of the court or administrative
										tribunal (to the extent such disclosure is expressly authorized), in certain conditions in response
										to a subpoena, discovery request or any other lawful process.
									</p>

									<p>
										&bull; <u>Law Enforcement:</u> We may disclose your protected health information, if applicable
										legal requirements are met, for law enforcement purposes, including if asked to do so by a
										law-enforcement official:
									</p>

									<p>
										&bull; in response to a court order, subpoena, warrant, summons, or similar process;
										<br />
										&bull; to identify or locate a suspect, fugitive, material witness, or missing person;
										<br />
										&bull; about the victim of a crime if, under certain limited circumstances, we are unable to obtain
										the victim’s agreement;
										<br />
										&bull; about a death that we believe may be the result of criminal conduct; and
										<br />
										&bull; about criminal conduct or activity.
									</p>

									<p>
										&bull; <u>Coroners and Funeral Directors:</u> We may disclose your protected health information to a
										coroner or medical examiner for identification purposes, determining cause of death or for the
										coroner or medical examiner to perform other duties as authorized by law. We also disclose your
										protected health information to a funeral director, as authorized by law, in order to permit the
										funeral director to carry out their duties. We may also disclose such information in reasonable
										anticipation of death.{" "}
									</p>

									<p>
										&bull; <u>National Security and Intelligence Activities or Protective Services:</u> We may disclose
										your protected health information to authorized federal officials who are conducting national
										security and intelligence activities or providing protective services to the President or other
										important officials.
									</p>

									<p>
										&bull; <u>Military and Veterans:</u> If you are a member of the military, we may disclose protected
										health information about you to appropriate military command authorities for activities they deem
										necessary to carry out their military mission. We may also release protected health information
										about foreign military personnel to the appropriate foreign military authority.
									</p>

									<p>
										&bull; <u>Workers’ Compensation:</u> We may release your protected health information for workers’
										compensation or similar programs, but only as authorized by, and to the extent necessary to comply
										with, laws relating to workers’ compensation and similar programs that provide benefits for
										work-related injuries or illness.
									</p>

									<p>
										&bull; <u>Inmates:</u> We may use or disclose your protected health information if you are an inmate
										of a correctional facility and your physician created or received your protected health information
										in the course of providing care to you.
									</p>

									<p>
										&bull; <u>Required Uses and Disclosures:</u> Under the law, we must make disclosures when required
										by the Secretary of the Department of Health and Human Services to investigate or determine our
										compliance with the requirements of federal law.
									</p>

									<p>
										&bull; <u>Research:</u> We may disclose your protected health information to researchers when their
										research has been approved by an institutional board that has reviewed the research proposal and
										established protocols to ensure the privacy of your protected health information or when the
										individual identifiers have been removed from your protected health information.
									</p>

									<p>
										&bull; <u>Personal Representatives:</u> We will disclose your protected health information to
										individuals authorized by you, to an individual acting as your personal representative under
										applicable state law, or to an individual designated as your personal representative,
										attorney-in-fact, etc., so long as you provide us with a written notice/authorization and any
										supporting documents (i.e., power of attorney). However, under the HIPAA privacy rule, we do not
										have to disclose information to a personal representative if we have a reasonable belief that:
									</p>

									<p>
										&bull; you have been, or may be, subjected to domestic violence, abuse, or neglect by such person;
										or
										<br />
										&bull; treating such person as your personal representative could endanger you; and
										<br />
										&bull; in the exercise of professional judgment, it is not in your best interest to treat the person
										as your personal representative.
									</p>

									<p>
										&bull; <u>Caregivers and Family Involved in Your Care:</u> If you do not object, we may share your
										health information with a family member, relative, or close friend who is involved in your care. We
										may also notify a family member, personal representative, or caregiver about your general condition,
										or about the unfortunate event of your death. In some cases, we may need to share your information
										with a disaster relief organization that will help us notify these persons.
									</p>

									<p>
										Uses or disclosures of your protected health information not described above will only be made with
										your written authorization. For example, in general and subject to specific conditions, we will not
										use or disclose your psychiatric notes, if any; we will not use or disclose your protected health
										information for marketing; and we will not sell your protected health information, unless you give
										us a written authorization. You may revoke written authorizations at any time, so long as the
										revocation is in writing, made to the Privacy Officer.{" "}
									</p>

									<p className="font-weight-bold">
										<u>Your Rights</u>
									</p>

									<p>You have the following rights with respect to your protected health information:</p>

									<p>
										&bull; <u>Right to Request Restrictions:</u> You have the right to request a restriction or
										limitation on your protected health information that we use or disclose for treatment, payment, or
										health care operations. You also have the right to request a limit on your protected health
										information that we disclose to someone who is involved in your care or the payment for your care,
										such as a family member or friend. Except as provided in the next paragraph, we are not required to
										agree to your request. However, if we do agree to the request, we will honor the restriction until
										you revoke it or we notify you.
									</p>

									<p>
										We will comply with any restriction request if (1) except as otherwise required by law, the
										disclosure is to a health plan for purposes of carrying out payment or health care operations (and
										is not for purposes of carrying out treatment); and (2) the protected health information pertains
										solely to a health care item or service for which the health care provider involved has been paid in
										full by you or another person.
									</p>

									<p>
										<u>Right to Inspect and Copy:</u> You have a right to inspect and copy your medical information. The
										right is subject to certain specific exceptions and you may be charged a reasonable fee for the
										costs of copying, mailing, or other supplies associated with your request. If the information you
										request is maintained electronically, and you request an electronic copy, we will provide a copy in
										the electronic form and format you request, if the information can be readily produced in that form
										and format; if the information cannot be readily produced in that form and format, we will work with
										you to come to an agreement on form and format. If we cannot agree on an electronic form and format,
										we will provide you with a paper copy. To inspect and copy your protected health information, you
										must submit your request in writing to the Privacy Officer.
									</p>

									<p>
										<u>Right to Amend:</u> You have the right to request amendments to your protected health
										information. We require that all requests for amendments be made in writing to the Privacy Officer
										and that you provide a reason to support the requested amendment. We may deny your request for an
										amendment if it is not in writing or does not include a reason to support the request. In addition,
										we may deny your request if you ask us to amend information that:
									</p>

									<p>
										&bull; is not part of the medical information kept by Archer;
										<br />
										&bull; was not created by Archer, unless the person or entity that created the information is no
										longer available to make the amendment;
										<br />
										&bull; is not part of the information that you would be permitted to inspect and copy; or
										<br />
										&bull; is already accurate and complete.
									</p>

									<p>
										If we deny your request, you have the right to file a statement of disagreement with us and any
										future disclosures of the disputed information will include your statement.
									</p>

									<p>
										&bull; <u>Right to Alternative Means of Communication:</u> You have a right to request that we
										communicate about your treatment and/or protected health information by alternative means or
										locations. We are required to accept reasonable requests. Your request must specify how or where you
										wish to be contacted. We require that you make this request in writing to the Privacy Officer.
									</p>

									<p>
										&bull; <u>Right to an Accounting of Disclosures:</u> You have the right to request an “accounting”
										of certain disclosures of your protected health information. The accounting will not include (1)
										disclosures for purposes of treatment, payment, or health care operations; (2) disclosures made to
										you; (3) disclosures made pursuant to your authorization; (4) disclosures made to friends or family
										in your presence or because of an emergency; (5) disclosures for national security purposes; and (6)
										disclosures incidental to otherwise permissible disclosures. However, to the extent your protected
										health information is maintained in an electronic health record, you have a right to an accounting
										of information related to treatment, payment, or health care operations for three years prior to
										your written request.
									</p>

									<p>
										To request a list or accounting of disclosures, you must submit your request in writing to the
										Privacy Officer. Your request must state the time period you want the accounting to cover, which may
										not be longer than six years before the date of the request. Your request should indicate in what
										form you want the list (for example, paper or electronic). The first list you request within a
										12-month period will be provided free of charge. For additional lists, we may charge you for the
										costs of providing the list. We will notify you of the cost involved and you may choose to withdraw
										or modify your request at that time before any costs are incurred.
									</p>

									<p>
										&bull; <u>Right to Revoke an Authorization:</u> You may revoke an authorization made by you at any
										time, provided that the revocation is in writing, made to the Privacy Officer. Once we receive your
										written revocation, it will only be effective for future uses and disclosures. It will not be
										effective for any information that may have been used or disclosed in reliance upon the written
										authorization and prior to receiving your written revocation.
									</p>

									<p>
										&bull; <u>Right to Be Notified of a Breach:</u> You have the right to be notified in the event we
										(or a Business Associate) discover a breach of unsecured protected health information.
									</p>

									<p>
										&bull; <u>Right to a Paper Copy of This Notice:</u> You have a right to a paper copy of this Notice.
										You may request a copy of this Notice from Archer Health at any time, even if you have previously
										agreed to receive this Notice electronically. Any material revisions to this Notice will be made
										available to you and posted to Archer Health’s website upon the effective date of such material
										revision.{" "}
									</p>

									<p>
										Right to File a Complaint: If you believe any of your privacy rights have been violated, you may
										file a complaint regarding these rights with Archer Health by contacting the Privacy Officer or with
										the Office for Civil Rights of the United States Department of Health and Human Services (
										<a href="https://www.hhs.gov/hipaa/filing-a-complaint/index.html" target="new">
											https://www.hhs.gov/hipaa/filing-a-complaint/index.html
										</a>
										). You will not be penalized, or in any other way retaliated against, for filing a complaint with
										the Office for Civil Rights or with us.
									</p>

									<p className="text-center font-weight-bold">ARCHER HEALTH</p>

									<p className="text-center font-weight-bold">ACKNOWLEDGEMENT OF RECEIPT OF NOTICE OF PRIVACY PRACTICES</p>

									<p>
										I understand that under the Health Insurance Portability and Accountability Act (HIPAA), I have
										certain rights to privacy regarding my protected health information. I acknowledge that I have
										received or have been given the opportunity to receive a copy of Archer Health’s Notice of Privacy
										Practices, which summarizes the ways my protected health information may be used and disclosed by
										Archer Health and states my rights with respect to my protected health information. I also
										understand that Archer Health has the right to change or amend its Notice of Privacy Practices, and
										that I may contact Archer Health at any time to obtain a current copy of its Notice of Privacy
										Practices. To the extent permitted by law, I consent to the use and disclosure of my information for
										the purposes described in the Notice of Privacy Practices.
									</p>
								</div>
							</div>
						</div>

						<div className="card mt-3">
							<div className="card-body">
								<h5 className="card-title">Consent to Certain Communications</h5>
								<div className="small terms-text">
									<p className="text-center font-weight-bold">ARCHER HEALTH</p>

									<p className="text-center font-weight-bold">
										CONSENT TO CERTAIN COMMUNICATIONS
										<br />
										Effective Date: January 19, 2021
									</p>

									<p className="font-weight-bold">Communications via E-mail and Text</p>

									<p>
										Archer Health offers its patients and their guardians or personal representatives the opportunity to
										communicate by e-mail or text messaging regarding: 1) appointment reminders; 2) pre or
										post-treatment instructions; 3) prescription information; and 4) educational information. Text
										messaging and e-mail will not be used to convey your medical information or to discuss your medical
										condition, and Archer Health will use reasonable means to protect the security and confidentiality
										of such of such text and e-mail communications. Archer Health does not charge for this service, but
										standard text messaging rates or cellular telephone minutes may apply as provided in your wireless
										plan (contact your carrier for pricing plans and details).{" "}
									</p>

									<p>
										When you consent to communication via e-mail or text messaging, you accept the risks that come with
										these types of communications being unencrypted. Some of these risks include, but are not limited
										to, the possibility that a text or e-mail could be intercepted by persons not authorized to receive
										such information, improperly forwarded onto others, addressed to the wrong recipient, or kept on
										electronic devices even after you believe you have deleted the communication. Further, some
										employers and online services have a right to archive and review texts and e-mails transmitted
										through their systems. Because of these risks, Archer Health cannot guarantee the security and
										confidentiality of communications made via text and e-mail. By signing this Consent, you acknowledge
										and agree to accept such risks, and that Archer Health shall not be responsible or liable for the
										improper use or disclosure of confidential information (including Protected Health Information
										subject to the Health Insurance Portability and Accountability Act of 1996) resulting from the use
										of e-mail or text messaging as outlined in this Consent.{" "}
									</p>

									<p className="font-weight-bold">Communications About My Healthcare</p>

									<p>
										By signing this Consent, you agree that you may be contacted by Archer Health, an agent of Archer
										Health, or an independent physician’s office for the purposes of scheduling necessary follow-up
										visits recommended by Archer Health’s treating provider.
									</p>

									<p className="text-center font-weight-bold">ARCHER HEALTH</p>

									<p className="text-center font-weight-bold">ACKNOWLEDGEMENT OF CONSENT TO CERTAIN COMMUNICATIONS</p>

									<p>
										I acknowledge that I have read and understand the above Consent to Certain Communications provided
										by Archer Healthcare, as well as the risks associated with using e-mail and text messaging
										communications. I agree to accept these risks and the conditions outlined in this Consent, as well
										as any future instructions Archer Healthcare may impose regarding e-mail and text communications. I
										further understand that I may opt out of the communications set forth in this Consent or amend the
										persons to whom I would like my Protected Health Information shared at any time by contacting Archer
										Health’s Privacy Officer, Cole Hawkins (615) 767-6336.
									</p>
								</div>
							</div>
						</div>

						<p className="small px-3 py-3">
							By verifying your account, you indicate that you have read and agree to the Communications and Privacy Policy terms
							above.
						</p>
					</Fragment>
				)}
				<p>
					<button className="btn btn-primary" onClick={handleSubmit}>
						{buttonTitle}
					</button>
					<button className="btn btn-link" onClick={handleCancel}>
						Cancel
					</button>
				</p>
			</form>
		</Fragment>
	);
};

VerifyAccount.defaultProps = {};

export default VerifyAccount;
