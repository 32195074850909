import React, { Fragment, useState, useEffect } from "react";
import AppointmentService from "../../../../services/appointment.service";
import CheckboxGroup from "react-checkbox-group";
import { nanoid } from "nanoid";

const MedicalHistoryAllergies = ({ appointmentAllergies, handleSetAppointmentAllergies, handleNextClick }) => {
	const [allergies, setAllergies] = useState([]);
	const [selectedAllergies, setSelectedAllergies] = useState([]);
	const [noSelection, setNoSelection] = useState(false);

	useEffect(() => {
		setAllergies(AppointmentService.allergies);
	}, []);

	useEffect(() => {
		if (appointmentAllergies) {
			setSelectedAllergies(appointmentAllergies);
		}
	}, [appointmentAllergies]);

	const handleSelectNone = (event) => {
		let isChecked = event.target.checked;
		setNoSelection(isChecked);

		if (isChecked) {
			handleSetAppointmentAllergies([]);
		}
	};

	return (
		<div id="appointment-allergies">
			<h1 className="my-3">
				Do you have any of the following allergies?
			</h1>

			<ul className="list-group my-5">
				<li className={`list-group-item ${noSelection && "active"}`}>
					<div className="form-check">
						<input type="checkbox" className="form-check-input" onChange={handleSelectNone} /> 
						<label className="form-check-label">None of the allergies below</label>
					</div>
				</li>
			</ul>

			{!noSelection && (
				<CheckboxGroup name="symptoms" value={selectedAllergies} onChange={handleSetAppointmentAllergies}>
					{(Checkbox) => (
						<Fragment key={nanoid()}>
							<ul className="list-group">
								{allergies.map((allergy) => (
									<li className={`list-group-item ${selectedAllergies.includes(allergy.name) && "active"}`} key={allergy.name}>
										<div className="form-check">
											<Checkbox className="form-check-input" value={allergy.name} />
											<label className="form-check-label">{allergy.name}</label>
										</div>
									</li>
								))}
							</ul>
						</Fragment>
					)}
				</CheckboxGroup>
			)}

			<p className="my-3">
				<button className="btn btn-primary btn-block" onClick={handleNextClick}>
					NEXT &raquo;
				</button>
			</p>
		</div>
	);
};

MedicalHistoryAllergies.defaultProps = {};

export default MedicalHistoryAllergies;
