import React, { Fragment, useEffect, useState } from "react";
//import PaymentService from "../../../services/payment.service";
import useForm from "../../useForm";
//import { useHistory } from "react-router-dom";
import validate from "./AppointmentConfirmValidationRules";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PaymentMethods from '../../Account/PaymentMethods';

const Confirm = ({
	reminderEmail,
	reminderPhone,
	patientCost,
	paymentMethod,
	appointmentProcessing,
	handleSetPaymentMethod,
	handleSetAppointmentReminderPhone,
	handleSetAppointmentReminderEmail,
	handleConfirmAppointment,
}) => {
	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);

	const confirmAppointment = () => {
		handleSetAppointmentReminderEmail(values.reminderEmail);
		handleSetAppointmentReminderPhone(values.reminderPhone);

		//Notify Book Appointment to Complete Appointment Booking
		handleConfirmAppointment();
	};

	const { setValues, values, errors, handleChange, handleSubmit } = useForm(confirmAppointment, validate);

	//const history = useHistory();

	useEffect(() => {
		setValues((values) => ({
			...values,
			reminderPhone: reminderPhone,
			reminderEmail: reminderEmail,
		}));
	}, [reminderEmail, reminderPhone, setValues]);

	useEffect(() => {
		if (paymentMethod) {
			setSelectedPaymentMethod(paymentMethod);
		}
	}, [paymentMethod]);

	return (
		<div id="appointment-confirm">
			<h1 className="my-3">Confirm Appointment</h1>

			{/* Reminders: On Site Appointments Only */}
			<h4 className="mt-5">Get Reminders</h4>
			<div className="form-group">
				<label>Text message</label>
				<input
					type="tel"
					name="reminderPhone"
					className={`form-control ${errors.reminderPhone && "is-invalid"}`}
					value={values.reminderPhone || ""}
					onChange={handleChange}
				/>
				<div className="invalid-feedback">{errors.reminderPhone}</div>
				<small>Data and message rates may apply</small>
			</div>
			<div className="form-group">
				<label>Email</label>
				<input
					type="email"
					name="reminderEmail"
					placeholder="email@employer.com"
					className={`form-control ${errors.reminderEmail && "is-invalid"}`}
					value={values.reminderEmail || ""}
					onChange={handleChange}
				/>
				<div className="invalid-feedback">{errors.reminderEmail}</div>
			</div>

			{patientCost > 0 && (
				<Fragment>
				<h4 className="mt-5">Payment</h4>

				<h5>
					Your Cost: <strong>${patientCost}</strong>
				</h5>

				<PaymentMethods clickAction={handleSetPaymentMethod} selectedPaymentMethod={selectedPaymentMethod} showHeader={false} />

			</Fragment>
			)}

			{appointmentProcessing && (
				<Fragment>
					<p>
						<FontAwesomeIcon icon={faCircleNotch} spin /> <em>One moment, booking appointment...</em>
					</p>
				</Fragment>
			)}

			<p className="my-3">
				<button className="btn btn-primary btn-block" onClick={handleSubmit} disabled={patientCost > 0 && !selectedPaymentMethod}>
					Confirm Appointment
				</button>
			</p>
		</div>
	);
};

Confirm.defaultProps = {};

export default Confirm;
