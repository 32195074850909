import React, { Fragment, useEffect, useState } from "react";
import AppointmentService from "../../../services/appointment.service";
import CheckboxGroup from "react-checkbox-group";
import { nanoid } from "nanoid";

const MedicalHistorySymptoms = ({ appointmentSymptoms, handleSetAppointmentSymptoms, handleNextClick }) => {
	const [symptoms, setSymptoms] = useState([]);
	const [selectedSymptoms, setSelectedSymptoms] = useState([]);
	const [noSelection, setNoSelection] = useState(false);

	useEffect(() => {
		//Load list of symptoms
		setSymptoms(AppointmentService.symptoms);
	}, []);

	useEffect(() => {
		if (appointmentSymptoms) {
			setSelectedSymptoms(appointmentSymptoms);
		}
	}, [appointmentSymptoms]);

	const handleSelectNone = (event) => {
		let isChecked = event.target.checked;
		setNoSelection(isChecked);

		if (isChecked) {
			handleSetAppointmentSymptoms([]);
		}
	};

	return (
		<div id="appointment-symptoms">
			<h1 className="my-3">
				Which of the following symptoms are you experiencing?
			</h1>
			<p className="font-italic">Select all that apply</p>

			<ul className="list-group my-5">
				<li className={`list-group-item ${noSelection && 'active'}`}>
					<div className="form-check">
						<input type="checkbox" onChange={handleSelectNone} className="form-check-input" /> <label className="form-check-label">None of the symptoms below</label>
					</div>
				</li>
			</ul>

			{!noSelection && (
				<CheckboxGroup name="symptoms" value={selectedSymptoms} onChange={handleSetAppointmentSymptoms}>
					{(Checkbox) => (
						<Fragment>
							{Object.keys(symptoms).map((category) => (
								<Fragment key={nanoid()}>
									<h5 className="text-capitalize mt-3">{category}</h5>

									<ul className="list-group">
										{symptoms[category].map((symptom) => (
											<li className={`list-group-item ${selectedSymptoms.includes(symptom.name) && 'active'}`} key={symptom.name}>
												<div className="form-check">
													<Checkbox className="form-check-input" value={symptom.name} />
													<label className="form-check-label">{symptom.name}</label>
												</div>
											</li>
										))}
									</ul>
								</Fragment>
							))}
						</Fragment>
					)}
				</CheckboxGroup>
			)}

			<p className="my-3">
				<button className="btn btn-primary btn-block" onClick={handleNextClick}>
					Next &raquo;
				</button>
			</p>
		</div>
	);
};

MedicalHistorySymptoms.defaultProps = {};

export default MedicalHistorySymptoms;