export default function validate(values) {
    
    let errors = {};

    if (!values.dobMonth) {
        errors.dobMonth = "Required"
    }

    if (!values.dobDay) {
        errors.dobDay = "Required"
    }

    if (!values.dobYear) {
        errors.dobYear = "Required"
    }

    

    //If the account was found, then we will require a phone number and password to finalize account verification
    if (values.accountId) {
        if (!values.password) {
            errors.password = "Required";
            //TODO: enforce password strength
        }

        if (values.password && values.password.length < 10) {
            errors.password = "Password is too short";
        }

        if (!values.phone && !values.email) {
            errors.phone = "Email or phone number is required";
            errors.email = "Email or phone number is required";
        }

        if (values.phone && (values.phone.indexOf("(") > -1 && values.phone.length !== 14)) {
            errors.phone = "Invalid phone number. Please enter as (xxx) xxx-xxxx";
        }

        if (values.phone && (values.phone.indexOf("(") === -1 && values.phone.length !== 10)) {
            errors.phone = "Invalid phone number. Please contact tech@archerhealth.com for assistance.";
        }
    }

    if (!values.companyCode) {
        errors.companyCode = "Required"
    }

    if (!values.lastName) {
        errors.lastName = "Required"
    }

    return errors;
};