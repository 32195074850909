import React from "react";

import EmployeeContainer from "../EmployeeContainer";
import { Route, Switch, Redirect } from "react-router-dom";
import BookAppointment from "../../components/BookAppointment";
import Account from "../../components/Account";
import PaymentMethods from "../../components/Account/PaymentMethods";
import ContactInformation from "../../components/Account/ContactInformation";
import ChangePassword from "../../components/Account/ChangePassword";
import SelectDependent from "../../components/Account/Dependent/Select";
import UploadEmployees from "../../components/Employees/Upload";
import EmployerContainer from "../EmployerContainer";
import EmployeeList from "../../components/Employees/List";
import AccountDetail from "../../components/Account/Detail";
import ProviderList from "../../components/Providers/List";
import EmployerList from "../../components/Employers/List";
import EmployerDetail from "../../components/Employers/Detail";
import ClinicDetail from "../../components/Clinic/Detail";
import ClinicAppointmentList from "../../components/Clinic/Appointments/List";
import AppointmentDetail from "../../components/Appointment/Detail";
import SuperadminContainer from "../SuperadminContainer";
import TelehealthGroupList from "../../components/TelehealthGroup/List/Table";
import TelehealthGroupDetail from "../../components/TelehealthGroup/Detail";
import ProviderDetail from "../../components/Providers/Detail";
import ProxyAppointment from "../../components/BookAppointment/Proxy";
import ReportList from "../../components/Reports/List";
import UtilizationPeriodReport from "../../components/Reports/Utilization/Period";
import UtilizationYTDReport from "../../components/Reports/Utilization/YTD";
import EmployerSMS from "../../components/Employers/SMS";
import ClinicBlockSchedule from "../../components/Clinic/Schedule/Block";
import AppointmentWalkin from "../../components/BookAppointment/Walkin";

const HubContainer = ({ currentUser }) => {
	const PrivateRoute = ({ isAllowed, ...props }) => (isAllowed ? <Route {...props} /> : <Redirect to="/login" />);

	/* Hub directs user types to their correct containers */
	return (
		<Switch>
			<Route exact path="/">
				{currentUser.role === "employee" && <Redirect to="/employee" />}
				{currentUser.role === "employer" && <Redirect to="/employer" />}
				{currentUser.role === "admin" && <Redirect to="/admin" />}
				{currentUser.role === "provider" && <Redirect to="/provider" />}
			</Route>



			{/* Shared Routes */}
			<PrivateRoute
				isAllowed={["admin", "provider"].includes(currentUser.role)}
				path="/book-appointment/proxy"
				render={(props) => <ProxyAppointment {...props} currentUser={currentUser} />} />

			<PrivateRoute
				isAllowed={["admin", "provider"].includes(currentUser.role)}
				path="/book-appointment/walkin"
				render={(props) => <AppointmentWalkin {...props} currentUser={currentUser} />} />

			<PrivateRoute
				isAllowed={["employer", "employee"].includes(currentUser.role)}
				path="/book-appointment/:patient/:step?"
				render={(props) => <BookAppointment {...props} currentUser={currentUser} />} />

			<PrivateRoute
				isAllowed={["employer", "employee"].includes(currentUser.role)}
				path="/select-dependent"
				render={(props) => <SelectDependent {...props} currentUser={currentUser} />} />

			<PrivateRoute
				isAllowed={currentUser !== null}
				path="/payment-methods/:referrer?"
				render={(props) => <PaymentMethods currentUser={currentUser} />} />

			<PrivateRoute 
				isAllowed={currentUser !== null} 
				path="/contact" 
				render={(props) => <ContactInformation currentUser={currentUser} />} />

			<PrivateRoute
				isAllowed={currentUser !== null}
				path="/change-password"
				render={(props) => <ChangePassword currentUser={currentUser} />} />		

			{/*Superadmin*/}

			<PrivateRoute 
				path="/admin" 
				isAllowed={currentUser.role === "admin"} 
				render={(props) => <SuperadminContainer {...props} />} />

			<PrivateRoute 
				path="/employee/upload" 
				isAllowed={["admin", "employer"].includes(currentUser.role)} 
				render={(props) => <UploadEmployees currentUser={currentUser} />} />

			<PrivateRoute
				path="/employee/list/:employerId"
				isAllowed={["employer", "admin"].includes(currentUser.role)}
				render={(props) => <EmployeeList {...props} />}	/>

			<PrivateRoute 
				path="/employee" 
				isAllowed={currentUser.role === "employee"} 
				render={(props) => <EmployeeContainer {...props} currentUser={currentUser} />} />

			{/* account id is optional, empty means add new */}
			<PrivateRoute 
				path="/account/detail/:employerId/:accountId?" 
				isAllowed={true} 
				render={(props) => <AccountDetail {...props} currentUser={currentUser} />} />

			<PrivateRoute 
				path="/account" 
				isAllowed={currentUser !== null} 
				render={(props) => <Account currentUser={currentUser} />} />

			<PrivateRoute
				path="/appointment/detail/:appointmentId"
				isAllowed={currentUser !== null}
				render={(props) => <AppointmentDetail {...props} currentUser={currentUser} />} />

			<PrivateRoute 
				path="/employer/list" 
				isAllowed={currentUser.role === "admin"} 
				render={(props) => <EmployerList {...props} />} />

			<PrivateRoute
				path="/employer/report/list/:employerId"
				isAllowed={["admin","employer"].includes(currentUser.role)}
				render={(props) => <ReportList {...props} />} />

			<PrivateRoute
				path="/employer/report/utilization/ytd/:employerId/:year"
				isAllowed={["admin","employer"].includes(currentUser.role)}
				render={(props) => <UtilizationYTDReport {...props} currentUser={currentUser} />} />

			<PrivateRoute
				path="/employer/report/utilization/period/:startDate/:employerId"
				isAllowed={["admin","employer"].includes(currentUser.role)}
				render={(props) => <UtilizationPeriodReport {...props} />} />

			<PrivateRoute
				path="/employer/detail/:employerId?"
				isAllowed={currentUser.role === "admin"}
				render={(props) => <EmployerDetail {...props} currentUser={currentUser} />} />

			<PrivateRoute
				path="/employer/sms/:employerId?"
				isAllowed={currentUser.role === "admin"}
				render={(props) => <EmployerSMS {...props} />} />

			<PrivateRoute 
				path="/employer" 
				isAllowed={currentUser.role === "employer"} 
				render={(props) => <EmployerContainer {...props} currentUser={currentUser} />} />

			{/* On Site clinics */}
			<PrivateRoute 
				path="/clinic/detail/:employerId/:calendarId?" 
				isAllowed={true} 
				render={(props) => <ClinicDetail {...props} />} />

			<PrivateRoute
				path="/clinic/appointments/"
				isAllowed={["admin", "provider"].includes(currentUser.role)}
				render={(props) => <ClinicAppointmentList {...props} currentUser={currentUser} />}
			/>

			<PrivateRoute
				path="/clinic/schedule/block"
				isAllowed={currentUser.role === "provider"}
				render={(props) => <ClinicBlockSchedule {...props} currentUser={currentUser} />} />

			{/* Telehealth Groups */}
			<PrivateRoute
				path="/telehealthgroup/detail/:groupId?"
				isAllowed={currentUser.role === "admin"}
				render={(props) => <TelehealthGroupDetail {...props} />} />

			<PrivateRoute
				path="/telehealthgroup/list"
				isAllowed={currentUser.role === "admin"}
				render={(props) => <TelehealthGroupList {...props} />} />

			<PrivateRoute
				path="/provider/detail/:providerId?"
				isAllowed={currentUser.role === "admin"}
				render={(props) => <ProviderDetail {...props} currentUser={currentUser} />} />

			<PrivateRoute 
				path="/provider/list" 
				isAllowed={currentUser.role === "admin"} 
				render={(props) => <ProviderList {...props} currentUser={currentUser} />} />

			

			<PrivateRoute
				path="/provider"
				isAllowed={currentUser.role === "provider"}
				render={(props) => <ClinicAppointmentList {...props} currentUser={currentUser} />} />
		</Switch>
	);
};

HubContainer.defaultProps = {};

export default HubContainer;
