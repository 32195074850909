import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { nanoid } from "nanoid";
import { faPhoneSquareAlt, faEnvelope, faMedkit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { format } from "date-fns-tz";
import parseISO from "date-fns/parseISO";
import { enUS } from "date-fns/locale";
import AppointmentService from "../../../services/appointment.service";

const ProviderSummary = ({ appointment, appointmentBiometrics, handleStartRequest, handleCompleteRequest, setCancelAppointment }) => {
	return (
		<Fragment>
			{appointment.modality === "on-demand" && (
				<Fragment>
					{appointment.status === "processing" && (
						<Fragment>
							<div className="form-row">
								<div className="col">
									{!appointment.startTime && (
										<button className="btn btn-primary btn-block" onClick={handleStartRequest}>
											Call Now
										</button>
									)}

									{appointment.startTime && !appointment.completedTime && (
										<button className="btn btn-primary btn-block" onClick={handleCompleteRequest}>
											Appointment Completed
										</button>
									)}
								</div>
								<div className="col">
									<button className="btn btn-link btn-block" onClick={() => setCancelAppointment(true)}>
										Cancel Appointment
									</button>
								</div>
							</div>

							<p className="alert alert-warning my-3">
								<strong>
									<small>
										If you call the patient outside the app, be sure to enter *67 first in order to hide your number.
									</small>
								</strong>
							</p>
						</Fragment>
					)}

					{appointment.completedTime && (
						<p className="my-3">
							<Link to="/" className="btn btn-primary btn-block" onClick={handleCompleteRequest}>
								&laquo; Back to Home
							</Link>
						</p>
					)}
				</Fragment>
			)}

			{appointment.patient &&
				<Fragment>
					<h3>{appointment.patient.fullName}</h3>
					<h5>Employer: {appointment.employer?.name} {appointment.account.hsa_flag && <Fragment><FontAwesomeIcon icon={faMedkit} /> <span style={{ backgroundColor: "yellow" }}>Patient uses HSA</span></Fragment>}</h5>
					<h5>{appointment.type}</h5>

					<span className="pr-3">DOB <strong>{moment(appointment.patient.birthday?.substring(0,10)).format("MM-DD-YYYY")}</strong></span><br/>
					<span>Gender <strong>{appointment.patient.gender}</strong></span><br/>
					

					{appointment.modality !== "on-demand" && appointment.datetime && (
						<Fragment>
							Appointment date/time: {format(parseISO(appointment.datetime), "MMM dd, yyyy h:mm b", { locale: enUS })}
							<br />
						</Fragment>
					)}										
				</Fragment>
			}

			{!appointment.patient?.isPrimary && (
				<Fragment>

					<p className="pt-3">
						<strong style={{ backgroundColor: "yellow" }}>Patient is a Dependent</strong><br/>
						Primary's account information:<br/>

						Name: {appointment.account?.firstName} {appointment.account?.lastName}<br/>
						Email: {appointment.account?.email}<br/>
						Phone: {appointment.account?.mobile}<br/>
					</p>
				</Fragment>
			)}

			{appointment.patient?.isPrimary && (
				<p className="pt-3">
					<a href={`tel:*67${appointment.account?.mobile}`} className="btn btn-outline-primary mr-3">
						<FontAwesomeIcon icon={faPhoneSquareAlt} /> Call
					</a>

					<a href={`mailto:${appointment.account?.email}`} className="btn btn-outline-primary">
						<FontAwesomeIcon icon={faEnvelope} /> Email
					</a>
				</p>
			)}

			{(appointmentBiometrics === null && AppointmentService.biometric_screening_types().includes(appointment?.receipt?.items[0]?.productID)) &&
				<p className="alert alert-warning my-3">
					This is a <strong>biometric</strong> screening appointment and requires entry of the biometric values collected during this
					appointment.
				</p>
			}

			<div className="card-group mb-3">
				<div className="card">
					<div className="card-header">Symptoms</div>
					<div className="card-body">
						<p className="card-text">
							{appointment.symptoms?.map((symptom) => (
								<Fragment key={symptom}>
									{symptom}
									<br />
								</Fragment>
							))}

							{appointment.symptoms?.length < 1 && <em>No symptoms</em>}
						</p>
					</div>
				</div>

				<div className="card">
					<div className="card-header">Surgeries</div>
					<div className="card-body">
						<p className="card-text">
							{appointment.patient?.surgeries
								?.filter((surgery) => surgery.description.length > 0)
								.map((surgery) => (
									<Fragment key={nanoid()}>
										{surgery.description}
										<br />
									</Fragment>
								))}

							{(appointment.patient?.surgeries?.filter((surgery) => surgery.description.length > 0)).length < 1 && (
								<em>No surgeries</em>
							)}
						</p>
					</div>
				</div>

				<div className="card">
					<div className="card-header">Health History: Allergies</div>
					<div className="card-body">
						<p className="card-text">
							{appointment.patient?.allergies?.map((allergy) => (
								<Fragment key={allergy.name}>
									{allergy.name}
									<br />
								</Fragment>
							))}

							{appointment.patient?.allergies?.length < 1 && <em>No allergies</em>}
						</p>
					</div>
				</div>
			</div>

			<div className="card-group mb-5">
				<div className="card">
					<div className="card-header">Health History: Medications</div>
					<div className="card-body">
						<p className="card-text">
							{appointment.patient?.medications
								?.filter((medication) => medication.name.length > 0)
								.map((medication) => (
									<Fragment key={nanoid()}>
										{medication.name} / {medication.howLong} / {medication.notes}
										<br />
									</Fragment>
								))}

							{appointment.patient?.medications?.filter((medication) => medication.name.length > 0).length < 1 && (
								<em>No medications</em>
							)}
						</p>
					</div>
				</div>

				<div className="card">
					<div className="card-header">Health History: Conditions</div>
					<div className="card-body">
						<p className="card-text">
							{appointment.patient?.conditions?.map((condition) => (
								<Fragment key={condition.name}>
									{condition.name}
									<br />
								</Fragment>
							))}

							{appointment.patient?.conditions?.length < 1 && <em>No conditions</em>}
						</p>
					</div>
				</div>

				<div className="card">
					<div className="card-header">Health History: Family Conditions</div>
					<div className="card-body">
						<p className="card-text">
							{appointment.patient?.familyHistory?.map((history) => (
								<Fragment key={history.condition}>
									{history.condition}
									<br />
								</Fragment>
							))}

							{appointment.patient?.familyHistory?.length < 1 && <em>No family conditions</em>}
						</p>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

ProviderSummary.defaultProps = {};

export default ProviderSummary;