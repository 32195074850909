import React, { Fragment } from "react";
import { Link } from "react-router-dom";

const SelectEmployer = ({
	currentUser,
	employers,
	employees,
	dependents,
	handleSetEmployer,
	handleSetAccount,
	handleSetPatient,
	handleNextClick,
	selectedEmployer,
	selectedEmployee,
	selectedPatient
}) => {
	const isEmployerProvider = (employer) => {
		let isProviderForEmployer = false;

		employer.calendars.forEach((calendar) => {
			if (calendar.providers.includes(currentUser._id)) {
				isProviderForEmployer = true;
			}
		});

		return isProviderForEmployer;
	};

	return (
		<Fragment>
			<h1>Schedule Appointment</h1>

			<div className="form-group">
				<label>Select employer:</label>
				<select className="form-control" onChange={(e) => handleSetEmployer(e.target.value)}>
					<option value="">Select</option>
					{employers.map(
						(employer) =>
							//Filter out employers where provider is not assigned
							(currentUser.role === "admin" || isEmployerProvider(employer)) && (
								<option value={employer._id} key={employer._id}>
									{employer.name}
								</option>
							)
					)}
				</select>
			</div>

			{selectedEmployer && (
				<div className="form-group">
					<label className="form-label">Select employee:</label>
					<select className="form-control" onChange={(e) => handleSetAccount(e.target.value)}>
						<option>Select</option>
						{employees.map((employee) => (
							<option value={employee._id} key={employee._id}>
								{employee.firstName} {employee.lastName}
							</option>
						))}
					</select>
					<small className="form-text">
						<strong>New Employee?</strong> <Link to={`/account/detail/${selectedEmployer}`}>Create Employee</Link>
					</small>
				</div>
			)}

			{selectedEmployee && (
				<Fragment>
					
					<label className="form-label">Select patient:</label>
					<select className="form-control" onChange={(e) => handleSetPatient(e.target.value)} value={selectedPatient || ""}>
						<option>Select</option>
						{dependents.map((dependent) => (
							<option value={dependent._id} key={dependent._id}>
								{dependent.fullName}
							</option>
						))}
					</select>
				</Fragment>
			)}

			<p className="py-3">
				<button className="btn btn-primary btn-block" onClick={handleNextClick} disabled={selectedEmployee === null}>
					NEXT &raquo;
				</button>
			</p>
		</Fragment>
	);
};

SelectEmployer.defaultProps = {};

export default SelectEmployer;
