import React, { useState, useEffect } from "react";
import AppointmentService from "../../../../services/appointment.service";
import CheckboxGroup from "react-checkbox-group";
import { nanoid } from "nanoid";

const MedicalHistoryFamily = ({ appointmentFamilyHistory, handleSetAppointmentFamilyHistory, handleNextClick }) => {
	const [familyConditions, setFamilyConditions] = useState([]);
	const [selectedFamilyConditions, setSelectedFamilyConditions] = useState([]);
	const [noSelection, setNoSelection] = useState(false);

	useEffect(() => {
		setFamilyConditions(AppointmentService.familyConditions);
	}, []);

	useEffect(() => {
		if (appointmentFamilyHistory) {
			setSelectedFamilyConditions(appointmentFamilyHistory);
		}
	}, [appointmentFamilyHistory]);

	const handleSelectNone = (event) => {
		let isChecked = event.target.checked;
		setNoSelection(isChecked);

		if (isChecked) {
			handleSetAppointmentFamilyHistory([]);
		}
	};

	return (
		<div id="appointment-family-conditions">
			<h1 className="my-3">
				Is there family history of any of the following?
			</h1>
			<p className="font-italic">(Parents, siblings, children)</p>

			<ul className="list-group my-5">
				<li className={`list-group-item ${noSelection && "active"}`}>
					<div className="form-check">
						<input type="checkbox" className="form-check-input" onChange={handleSelectNone} /> 
						<label className="form-check-label">None of the conditions below</label>
					</div>
				</li>
			</ul>

			{!noSelection && (
				<CheckboxGroup 
				name="symptoms" 
				value={selectedFamilyConditions} 
				onChange={handleSetAppointmentFamilyHistory}>
					{(Checkbox) => (
						
						<ul className="list-group">
						{familyConditions.map((condition) => (
							<li className={`list-group-item ${selectedFamilyConditions.includes(condition.name) && "active"}`} key={nanoid()}>
								<div className="form-check">
									<Checkbox className="form-check-input" value={condition.name} />
									<label className="form-check-label">
										{condition.name}
									</label>
								</div>
							</li>
						))}
						</ul>
						
					)}
				</CheckboxGroup>
			)}

			<p className="my-3">
				<button className="btn btn-primary btn-block" onClick={handleNextClick}>
					NEXT &raquo;
				</button>
			</p>
		</div>
	);
};

MedicalHistoryFamily.defaultProps = {};

export default MedicalHistoryFamily;
