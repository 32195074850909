import React, { Fragment, useState, useEffect } from "react";


const Biometrics = ({ handleSaveScreening }) => {
	const [biometrics, setBiometrics] = useState({
		screeningDate: null,
		height: null,
		weight: null,
		systolicBloodPressure: null,
		diastolicBloodPressure: null,
		cholesterolTotal: null,
		hdl: null,
		ldl: null,
		triglycerides: null,
		nonHDL: null,
		glucose: null,
		hbA1c: null,
		nicotine: null,
	});

	const [saveDisabled, setSaveDisabled] = useState(true);

	useEffect(() => {
		let requiredValues = ['screeningDate', 'height', 'weight', 'systolicBloodPressure', 'diastolicBloodPressure', 'nicotine'];
		let disabled = false;

		requiredValues.forEach(key => {
			if (biometrics[key] === null) {
				disabled = true;
				return;
			}
		});

		//Object.keys(biometrics).forEach(key => {
		//	if (biometrics[key] === null) {
		//		disabled = true;
		//		return;
		//	}			
		//});

		setSaveDisabled(disabled);
	}, [biometrics]);

	const handleSetBiometrics = (event) => {
		setBiometrics((biometrics) => ({ ...biometrics, [event.target.name]: event.target.value }));
	};


	useEffect(() => {		
		const target = document.querySelector("div#appointment-detail");
		
		target.addEventListener("paste", (event) => {
			let paste = (event.clipboardData || window.clipboardData).getData("text");
			console.log(paste);

			let values = paste.split("\t");
			console.log(values);

			console.log("setting biometrics");
			console.log(values[5]);
			let examDate = values[0].split("/");
			examDate = examDate[2] + "-" + (examDate[0] < 10 ? "0" + examDate[0] : examDate[0])  + "-" + examDate[1];
			setBiometrics((biometrics) => ({...biometrics, 		
				screeningDate: examDate,
				height: values[5],
				weight: values[6],
				systolicBloodPressure: values[7],
				diastolicBloodPressure: values[8],
				nicotine: values[9].toLowerCase(),
				cholesterolTotal: values[10],
				hdl: values[11],
				ldl: values[12],
				glucose: values[13],
				triglycerides: values[14],
				nonHDL: values[15]
			}));
		});
	}, []);
	
	
	return (
		<Fragment>
			<h5>Biometrics</h5>

			<h6>Physical / Lifestyle</h6>

			<div className="row mb-3">
				<div className="col-sm-12 col-md-6">
					<div className="form-group row">
						<label className="col-form-label col-sm-4" htmlFor="screeningDate">
							Screening Date: <span className="text-danger">*</span>
						</label>
						<div className="col-sm-8">
							<input
								type="date"
								className="form-control"
								name="screeningDate"
								value={biometrics.screeningDate || ""}
								onChange={(e) => handleSetBiometrics(e)}
								max={new Date().toISOString().split("T")[0]}
							/>
						</div>
					</div>

					<div className="form-group row">
						<label className="col-form-label col-sm-4" htmlFor="height">
							Height: <span className="text-danger">*</span>
						</label>
						<div className="col-sm-4">
							<div className="input-group">
								<input
									type="number"
									className="form-control"
									min="24"
									max="100"
									name="height"
									value={biometrics.height || ""}
									onChange={(e) => handleSetBiometrics(e)}
								/>
								<div className="input-group-append">
									<div className="input-group-text">in</div>
								</div>
							</div>
						</div>
					</div>

					<div className="form-group row">
						<label className="col-form-label col-sm-4" htmlFor="weight">
							Weight: <span className="text-danger">*</span>
						</label>
						<div className="col-sm-4">
							<div className="input-group">
								<input
									type="number"
									className="form-control"
									min="40"
									max="600"
									name="weight"
									value={biometrics.weight || ""}
									onChange={(e) => handleSetBiometrics(e)}
								/>
								<div className="input-group-append">
									<div className="input-group-text">lb</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-sm-12 col-md-6">
					<div className="form-group row">
						<label className="col-form-label col-sm-4" htmlFor="systolicBloodPressure">
							Systolic: <span className="text-danger">*</span>
						</label>
						<div className="col-sm-4">
							<input
								type="number"
								className="form-control"
								min="30"
								max="250"
								name="systolicBloodPressure"
								value={biometrics.systolicBloodPressure || ""}
								onChange={(e) => handleSetBiometrics(e)}
							/>
						</div>
					</div>

					<div className="form-group row">
						<label className="col-form-label col-sm-4" htmlFor="diastolicBloodPressure">
							Diastolic: <span className="text-danger">*</span>
						</label>
						<div className="col-sm-4">
							<input
								type="number"
								className="form-control"
								min="30"
								max="250"
								name="diastolicBloodPressure"
								value={biometrics.diastolicBloodPressure || ""}
								onChange={(e) => handleSetBiometrics(e)}
							/>
						</div>
					</div>

					<div className="form-group row">
						<label className="col-form-label col-sm-4" htmlFor="nicotine">
							Nicotine Use: <span className="text-danger">*</span>
						</label>
						<div className="col-sm-4">
							<select className="form-control" name="nicotine" value={biometrics.nicotine || ""} onChange={(e) => handleSetBiometrics(e)}>
								<option value="">- Select -</option>
								<option value="true">Yes</option>
								<option value="false">No</option>
							</select>
						</div>
					</div>
				</div>
			</div>

			<h6>Blood Values</h6>
			<div className="row mb-3">
				<div className="col-sm-12 col-md-6">
					<div className="form-group row">
						<label className="col-form-label col-sm-4" htmlFor="cholesterolTotal">
							Cholesterol:
						</label>
						<div className="col-sm-4">
							<input
								type="number"
								className="form-control"
								min="10"
								max="400"
								name="cholesterolTotal"
								value={biometrics.cholesterolTotal || ""}
								onChange={(e) => handleSetBiometrics(e)}
							/>
						</div>
					</div>

					<div className="form-group row">
						<label className="col-form-label col-sm-4" htmlFor="hdl">
							HDL:
						</label>
						<div className="col-sm-4">
							<input
								type="number"
								className="form-control"
								min="10"
								max="200"
								name="hdl"
								value={biometrics.hdl || ""}
								onChange={(e) => handleSetBiometrics(e)}
							/>
						</div>
					</div>
					<div className="form-group row">
						<label className="col-form-label col-sm-4" htmlFor="ldl">
							LDL:
						</label>
						<div className="col-sm-4">
							<input
								type="number"
								className="form-control"
								min="10"
								max="300"
								name="ldl"
								value={biometrics.ldl || ""}
								onChange={(e) => handleSetBiometrics(e)}
							/>
						</div>
					</div>
					<div className="form-group row">
						<label className="col-form-label col-sm-4" htmlFor="glucose">
							Glucose:
						</label>
						<div className="col-sm-4">
							<input
								type="number"
								className="form-control"
								min="10"
								max="500"
								name="glucose"
								value={biometrics.glucose || ""}
								onChange={(e) => handleSetBiometrics(e)}
							/>
						</div>
					</div>
				</div>
				<div className="col-sm-12 col-md-6">
					<div className="form-group row">
						<label className="col-form-label col-sm-4" htmlFor="triglycerides">
							Triglycerides:
						</label>
						<div className="col-sm-4">
							<input
								type="number"
								className="form-control"
								min="10"
								max="500"
								name="triglycerides"
								value={biometrics.triglycerides || ""}
								onChange={(e) => handleSetBiometrics(e)}
							/>
						</div>
					</div>

					<div className="form-group row">
						<label className="col-form-label col-sm-4" htmlFor="nonHDL">
							Non HDL:
						</label>
						<div className="col-sm-4">
							<input type="number" className="form-control" name="nonHDL" value={biometrics.nonHDL || ""} onChange={(e) => handleSetBiometrics(e)} />
						</div>
					</div>

					<div className="form-group row">
						<label className="col-form-label col-sm-4" htmlFor="hbA1c">
							HbA1c:
						</label>
						<div className="col-sm-4">
							<div className="input-group">
								<input
									type="number"
									className="form-control"
									min="1"
									max="100"
									name="hbA1c"
									onChange={(e) => handleSetBiometrics(e)}
								/>
								<div className="input-group-append">
									<div className="input-group-text">%</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="row mb-5">
				<div className="col-12">
					<button className="btn btn-secondary" onClick={() => handleSaveScreening(biometrics)} disabled={saveDisabled}>
						Save Biometrics
					</button>
				</div>
			</div>
		</Fragment>
	);
};

Biometrics.defaultProps = {};

export default Biometrics;