import React, { Fragment, useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { faHandPointRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TIMEZONE } from "../../../constants/constants";
import CalendarService from "../../../services/calendar.service";

const Confirmation = () => {
	const location = useLocation();

	const appointmentInformation = location.state && location.state.appointmentInformation;

	const [calendarTimeZone, setCalendarTimeZone] = useState(TIMEZONE.CENTRAL);

	useEffect(() => {
		CalendarService.getCalendars()
			.then((res) => {
				if (res.data) {
					setCalendarTimeZone(res.data.find((calendar) => calendar.id === appointmentInformation.location.calendarID)?.timezone || TIMEZONE.CENTRAL);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	}, [appointmentInformation.location.calendarID]);

	return (
		<div id="appointment-confirmation">
			{appointmentInformation?.modality === "on-site" && (
				<Fragment>
					<h1 className="my-5">Your Appointment is Confirmed!</h1>

					<div className="p-5 mb-5" id="appointment-confirmation-box">
						<p>
							<strong>When:</strong>{" "}
							{format(utcToZonedTime(appointmentInformation?.datetime, calendarTimeZone), "MMMM do, yyyy h:mm bbbb")}
						</p>
						<p>
							<strong>Location:</strong> {appointmentInformation?.location?.location}
						</p>
					</div>
				</Fragment>
			)}

      {appointmentInformation?.modality === "on-demand" && (
        <Fragment>
			<h1 className="my-5">Your Request is Confirmed!</h1>
          <div className="alert alert-warning shadow-sm">
						<p>
							<FontAwesomeIcon icon={faHandPointRight} /> If you're using a cell phone for your appointment, remember to set your
							phone to accept anonymous calls. The call will use minutes from your cell phone plan.
						</p>
						<p>
							Your phone appointment will begin after a provider accepts the appointment and reviews the details, during regular business
							hours Monday - Friday 8am - 5pm.
						</p>
					</div>
        </Fragment>
      )}

			<Link className="btn btn-primary btn-block" to="/">
				Done
			</Link>
		</div>
	);
};

Confirmation.defaultProps = {};

export default Confirmation;
