import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { formatDistance } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
//import { Modal } from "react-bootstrap";
//import parseISO from "date-fns/parseISO";
//import differenceInMinutes from "date-fns/differenceInMinutes";

const RequestList = ({ telehealthRequests }) => {
	

	/*
	const [selectedRequest, setSelectedRequest] = useState(null);
const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);

	const confirmAcceptRequest = (acceptedStartMinutes) => {
		handleAcceptRequest(selectedRequest, acceptedStartMinutes);
		setShow(false);
	};

	
	const showAcceptOptions = (request) => {
		setSelectedRequest(request);
		//Show accept options dialog
		setShow(true);
	};


			<Modal show={show} onHide={handleClose} animation={false}>
				<Modal.Header closeButton>
					<Modal.Title>Accept Appointment</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>Accept request and start the appointment within the next:</p>
					<p>
						<button className="btn btn-primary btn-block" onClick={() => confirmAcceptRequest(10)}>
							10 minutes
						</button>
					</p>
					<p>
						<button className="btn btn-primary btn-block" onClick={() => confirmAcceptRequest(30)}>
							30 minutes
						</button>
					</p>
					<p>
						<button className="btn btn-primary btn-block" onClick={() => confirmAcceptRequest(60)}>
							1 hour
						</button>
					</p>
					<p>Accept only those requests you can fulfill within the next hour.</p>
				</Modal.Body>
			</Modal>

	*/
	return (

			<table className="table">
				<tbody>
					{telehealthRequests.filter((request) => request.status === "processing").map((request, index) => {
						let distance = formatDistance(
							utcToZonedTime(request.created, "America/Chicago"),
							utcToZonedTime(new Date(), "America/Chicago"),
							{ addSuffix: true }
						);

						//let approximateStartTime = add(parseISO(request.acceptedTime), { minutes: request.acceptedStartMinutes });
						//let minutesToAppointment = differenceInMinutes(approximateStartTime, new Date());
						return (
							<tr key={request._id}>
								<td>
									<Link to={`/appointment/detail/${request._id}`}>
										{request.patient?.lastName}, {request.patient?.firstName}
									</Link>
									<br />
									<small>Requested {distance} | State: {request.state}</small>
								</td>
								<td>
									<Link to={`/appointment/detail/${request._id}`}>{request.type || ""}</Link>
								</td>
								<td>
									<Fragment>
										{/*
											{request.status === "accepted" && ()}
											<span className={`${minutesToAppointment <= 10 && "imminent-start"}`}>
												Start by {format(approximateStartTime, "hh:mm b")}
											</span>
											*/}

										<Link to={`/appointment/detail/${request._id}`} className="btn btn-outline-primary ml-3">
											Start
										</Link>
									</Fragment>

									{/*request.status === "processing" && (
										<button className="btn btn-outline-primary" onClick={() => showAcceptOptions(request)}>
											Accept
										</button>
									)*/}
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>

			

	);
};

RequestList.defaultProps = {};

export default RequestList;