import React, { useState, useEffect } from "react";
import AppointmentService from "../../../services/appointment.service";

const Alternative = ({ appointmentAlternative, handleSetAppointmentAlternative, handleNextClick }) => {
	const [alternatives, setAlternatives] = useState([]);
	const [selectedAlternative, setSelectedAlternative] = useState("");

	useEffect(() => {
		setAlternatives(AppointmentService.alternatives);
	}, []);

	useEffect(() => {
		if (appointmentAlternative) {
			setSelectedAlternative(appointmentAlternative);
		}
	}, [appointmentAlternative]);

	return (
		<div id="appointment-alternative">
			<h1 className="my-3">
				Without Archer Health, where would you have gone to seek medical care?
			</h1>			

			<div className="list-group">
				{alternatives.map((alternative) => (
					<button
						onClick={() => handleSetAppointmentAlternative(alternative)}
						className={`list-group-item list-group-item-action shadow-sm ${selectedAlternative === alternative && "active"}`}
						key={alternative}>
						<span className="card-title">{alternative}</span>
					</button>
				))}
			</div>
            
			<p className="my-3">
				<button className="btn btn-primary btn-block" onClick={handleNextClick} disabled={selectedAlternative?.length === 0}>
					NEXT &raquo;
				</button>
			</p>
		</div>
	);
};

Alternative.defaultProps = {};

export default Alternative;
