import React, { Fragment, useEffect, useState } from "react";
import useForm from "../../useForm";
import validate from "./ProviderValidationRules";
import { Link, useParams } from "react-router-dom";
import AuthService from "../../../services/auth.service";
import FlashMessage from "../../FlashMessage";
import * as CONSTANT from "../../../constants/constants";
import { useHistory } from "react-router-dom";
import AccountService from "../../../services/account.service";
import ReturnHome from "../../Navigation/ReturnHome";
import Loader from "../../UIHelpers/Loader";

/* This view is shared across the board - admin role sees additional fields */

const ProviderDetail = ({currentUser}) => {
	const params = useParams();
	const [flashMessage, setFlashMessage] = useState("");
	const [flashMessageType, setFlashMessageType] = useState("");
	const [loading, setLoading] = useState(false);

	const history = useHistory();

	const saveAccount = () => {
		setLoading(true);
		setFlashMessage("");

		let account = {
			_id: params.providerId || null,
			active: values.active,
			email: values.email,
			mobile: values.mobile,
			firstName: values.firstName,
			lastName: values.lastName,
		};

		//if params.employerId then save/update
		if (params.providerId) {
			AuthService.updateEmployeeAccount(account)
				.then((response) => {
					setFlashMessage("Provider Updated");
					setFlashMessageType(CONSTANT.ALERT_TYPE.SUCCESS);
				})
				.catch((error) => {
					const resMessage =
						(error.response && error.response.data && error.response.data.message) || error.message || error.toString();

					setFlashMessage(resMessage);
					setFlashMessageType(CONSTANT.ALERT_TYPE.ERROR);
				})
				.finally(() => setLoading(false));
		}
		//if !params.providerId then create
		else {
			AccountService.addProvider(account)
				.then((response) => {
					console.log(response);

					history.push({
						pathname: "/provider/detail/" + response.data._id,
					});
				})
				.catch((error) => {
					const resMessage =
						(error.response && error.response.data && error.response.data.message) || error.message || error.toString();

					setFlashMessage(resMessage);
					setFlashMessageType(CONSTANT.ALERT_TYPE.ERROR);
				})
				.finally(() => setLoading(false));
		}
	};
	const { setValues, values, errors, handleChange, handleSubmit } = useForm(saveAccount, validate);

	useEffect(() => {
		//Load account data if accountId is defined, otherwise skip */
		if (!params.providerId) return;

		setLoading(true);

		AuthService.getAccountById(params.providerId)
			.then((response) => {
				setValues((values) => ({
					...response.data,
				}));
			})
			.finally(() => {
				setLoading(false);
			});
	}, [params.providerId, setValues]);

	return (
		<Fragment>
			<ReturnHome>
				<li className="breadcrumb-item">
					{currentUser?.role === "admin" ?
					<Link to="/provider/list">Providers</Link> : 
					"Providers"
					}
				</li>
				<li className="breadcrumb-item active">Provider Detail</li>
			</ReturnHome>

			<h1>Provider Detail</h1>

			{flashMessage.length > 0 && <FlashMessage message={flashMessage} messageType={flashMessageType} />}

			<Loader show={loading} />

			{!loading && (
				<Fragment>
					<div className="form-group">
						<input
							type="checkbox"
							className="form-control-check"
							checked={values.active || false}
							onChange={() => setValues({ ...values, active: !values.active })}
						/>{" "}
						Active
					</div>

					<div className="form-group">
						<label>
							First name <span className="text-danger">*</span>
						</label>
						<input
							type="text"
							className={`form-control ${errors.firstName && "is-invalid"}`}
							name="firstName"
							value={values.firstName || ""}
							onChange={handleChange}
						/>
						<div className="invalid-feedback">{errors.firstName}</div>
					</div>

					<div className="form-group">
						<label>
							Last name <span className="text-danger">*</span>
						</label>
						<input
							type="text"
							className={`form-control ${errors.lastName && "is-invalid"}`}
							name="lastName"
							value={values.lastName || ""}
							onChange={handleChange}
						/>
						<div className="invalid-feedback">{errors.lastName}</div>
					</div>

					<div className="form-group">
						<label>
							Email <span className="text-danger">*</span>
						</label>
						<input
							type="text"
							className={`form-control ${errors.email && "is-invalid"}`}
							name="email"
							value={values.email || ""}
							onChange={handleChange}
						/>
						<div className="invalid-feedback">{errors.email}</div>
					</div>

					<div className="form-group">
						<label>
							Mobile <span className="text-danger">*</span>
						</label>
						<input
							type="text"
							className={`form-control ${errors.mobile && "is-invalid"}`}
							name="mobile"
							value={values.mobile || ""}
							onChange={handleChange}
						/>
						<div className="invalid-feedback">{errors.mobile}</div>
					</div>

					<p>
						<button className="btn btn-primary" onClick={handleSubmit}>
							Save
						</button>
					</p>
				</Fragment>
			)}
		</Fragment>
	);
};

ProviderDetail.defaultProps = {};

export default ProviderDetail;
