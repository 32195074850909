import React from "react"
import Login from '../../components/Login'
import ForgotPassword from '../../components/Account/ForgotPassword'
import VerifyAccount from '../../components/VerifyAccount'
import { Route, Switch } from 'react-router-dom'
import ResetPassword from "../../components/ResetPassword"
import PrivacyPolicy from "../../components/StaticContent/PrivacyPolicy";

const LoginContainer = () => {
  return (
    <Switch>
      <Route path="/privacy-policy" render={(props) => (<PrivacyPolicy />)} />
      <Route exact path="/forgot-password" render={(props) => (<ForgotPassword title="Forgot Password" />)} />
      {/*<Route exact path="/change-password" render={(props) => (<ChangePassword title="Change Password" />)} />*/}
      <Route path="/verify-account" render={(props) => (<VerifyAccount />)} />
      <Route path="/" exact render={(props) => (<Login title="Login" />)} />
      <Route path="/login" exact render={(props) => (<Login title="Login" />)} />
      <Route path="/reset-password/:resetToken" exact render={(props) => (<ResetPassword />)} />

      {/* Catch unmatched routes 
      <Route>
        <Redirect to="/" />
      </Route>*/}
    </Switch>
  );
}

LoginContainer.defaultProps = {};

export default LoginContainer;