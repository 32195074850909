import React, { Fragment, useEffect, useState } from "react";
import AppointmentService from "../../../../services/appointment.service";
import CheckboxGroup from "react-checkbox-group";
import { nanoid } from "nanoid";

const MedicalHistoryConditions = ({ appointmentConditions, handleSetAppointmentConditions, handleNextClick }) => {
	const [conditions, setConditions] = useState([]);
	const [selectedConditions, setSelectedConditions] = useState([]);
	const [noSelection, setNoSelection] = useState(false);

	useEffect(() => {
		setConditions(AppointmentService.conditions);
	}, []);

	useEffect(() => {
		if (appointmentConditions) {
			setSelectedConditions(appointmentConditions);
		}
	}, [appointmentConditions]);

	const handleSelectNone = (event) => {
		let isChecked = event.target.checked;
		setNoSelection(isChecked);

		if (isChecked) {
			handleSetAppointmentConditions([]);
		}
	};

	return (
		<div id="appointment-conditions">
			<h1 className="my-3">
				Do you have any of the following health conditions?
			</h1>

			<ul className="list-group my-5">
				<li className={`list-group-item ${noSelection && "active"}`}>
					<div className="form-check">
						<input type="checkbox" className="form-check-input" onChange={handleSelectNone} /> 
						<label className="form-check-label">None of the conditions below</label>
					</div>					
				</li>
			</ul>

			{!noSelection && (
				<CheckboxGroup name="symptoms" value={selectedConditions} onChange={handleSetAppointmentConditions}>
					{(Checkbox) => (
						<Fragment key={nanoid()}>
							<ul className="list-group">
							{conditions.map((condition) => (
								<li className={`list-group-item ${selectedConditions.includes(condition.name) && "active"}`} key={condition.name}>
									<div className="form-check">
										<Checkbox className="form-check-input" value={condition.name} />
										<label className="form-check-label">{condition.name}</label>
									</div>
								</li>
							))}
							</ul>
						</Fragment>
					)}
				</CheckboxGroup>
			)}

			<p className="my-3">
				<button className="btn btn-primary btn-block" onClick={handleNextClick}>
					NEXT &raquo;
				</button>
			</p>
		</div>
	);
};

MedicalHistoryConditions.defaultProps = {};

export default MedicalHistoryConditions;
