import React, { Fragment, useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PaymentService from "../../../services/payment.service";
import CardList from './CardList'

import validate from "./AddPaymentValidationRules";
import useForm from "../../useForm";
import Loader from "../../UIHelpers/Loader";

const PaymentMethods = ({clickAction, selectedPaymentMethod, showHeader}) => {

	const handleAddPaymentMethod = () => {
		setLoading(true);

		var card = {};
		card.cardNumber = values.cardNumber;
		card.expMonth = values.expiration.substring(values.expiration.length - 2);
		card.expYear = values.expiration.substring(0, 4);
		card.cvv = values.cvv;

		PaymentService.addPaymentMethod(card)
			.then(function (cards) {
				if (cards.data) {
					//hide add form
					setShowAddPayment(false);
					//clear values
					setValues({});
					//update card list
					setCardList(cards.data);
				}

				setLoading(false);
			})
			.catch((error) => {
				setLoading(false);

				const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
				console.error(resMessage);
			});
	};

	const handleCancelAddPaymentMethod = () => {
		//clear values
		setValues({});
		setShowAddPayment(false);
	};

	const [cardList, setCardList] = useState([]);
	const [showAddPayment, setShowAddPayment] = useState(false);
	const [loading, setLoading] = useState(true);

	const { setValues, values, errors, handleChange, handleSubmit } = useForm(handleAddPaymentMethod, validate);

	useEffect(() => {
		PaymentService.getMyCards().then(function (cards) {
			if (cards.data) {
				setCardList(cards.data.data);
				setLoading(false);
			}
		});
	}, []);

	return (
		<Fragment>
			{showHeader && <h1>Payment Methods</h1>}
			

			<Loader show={loading} />

			<div className="card shadow-sm p-3">
				<div className="card-body">
					
					<CardList cards={cardList} clickAction={clickAction} selectedPaymentMethod={selectedPaymentMethod} />	
								

					{!showAddPayment && (
						<p>
							<button className="btn btn-primary" onClick={() => setShowAddPayment(true)}>
								<FontAwesomeIcon icon={faPlusCircle} /> Add New Payment Method
							</button>
						</p>
					)}

					{showAddPayment && (
						<Fragment>
							<div className="form-row">
								<div className="form-group col-md-6 col-sm-12">
									<label>Card Number</label>
									<input
										type="number"
										name="cardNumber"
										className={`form-control ${errors.cardNumber && "is-invalid"}`}
										value={values.cardNumber || ""}
										onChange={handleChange}
									/>
									<div className="invalid-feedback">{errors.cardNumber}</div>
								</div>
							</div>
							<div className="form-row">
								<div className="form-group col-md-3 col-sm-6">
									<label>Expiration</label>
									<input
										type="month"
										name="expiration"
										className={`form-control ${errors.expiration && "is-invalid"}`}
										value={values.expiration || ""}
										onChange={handleChange}
									/>
									<div className="invalid-feedback">{errors.expiration}</div>
								</div>

								<div className="form-group col-md-3 col-sm-6">
									<label>CVV</label>
									<input
										type="number"
										name="cvv"
										className={`form-control ${errors.cvv && "is-invalid"}`}
										value={values.cvv || ""}
										onChange={handleChange}
									/>
									<div className="invalid-feedback">{errors.cvv}</div>
								</div>
							</div>
							<p>
								<button className="btn btn-primary" onClick={handleSubmit}>
									OK
								</button>
								<button className="btn btn-link" onClick={handleCancelAddPaymentMethod}>
									Cancel
								</button>
							</p>
						</Fragment>
					)}
				</div>
			</div>
		</Fragment>
	);
};


PaymentMethods.propTypes = {
	showHeader: PropTypes.bool
};


PaymentMethods.defaultProps = {
	showHeader: true
};

export default PaymentMethods;
