export default function validate(values) {
    
    let errors = {};

    if (values.confirmEmail && (values.confirmEmail.indexOf("@") === -1)) {
        errors.confirmEmail = "Invalid email address"
    }

    if (values.confirmEmail && (values.confirmEmail.indexOf(".") === -1)) {
        errors.confirmEmail = "Invalid email address"
    }

    if (values.confirmMobile && (values.confirmMobile.length < 10)) {
        errors.confirmMobile = "Invalid phone number"
    }

    return errors;
};