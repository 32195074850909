import React, { Fragment, useEffect, useState } from "react";
import AppointmentProduct from "../Product";
import SelectEmployer from "./SelectEmployer";
import * as CONSTANT from "../../../constants/constants";
import PaymentService from "../../../services/payment.service";
import AppointmentService from "../../../services/appointment.service";
import FlashMessage from "../../FlashMessage";
import EmployerService from "../../../services/employer.service";
import AuthService from "../../../services/auth.service";
import AppointmentDateTime from "../DateTime";
import { Link } from "react-router-dom";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import Loader from "../../UIHelpers/Loader";
//import CalendarService from "../../../services/calendar.service";

const ProxyAppointment = ({currentUser}) => {
	const [flashMessage, setFlashMessage] = useState("");
	const [flashMessageType, setFlashMessageType] = useState("");
	const [loading, setLoading] = useState(false);

    const [selectedEmployer, setSelectedEmployer] = useState(null);
	const [selectedEmployee, setSelectedEmployee] = useState(null);

	const [step, setStep] = useState(null);
	const [employers, setEmployers] = useState([]);
	const [employees, setEmployees] = useState([]);
	const [dependents, setDependents] = useState([]);
	const [products, setProducts] = useState([]);


	const [appointmentProcessing, setAppointmentProcessing] = useState(false);
    //Set initial appointment details
	const [appointmentDetails, setAppointmentDetails] = useState({
		datetime: null,
		location: {
			calendarID: null,
			location: null,
		},
		product: {
			price: 0,			
		},
        account: null,
		accountEmail: null,
		accountMobile: null,
		employer: null,
		type: null,
		modality: "on-site",		
		alternative: "Unknown",
		contact: CONSTANT.CONTACT.PHONE,
		patient: null,
		receipt: {},
		employerName: null,
		patientFirstName: null,
		patientLastName: null
    });
	
	//load employers    
	useEffect(() => {
		setLoading(true);

		AuthService.getEmployers()
			.then((employers) => {
				setEmployers(employers.data);
			})
			.catch((error) => {
				const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

				setFlashMessage(resMessage);
				setFlashMessageType(CONSTANT.ALERT_TYPE.ERROR);
			})
			.finally(() => {
				setLoading(false);
			});
	}, []);

    //Load employees from selected employer
	useEffect(() => {
		if (appointmentDetails.employer === null) return;

		setLoading(true);

		EmployerService.getEmployerRoster(appointmentDetails.employer)
			.then((employees) => {
				setEmployees(employees.data);
			})
			.catch((error) => {
				const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

				setFlashMessage(resMessage);
				setFlashMessageType(CONSTANT.ALERT_TYPE.ERROR);
			})
			.finally(() => {
				setLoading(false);
			});
	}, [appointmentDetails.employer]);

    //Load selected employee's dependents
	useEffect(() => {
		if (appointmentDetails.account === null) return;

		setLoading(true);

		AuthService.getPatientsByAccount(appointmentDetails.account)
			.then((patients) => {
				setDependents(patients.data);
			})
			.catch((error) => {
				const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

				setFlashMessage(resMessage);
				setFlashMessageType(CONSTANT.ALERT_TYPE.ERROR);
			})
			.finally(() => {
				setLoading(false);
			});
	}, [appointmentDetails.account]);

	useEffect(() => {
		setLoading(true);

		PaymentService.findProductsByCategory(appointmentDetails.modality).then((products) => {
			setProducts(products.data);
		})		
		.catch((error) => {
			const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

			setFlashMessage(resMessage);
			setFlashMessageType(CONSTANT.ALERT_TYPE.ERROR);
		})
		.finally(() => {
			setLoading(false);
		});
	}, [appointmentDetails.modality]);

	//Get patient cost for selected service
	useEffect(() => {
		if (appointmentDetails.account && appointmentDetails.product?.productID) {
			PaymentService.getProductPricing(appointmentDetails.account, appointmentDetails.product?.productID).then(function (price) {
				setPatientCost(price.data.price);
			});
		}
	}, [appointmentDetails.account, appointmentDetails.product?.productID]);



	const setEmployer = (employerId) => {
        if (!employerId || employerId.length === 0) {
            setAppointmentDetails((appointmentDetails) => ({
                ...appointmentDetails,
                employer: null,
                account: null,
				accountEmail: null,
				accountMobile: null,
                patient: null,
				employerName: null,
				patientFirstName: null,
				patientLastName: null,
            }));
        } else {
            let employer = employers.find((employer) => { return employer._id === employerId; });
            if (employer) { setSelectedEmployer(employer); }

            setAppointmentDetails((appointmentDetails) => ({
                ...appointmentDetails,
                employer: employerId,
                account: null,
                patient: null,
				employerName: employer.name,
				patientFirstName: null,
				patientLastName: null,
            }));
        }
	};

	const setAccount = (employeeId) => {
		let employee = employees.find((employee) => { return employee._id === employeeId; });
		if (employee) { setSelectedEmployee(employee); }

		setAppointmentDetails((appointmentDetails) => ({
			...appointmentDetails,
			account: employeeId,
			accountEmail: employee.email ? employee.email : null,
			accountMobile: employee.mobile ? employee.mobile: null,
		}));
	};

	const setPatient = (patientId) => {
		let patient = dependents.find((dependent) => { return dependent._id === patientId; });

		setAppointmentDetails((appointmentDetails) => ({
			...appointmentDetails,
			patient: patientId,
			patientFirstName: patient.firstName,
			patientLastName: patient.lastName
		}));
	};

	const setAppointmentProduct = (appointmentProduct) => {
		setAppointmentDetails((appointmentDetails) => ({
			...appointmentDetails,
			product: appointmentProduct,			
		}));
	};

	const setAppointmentLocation = (appointmentLocation) => {
		if (appointmentLocation === null) {
			setAppointmentDetails((appointmentDetails) => ({
				...appointmentDetails,
				location: {				
					location: null,
					calendarID: null
				}
			}));
		} else {
			setAppointmentDetails((appointmentDetails) => ({
				...appointmentDetails,
				location: {				
					location: appointmentLocation.location,
					calendarID: appointmentLocation.calendarID
				}
			}));
		}
	}

	const setAppointmentDateTime = (appointmentDateTime) => {
		setAppointmentDetails((appointmentDetails) => ({
			...appointmentDetails,
			datetime: appointmentDateTime
		}));		
	};

	const setPatientCost = (patientCost) => {
		setAppointmentDetails((appointmentDetails) => ({
			...appointmentDetails,
			product: {
				...appointmentDetails.product,
				price: patientCost,
			},
		}));
	};


	const handleConfirmPatient = () => {
		setStep("visit-reason");
	}

    const handleConfirmProduct = () => {
		setStep("visit-date-time");
		//setShowAppointmentProduct(false);
        //setShowDateTime(true);
    }

	const handleConfirmDateTime = () => {
		setStep("confirm");		
	}

	const handleCancelScheduleAppointment = () => {
		setStep(null);

		setAppointmentDetails({
			datetime: null,
			location: {
				calendarID: null,
				location: null,
			},
			product: {
				price: 0,
			},
			account: null,
			accountEmail: null,
			accountMobile: null,
			employer: null,
			type: null,
			modality: "on-site",		
			alternative: "Unknown",
			contact: CONSTANT.CONTACT.PHONE,
			patient: null,
			receipt: {},
			employerName: null,
			patientFirstName: null,
			patientLastName: null
		});
	}

	const handleScheduleAppointment = () => {
		//Schedule appointment when details are complete
		//User has pressed the "Confirm" button save appointment

		setAppointmentProcessing(true);
		
		AppointmentService.adminScheduleAppointment(appointmentDetails).then(function (response) {
			setAppointmentProcessing(false);
			setStep("confirmation");

		}).catch((error) => {
			const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

			setAppointmentProcessing(false);

			setFlashMessage(resMessage);
			setFlashMessageType(CONSTANT.ALERT_TYPE.ERROR);
		});
	}

	const renderStep = (step) => {
		switch (step) {
			case "visit-reason":
				return <AppointmentProduct 
                    appointmentProduct={appointmentDetails.product}
                    employer={selectedEmployer}
					currentUser={appointmentDetails.account}
                    products={products}
                    handleSetAppointmentProduct={setAppointmentProduct}
                    handleNextClick={handleConfirmProduct}
                />;

			case "visit-date-time":
				return <AppointmentDateTime 
					appointmentDateTime={appointmentDetails.datetime}
					appointmentLocation={appointmentDetails.location}
					appointmentTypeID={appointmentDetails.product?.appointmentTypeID}
					employer={selectedEmployer}
					isProxy={true}
					currentUser={selectedEmployee}
					handleSetAppointmentLocation={setAppointmentLocation}
					handleSetAppointmentDateTime={setAppointmentDateTime}
					handleNextClick={handleConfirmDateTime}
				/>;

			case "confirm":
				return <ConfirmAppointmentDetails 
					appointment={appointmentDetails}
					handleScheduleAppointment={handleScheduleAppointment}
					handleCancelScheduleAppointment={handleCancelScheduleAppointment}
					appointmentProcessing={appointmentProcessing} />;

			case "confirmation":
				return <Confirmation />;

			default:
				return <SelectEmployer
				currentUser={currentUser}
                employers={employers}
                employees={employees}
                dependents={dependents}
                handleSetEmployer={setEmployer}
                handleSetAccount={setAccount}
                handleSetPatient={setPatient}
			handleNextClick={handleConfirmPatient}
                selectedEmployer={appointmentDetails.employer}
                selectedEmployee={appointmentDetails.account}
				selectedPatient={appointmentDetails.patient}
            />;
		}
	}

	return (
		<Fragment>
			{flashMessage.length > 0 && <FlashMessage message={flashMessage} messageType={flashMessageType} />}       

			<Loader show={loading} />

			{renderStep(step)}     
		</Fragment>
	);
};

ProxyAppointment.defaultProps = {};

export default ProxyAppointment;

const ConfirmAppointmentDetails = ({ appointment, handleScheduleAppointment, handleCancelScheduleAppointment, appointmentProcessing }) => {
	const timeZone = "America/Chicago";

	return (
		<div className="card">
			<div className="card-body">
				<h5 className="card-title">Confirm Appointment Details</h5>
				<p className="card-text">
					Employer: {appointment.employerName}<br/>
					Patient: {appointment.patientFirstName}{" "}{appointment.patientLastname}<br/>
					Reason for Visit: {appointment.product?.name}<br/>
					Patient Cost: ${appointment.product?.price}<br/>
					Appointment Date/Time: {format(utcToZonedTime(appointment.datetime, timeZone), "EEEE, LLL dd, yyyy h:mm aaaa")}
				</p>
				{appointmentProcessing && 
					<p><FontAwesomeIcon icon={faCircleNotch} spin /> <em>Scheduling Appointment...</em></p>
				}
				<p>
					<button onClick={handleCancelScheduleAppointment} className="btn btn-outline-secondary mr-3">Cancel</button>
					<button onClick={handleScheduleAppointment} className="btn btn-primary">Schedule Appointment</button>
				</p>
			</div>
		</div>
	);
};

const Confirmation = () => {
	return (
		<div className="card">
			<div className="card-body">
				<h5 className="card-title">Appointment Scheduled</h5>
				<Link to="/clinic/appointments">Return to Schedule Overview</Link>
			</div>
		</div>
	)
}
