import React, { Fragment, useState } from "react"
import useForm from "../../useForm"
import validate from "./ForgotPasswordValidationRules"
import AuthService from '../../../services/auth.service'
import * as CONSTANT from '../../../constants/constants'
import FlashMessage from '../../FlashMessage'
import { Link } from "react-router-dom";

const ForgotPassword = () => {    
	const [flashMessage, setFlashMessage] = useState("");
	const [flashMessageType, setFlashMessageType] = useState("");
	
    const handleForgotPassword = () => {
        console.info("handleForgotPassword");

        let user = {};
        if (values.emailPhone.indexOf("@") >= 0) {
            user.email = values.emailPhone;
        } else {
            user.mobile = values.emailPhone;
        }
        
        AuthService.forgotPassword(user).then((res) => {			
			setFlashMessage(res.data.message);
			setFlashMessageType(CONSTANT.ALERT_TYPE.SUCCESS);
			setValues({...values, emailPhone: ""})
        }).catch((error) => {
			const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
			console.error(resMessage);		
			
			setFlashMessage(resMessage);
			setFlashMessageType(CONSTANT.ALERT_TYPE.ERROR);			
		});;
    };

	const { setValues, values, errors, handleChange, handleSubmit } = useForm(handleForgotPassword, validate);

	
	return (
		<Fragment>
			<h1>Forgot Password</h1>

			{flashMessage.length > 0 &&
                <FlashMessage message={flashMessage} messageType={flashMessageType} />
            }

			<div className="form-group">
				<label>Email or phone</label>
				<input
					type="text"
					className={`form-control ${errors.emailPhone && "is-invalid"}`}
					name="emailPhone"
					value={values.emailPhone || ""}
					onChange={handleChange}
					placeholder="email@email.com or 555-555-5555"
				/>
				<div className="invalid-feedback">{errors.emailPhone}</div>
			</div>

			<p>
				<button className="btn btn-primary" onClick={handleSubmit}>
					Send Password Reset Message
				</button>
			</p>

			<p>
				<Link to="/" className="btn btn-link">Back to Login</Link>
			</p>
		</Fragment>
	);
};

ForgotPassword.defaultProps = {};

export default ForgotPassword;
