import React, { Fragment, useEffect, useState } from "react";
import useForm from "../../useForm";
import validate from "./AccountValidationRules";
import { Link, useParams } from "react-router-dom";
import AuthService from "../../../services/auth.service";
import EmployerService from "../../../services/employer.service";
import FlashMessage from "../../FlashMessage";
import * as CONSTANT from "../../../constants/constants";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useHistory } from "react-router-dom";
import CheckboxGroup from "react-checkbox-group";
import { nanoid } from "nanoid";
import ReturnHome from "../../Navigation/ReturnHome";
import Loader from "../../UIHelpers/Loader";
import ModalHelper from "../../UIHelpers/Modal";
import AppointmentService from "../../../services/appointment.service";
import AppointmentList from "../../Appointment/List";

/* This view is shared across the board - admin role sees additional fields */

const AccountDetail = ({ currentUser }) => {
	const params = useParams();
	const history = useHistory();

	const [flashMessage, setFlashMessage] = useState("");
	const [flashMessageType, setFlashMessageType] = useState("");
	const [loading, setLoading] = useState(false);
	const [saving, setSaving] = useState(false);
	const [employer, setEmployer] = useState(null);
	const [allLocations, setAllLocations] = useState(true);
	const [selectedLocations, setSelectedLocations] = useState([]);
	const [appointments, setAppointments] = useState([]);

	const saveAccount = () => {
		setSaving(true);
		setFlashMessage("");

		let account = {
			_id: params.accountId || null,
			active: values.active,
			role: values.isEmployerAdmin ? "employer" : "employee",
			email: values.email,
			mobile: values.mobile,
			firstName: values.firstName,
			lastName: values.lastName,
			gender: values.gender || "unspecified",
			employee_id: values.employee_id,
			birthday: moment(values.birthday.substring(0,10)).format("MM-DD-YYYY"),
			startDate: values.startDate ? moment(values.startDate.substring(0,10)).format("MM-DD-YYYY") : null,
			terminationDate: values.terminationDate ? moment(values.terminationDate.substring(0,10)).format("MM-DD-YYYY") : null,
			employer: employer,
			hsa_flag: values.hsa_flag,
			authorizedLocations: {
				all: allLocations,
				calendars: selectedLocations,
			},
		};
console.log(values);
	

		if (params.accountId) {
			//if params.accountId then save/update
			AuthService.updateEmployeeAccount(account)
				.then((response) => {
					setFlashMessage("Account Updated");
					setFlashMessageType(CONSTANT.ALERT_TYPE.SUCCESS);
				})
				.catch((error) => {
					const resMessage =
						(error.response && error.response.data && error.response.data.message) || error.message || error.toString();

					setFlashMessage(resMessage);
					setFlashMessageType(CONSTANT.ALERT_TYPE.ERROR);
				})
				.finally(() => setSaving(false));
		} else {
			//if !params.accountId then create
			EmployerService.addEmployee(account)
				.then((response) => {
					history.replace({
						pathname: "/account/detail/" + response.data.employer + "/" + response.data._id,
					});
				})
				.catch((error) => {
					const resMessage =
						(error.response && error.response.data && error.response.data.message) || error.message || error.toString();

					setFlashMessage(resMessage);
					setFlashMessageType(CONSTANT.ALERT_TYPE.ERROR);
				})
				.finally(() => setSaving(false));
		}
	};

	const { setValues, values, errors, handleChange, handleSubmit } = useForm(saveAccount, validate);

	useEffect(() => {
		//set employer from parameter, set the employee admin to false by default
		setValues((values) => ({
			...values,
			isEmployerAdmin: false,
		}));

		EmployerService.getEmployer(params.employerId).then((response) => {
			setEmployer(response.data);
		});

		//Load account data if accountId is defined, otherwise skip */
		if (!params.accountId) return;

		setLoading(true);

		AuthService.getAccountById(params.accountId)
			.then((response) => {
				setValues((values) => ({
					...values,
					...response.data,
				}));

				if (response.data.role === "employer") {
					setValues((values) => ({
						...values,
						isEmployerAdmin: true,
					}));
				}

				//Set user's authorized locations
				setAllLocations(response.data.authorizedLocations.all);
				setSelectedLocations(response.data.authorizedLocations.calendars);
			})
			.catch((error) => {
				const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

				setFlashMessage(resMessage);
				setFlashMessageType(CONSTANT.ALERT_TYPE.ERROR);
			})
			.finally(() => {
				setLoading(false);
			});
	}, [params.employerId, params.accountId, setValues]);

	useEffect(() => {
		if (selectedLocations.length > 0) {
			setAllLocations(false);
		}
	}, [selectedLocations]);

	useEffect(() => {
		if (allLocations) {
			setSelectedLocations([]);
		}
	}, [allLocations]);

	useEffect(() => {
		if (!params.accountId) return;
		AppointmentService.getAppointmentsByAccount(params.accountId).then((response) => {
			setAppointments(response.data);
		}).catch((error) => {
			const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

			setFlashMessage(resMessage);
			setFlashMessageType(CONSTANT.ALERT_TYPE.ERROR);
		});
	}, [params.accountId])

	return (
		<Fragment>
			<ReturnHome>
				<li className="breadcrumb-item">
					{currentUser.role === "admin" ? <Link to="/employer/list">Employers</Link> : "Employers" }
				</li>
				<li className="breadcrumb-item">
					{["admin", "employer"].includes(currentUser.role) ? <Link to={`/employer/detail/${params.employerId}`}>Employer Detail</Link> : "Employer Detail" }
				</li>
				<li className="breadcrumb-item">
					{["admin", "employer"].includes(currentUser.role) ? <Link to={`/employee/list/${params.employerId}`}>Employees</Link> : "Employees" }
				</li>
				<li className="breadcrumb-item active">Employee Detail</li>
			</ReturnHome>
			

			<h1>Account Detail</h1>

			{flashMessage.length > 0 && <FlashMessage message={flashMessage} messageType={flashMessageType} />}

			<Loader show={loading} />

			<div className={`${loading && "d-none"}`}>
				{params.accountId && (
					<Fragment>
						<div className={`alert alert-info ${!values.activated && "d-none"}`}>Account activated</div>
						<div className={`alert alert-warning ${values.activated && "d-none"}`}>Account not yet activated</div>
					</Fragment>
				)}

				<div className="form-group">
					<input
						type="checkbox"
						className="form-control-check"
						checked={values.active || false}
						onChange={() => setValues({ ...values, active: !values.active })}
					/>{" "}
					Active
				</div>

				{currentUser.role === "admin" && (
					<div className="form-group">
						<input
							type="checkbox"
							className="form-control-check"
							checked={values.isEmployerAdmin || false}
							onChange={(event) => setValues({ ...values, isEmployerAdmin: !values.isEmployerAdmin })}
						/>{" "}
						Employer Admin
					</div>
				)}

				<div className="form-group">
					<label>Employer</label>
					{employer && (
						<select className="form-control" disabled>
							<option value={employer._id} key={employer._id}>
								{employer.name}
							</option>
						</select>
					)}
				</div>

				<div className="form-row">
					<div className="form-group col-6">
						<label>
							Employee ID <span className="text-danger">*</span>
						</label>
						<input
							type="text"
							className={`form-control ${errors.employee_id && "is-invalid"}`}
							name="employee_id"
							value={values.employee_id || ""}
							onChange={handleChange}
							disabled={params.accountId}
						/>
						<div className="invalid-feedback">{errors.employee_id}</div>
					</div>
					<div className="form-group col-6">
						<div className="form-check">

							<input
								type="checkbox"
								className="form-control-check"
								checked={values.hsa_flag || false}
								onChange={() => setValues({ ...values, hsa_flag: !values.hsa_flag })}
							/>
							<label className="form-check-label">Health Savings Participant</label>
						</div>
					</div>
				</div>

				<div className="form-row">
					<div className="form-group col-6">
						<label>
							First name <span className="text-danger">*</span>
						</label>
						<input
							type="text"
							className={`form-control ${errors.firstName && "is-invalid"}`}
							name="firstName"
							value={values.firstName || ""}
							onChange={handleChange}
						/>
						<div className="invalid-feedback">{errors.firstName}</div>
					</div>

					<div className="form-group col-6">
						<label>
							Last name <span className="text-danger">*</span>
						</label>
						<input
							type="text"
							className={`form-control ${errors.lastName && "is-invalid"}`}
							name="lastName"
							value={values.lastName || ""}
							onChange={handleChange}
						/>
						<div className="invalid-feedback">{errors.lastName}</div>
					</div>
				</div>

				<div className="form-row">
					<div className="form-group col-6">
						<label>Mobile</label>
						<input
							type="text"
							className={`form-control ${errors.mobile && "is-invalid"}`}
							name="mobile"
							value={values.mobile || ""}
							onChange={handleChange}
						/>
						<div className="invalid-feedback">{errors.mobile}</div>
					</div>
					<div className="form-group col-6">
						<label>Email</label>
						<input
							type="text"
							className={`form-control ${errors.email && "is-invalid"}`}
							name="email"
							value={values.email || ""}
							onChange={handleChange}
							disabled={params.accountId}
						/>
						<div className="invalid-feedback">{errors.email}</div>
					</div>
				</div>

				<div className="form-row">
					<div className="form-group col-6">
						<label>Gender</label>
						<select
							className={`form-control ${errors.gender && "is-invalid"}`}
							name="gender"
							value={values.gender || "unspecified"}
							onChange={handleChange}>
							<option value="male">M</option>
							<option value="female">F</option>
							<option value="unspecified">Unspecified</option>
						</select>
						<div className="invalid-feedback">{errors.gender}</div>
					</div>
					<div className="form-group col-6">
						<label>
							Date of birth <span className="text-danger">*</span>
						</label>
						<input
							type="date"
							className={`form-control ${errors.birthday && "is-invalid"}`}
							name="birthday"
							value={values.birthday ? values.birthday?.substring(0, 10) : ""}
							onChange={handleChange}						
						/>
						<div className="invalid-feedback">{errors.birthday}</div>
					</div>
				</div>

				<div className="form-row">
					<div className="form-group col-6">
						<label>Start date</label>
						<input
							type="date"
							className={`form-control ${errors.startDate && "is-invalid"}`}
							name="startDate"
							value={values.startDate ? values.startDate.substring(0, 10) : ""}
							onChange={handleChange}
							disabled={params.accountId}
						/>
						<div className="invalid-feedback">{errors.firstName}</div>
					</div>

					<div className="form-group col-6">
						<label>Termination date</label>
						<input
							type="date"
							className={`form-control ${errors.terminationDate && "is-invalid"}`}
							name="terminationDate"
							value={values.terminationDate ? values.terminationDate.substring(0, 10) : ""}
							onChange={handleChange}
							disabled={values.active}
						/>
						<div className="invalid-feedback">{errors.terminationDate}</div>
					</div>
				</div>
				{params.accountId && (
					<Fragment>
						<p className="mb-3">
							<strong>Authorized Clinic Locations</strong>
						</p>

						{employer && (
							<Fragment>
								<div className="form-check">
									<input
										className="form-check-input"
										type="checkbox"
										checked={allLocations}
										value={true}
										onChange={(e) => setAllLocations(!allLocations)}
									/>
									<label className="form-check-label">All</label>
								</div>

								<CheckboxGroup name="allowedLocations" value={selectedLocations} onChange={setSelectedLocations}>
									{(Checkbox) => (
										<Fragment key={nanoid()}>
											{employer.calendars.map((location) => (
												<div className="form-check" key={nanoid()}>
													<Checkbox
														className="form-check-input"
														value={location.calendarID}
														disabled={allLocations}
													/>
													<label className="form-check-label">{location.location}</label>
												</div>
											))}
										</Fragment>
									)}
								</CheckboxGroup>
							</Fragment>
						)}
					</Fragment>
				)}
				<p className="mt-3">
					<button className="btn btn-primary" onClick={handleSubmit}>
						Save
					</button>
				</p>

				<hr />

				<AppointmentList appointments={appointments} />
			</div>

			{/* Saving Modal */}
			<ModalHelper show={saving}>
				<p>
					<FontAwesomeIcon icon={faCircleNotch} spin /> <em>Saving...</em>
				</p>
			</ModalHelper>
		</Fragment>
	);
};

AccountDetail.defaultProps = {};

export default AccountDetail;
