import API from "./API";


const login = async (emailAddress, password) => {
    return await API.post(process.env.REACT_APP_API_BASE_URL + "/auth/login", { email: emailAddress, password: password })
};

const logout = () => {
    sessionStorage.removeItem("archer-user");
    sessionStorage.removeItem("archer-user-token");
};

const getCurrentUser = () => {
    let user = JSON.parse(sessionStorage.getItem("archer-user"));

    return user;
};

const findNewAccount = async (account) => {
    return await API.post(process.env.REACT_APP_API_BASE_URL + "/auth/findNewAccount", account)
}

const register = async (user) => {
    return await API.post(process.env.REACT_APP_API_BASE_URL + "/auth/register", user)
}

const getToken = () => {
    return sessionStorage.getItem("archer-user-token");
}

const saveToken = (token) => {
    sessionStorage.setItem("archer-user-token", token);
}

const saveProfile = (profile) => {
    localStorage.setItem("archer-user-username", (profile.email?.length > 0) ? profile.email : profile.mobile);
    sessionStorage.setItem("archer-user", JSON.stringify(profile));
}

const forgotPassword = async (user) => {
    return await API.post(process.env.REACT_APP_API_BASE_URL + "/auth/forgot", user);
}

const resetPassword = async (token, user) => {
    return await API.post(process.env.REACT_APP_API_BASE_URL + "/auth/reset/" + token, user);
}

const getMyProfile = async () => {
    return await API.get(process.env.REACT_APP_API_BASE_URL + "/auth/me");
}

const updateMyAccount = async (profile) => {
    return await API.patch(process.env.REACT_APP_API_BASE_URL + "/auth/me", profile);
}

const getEmployers = async () => {
    return await API.get(process.env.REACT_APP_API_BASE_URL + "/auth/employers");
}

const uploadEmployeesFromCsv = async (employees) => {
    return await API.post(process.env.REACT_APP_API_BASE_URL + "/auth/upload", employees);
}

const getAccountById = async (accountId) => {
    return await API.get(process.env.REACT_APP_API_BASE_URL + "/auth/account/" + accountId);
}

const getProviders = async() => {
    return await API.get(process.env.REACT_APP_API_BASE_URL + "/auth/providers")
}

const updateEmployeeAccount = async(account) => {
    return await API.post(process.env.REACT_APP_API_BASE_URL + "/auth/update", account);
}

const getPatientsByAccount = async (accountID) => {
    return await API.get(process.env.REACT_APP_API_BASE_URL + "/auth/patients/" + accountID);
}

const AuthService = {
    login,
    logout,
    getCurrentUser,
    register,
    findNewAccount,
    saveToken,
    saveProfile,
    getToken,
    forgotPassword,
    resetPassword,
    getMyProfile,
    updateMyAccount,
    getEmployers,
    uploadEmployeesFromCsv,
    getAccountById,
    getProviders,
    updateEmployeeAccount,
    getPatientsByAccount
}

export default AuthService;