import React, { Fragment, useEffect, useState } from "react";
import { format } from "date-fns";
//import { enUS } from "date-fns/locale";
import parseISO from "date-fns/parseISO";
import { Link } from "react-router-dom";
import CalendarService from "../../../services/calendar.service";
import { utcToZonedTime } from "date-fns-tz";
import { getTimezoneName } from "../../../constants/constants";
//import PropTypes from "prop-types";

const AppointmentList = ({ appointments }) => {
	const [calendars, setCalendars] = useState([]);
	useEffect(() => {
		CalendarService.getCalendars()
			.then((res) => {
				setCalendars(res.data);
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	return (
		<Fragment>

			<table className="table">
				<tbody>
					{appointments.map((appointment, index) => {
						let appointmentTimeZone = calendars.find((calendar) => calendar.id === appointment.location?.calendarID)?.timezone || "America/Chicago";

						return (
                            <tr key={appointment._id}>
                                <td>
                                    <Link to={`/appointment/detail/${appointment._id}`}>
                                        {appointment.location?.location}
                                    </Link>
                                </td>
                                <td>
                                    <Link to={`/appointment/detail/${appointment._id}`}>
								 {format(utcToZonedTime(appointment.datetime, appointmentTimeZone), "eeee MMM do yyyy h:mm b", )} {getTimezoneName(appointmentTimeZone)}
                                    </Link>
                                </td>
                                <td>
                                    <Link to={`/appointment/detail/${appointment._id}`}>
                                        {appointment.patient?.lastName}, {appointment.patient?.firstName}
                                    </Link>
                                </td>
                                <td>
                                    <Link to={`/appointment/detail/${appointment._id}`}>{appointment.type || ""}</Link>
                                </td>
                            </tr>
						);
					})}
				</tbody>
			</table>
		</Fragment>
	);
};

/*AppointmentList.defaultProps = {
	startDate: PropTypes.instanceOf(Date).isRequired,
	endDate: PropTypes.instanceOf(Date).isRequired,
	calendarID: PropTypes.number.isRequired,
};*/

export default AppointmentList;
