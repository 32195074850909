import "./App.css";
import { useState, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import LoginContainer from "./containers/LoginContainer";
import HubContainer from "./containers/HubContainer";
import Navigation from "./components/Navigation";
import Footer from "./components/Footer";
import AuthService from "./services/auth.service";

const App = () => {
	const [currentUser, setCurrentUser] = useState(null);

	useEffect(() => {
		let user = AuthService.getCurrentUser();		
		setCurrentUser(user);
	}, []);
	
	return (
		<Router>
			<main>
				<Navigation currentUser={currentUser} />
				<div className="container">
					<div className="row">
						<div className="col-12">
							{currentUser !== null ? <HubContainer currentUser={currentUser} /> : <LoginContainer />}
						</div>
					</div>
				</div>
			</main>

			<Footer />
		</Router>
	);
};

export default App;
