import API from "./API";

const getMyCards = async () => {
    return await API.get(process.env.REACT_APP_API_BASE_URL + "/payment/cards");
}

const addPaymentMethod = async (card) => {
    return await API.post(process.env.REACT_APP_API_BASE_URL + "/payment/add", card);
}

const findProductsByCategory = async (category) => {
    return await API.get(process.env.REACT_APP_API_BASE_URL + "/products/find/" + category);
}

const getProductPricing = async (accountId, productId) => {
    return await API.get(process.env.REACT_APP_API_BASE_URL + "/products/productID/" + accountId + "/" + productId);
}

const getAllProducts = async () => {
    return await API.get(process.env.REACT_APP_API_BASE_URL + "/products/all");
}


const PaymentService = {
    getMyCards,
    addPaymentMethod,
    findProductsByCategory,
    getProductPricing,
    getAllProducts
}

export default PaymentService;