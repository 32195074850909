import React, { useEffect, useState } from "react";
import AppointmentService from "../../../services/appointment.service";
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { Link } from "react-router-dom";
import BiometricsService from "../../../services/biometrics.service";
import * as CONSTANT from "../../../constants/constants";

const MyAppointments = ({ currentUser }) => {
	const [flashMessage, setFlashMessage] = useState("");
	const [flashMessageType, setFlashMessageType] = useState("");

	const [myAppointments, setMyAppointments] = useState([]);
	const [myResults, setMyResults] = useState([]);	

	useEffect(() => {
		// Service retrieves all appointments past and upcoming - processing, confirmed, not canceled
		AppointmentService.getMyAppointments().then((response) => {
			if (response.data) {				

				let currentAppointments = [];

				response.data.forEach(app => {
					if (app.modality === "on-demand") {
						currentAppointments.push(app);
					} else {
						// Do not show past appointments						
						if (utcToZonedTime(app.datetime, "America/Chicago") >= utcToZonedTime(new Date(), "America/Chicago")) {
							currentAppointments.push(app);
						} 
					}
				});

				setMyAppointments(currentAppointments);
			}			
		});		
	}, []);	

	useEffect(() => {
		//Get biometric results
		BiometricsService.getScreeningsFromAccountID(currentUser._id)
		.then((res) => {
			setMyResults(res.data);
		})
		.catch((error) => {
			const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

			setFlashMessage(resMessage);
			setFlashMessageType(CONSTANT.ALERT_TYPE.ERROR);
		});
	}, [currentUser._id]);

	return (
		<div id="my-appointments" className="shadow-sm p-3">
			<h5 className="my-3">Upcoming Appointments</h5>

			<table className="table">
				<tbody>
					{myAppointments.map((appt) => (
						<tr key={appt._id}>
							<td>{appt.modality === "on-demand" ? "Telehealth Request" : format(utcToZonedTime(appt?.datetime, "America/Chicago"), "EEEE, MMMM d, yyyy")}</td>
							<td>{appt.modality === "on-demand" ? "A provider should contact you within 48 hours. If this is a medical emergency, please call 911 or go to your nearest emergency room." : format(utcToZonedTime(appt?.datetime, "America/Chicago"), "h:mm bbbb")}</td>
							<td>{appt.modality === "on-demand" ? "" : appt.location.location}</td>
							<td>
								<Link to={`/appointment/detail/${appt._id}`} className="btn btn-outline-primary">
									View or Change
								</Link>
							</td>
						</tr>
					))}
				</tbody>
			</table>

			

			{myAppointments.length === 0 && <div className="alert alert-info">You have not booked any appointments.</div>}

			{myResults.length > 0 && <h5 className="my-3 mt-5">Biometrics Results</h5> }

			<table className="table">
				<tbody>
					{myResults.map((appt) => (
						<tr key={appt._id}>
							<td>{format(utcToZonedTime(appt.screeningDate, "America/Chicago"), "EEE, MMM d, yyyy")}</td>
							<td>{appt.location}</td>
							<td>
								<Link to={`/appointment/detail/${appt.appointment}`} className="btn btn-outline-primary">
									View Results
								</Link>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

MyAppointments.defaultProps = {};

export default MyAppointments;
