export default function validate(values) {
    let errors = {};

    if (!values.name) {
        errors.name = "Company name required.";
    }

    if (!values.companyCode) {
        errors.companyCode = "Employer code is required.";
    }

    return errors;
};