import React, { Fragment, useEffect, useState} from 'react';
import AuthService from '../../../services/auth.service';
import Loader from '../../UIHelpers/Loader';
//import AppointmentDateTime from '../DateTime';
import AppointmentProduct from '../Product';
import SelectEmployer from '../Proxy/SelectEmployer';
import * as CONSTANT from "../../../constants/constants";
import EmployerService from '../../../services/employer.service';
import PaymentService from '../../../services/payment.service';
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format, formatISO } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { Link } from "react-router-dom";
import AppointmentService from '../../../services/appointment.service';
import FlashMessage from '../../FlashMessage';


const AppointmentWalkin = ({currentUser}) => {
    const [flashMessage, setFlashMessage] = useState("");
	const [flashMessageType, setFlashMessageType] = useState("");
	const [loading, setLoading] = useState(false);

    const [selectedEmployer, setSelectedEmployer] = useState(null);
	//const [selectedEmployee, setSelectedEmployee] = useState(null);

	const [step, setStep] = useState(null);
	const [employers, setEmployers] = useState([]);
	const [employees, setEmployees] = useState([]);
	const [dependents, setDependents] = useState([]);
	const [products, setProducts] = useState([]);

    const [appointmentProcessing, setAppointmentProcessing] = useState(false);
	const [appointmentId, setAppointmentId] = useState(null);

    const [appointmentDetails, setAppointmentDetails] = useState({
        datetime: formatISO(new Date()),
        account: null,
        employer: null,
        location: {
            calendarID: null,
            location: null,
        },
        product: {
            price: 0,
        },
        alternative: "Unknown",        
        patient: {},
        receipt: {},
        modality: "on-site",
    });

    //load employers    
	useEffect(() => {		
		setLoading(true);

		AuthService.getEmployers()
			.then((employers) => {
				setEmployers(employers.data);
			})
			.catch((error) => {
				const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

				setFlashMessage(resMessage);
				setFlashMessageType(CONSTANT.ALERT_TYPE.ERROR);
			})
			.finally(() => {
				setLoading(false);
			});
	}, []);

    //Load employees from selected employer
	useEffect(() => {
		if (appointmentDetails.employer === null) return;

		setLoading(true);

		EmployerService.getEmployerRoster(appointmentDetails.employer)
			.then((employees) => {
				setEmployees(employees.data);
			})
			.catch((error) => {
				const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

				setFlashMessage(resMessage);
				setFlashMessageType(CONSTANT.ALERT_TYPE.ERROR);
			})
			.finally(() => {
				setLoading(false);
			});
	}, [appointmentDetails.employer]);

    //Load selected employee's dependents
	useEffect(() => {
		if (appointmentDetails.account === null) return;

		setLoading(true);

		AuthService.getPatientsByAccount(appointmentDetails.account)
			.then((patients) => {
				setDependents(patients.data);								
			})
			.catch((error) => {
				const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

				setFlashMessage(resMessage);
				setFlashMessageType(CONSTANT.ALERT_TYPE.ERROR);
			})
			.finally(() => {
				setLoading(false);
			});

	}, [appointmentDetails.account]);

	//Pre-select the employee as the patient
	useEffect(() => {		
		let patient = dependents.find((dependent) => { return dependent.account === appointmentDetails.account; });
		if (patient) {
			setAppointmentDetails((appointmentDetails) => ({
				...appointmentDetails,
				patient: patient._id,
				patientFirstName: patient.firstName,
				patientLastName: patient.lastName
			}));
		}
	}, [appointmentDetails.account, dependents]);

    //Load the products for on-site appointment type
	useEffect(() => {
		setLoading(true);

		PaymentService.findProductsByCategory(appointmentDetails.modality).then((products) => {
			setProducts(products.data);
		})		
		.catch((error) => {
			const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

			setFlashMessage(resMessage);
			setFlashMessageType(CONSTANT.ALERT_TYPE.ERROR);
		})
		.finally(() => {
			setLoading(false);
		});
	}, [appointmentDetails.modality]);

	//Get patient cost for selected service
	useEffect(() => {
		if (appointmentDetails.account && appointmentDetails.product?.productID) {
			PaymentService.getProductPricing(appointmentDetails.account, appointmentDetails.product?.productID).then(function (price) {
				setPatientCost(price.data.price);
			});
		}
	}, [appointmentDetails.account, appointmentDetails.product?.productID]);

    const setEmployer = (employerId) => {
        if (!employerId || employerId.length === 0) {
            setAppointmentDetails((appointmentDetails) => ({
                ...appointmentDetails,
                employer: null,
                account: null,
				accountEmail: null,
				accountMobile: null,
                patient: null,
				employerName: null,
				patientFirstName: null,
				patientLastName: null,
            }));
        } else {
            let employer = employers.find((employer) => { return employer._id === employerId; });
            if (employer) { setSelectedEmployer(employer); }

            setAppointmentDetails((appointmentDetails) => ({
                ...appointmentDetails,
                employer: employerId,
                account: null,
                patient: null,
				employerName: employer.name,
				patientFirstName: null,
				patientLastName: null,
            }));
        }
	};

    const setAccount = (employeeId) => {
		let employee = employees.find((employee) => { return employee._id === employeeId; });
		
		setAppointmentDetails((appointmentDetails) => ({
			...appointmentDetails,
			account: employeeId,
			accountEmail: employee.email ? employee.email : null,
			accountMobile: employee.mobile ? employee.mobile: null,
		}));
	};

    const setPatient = (patientId) => {
		let patient = dependents.find((dependent) => { return dependent._id === patientId; });

		setAppointmentDetails((appointmentDetails) => ({
			...appointmentDetails,
			patient: patientId,
			patientFirstName: patient.firstName,
			patientLastName: patient.lastName
		}));
	};

    const setPatientCost = (patientCost) => {
		setAppointmentDetails((appointmentDetails) => ({
			...appointmentDetails,
			product: {
				...appointmentDetails.product,
				price: patientCost,
			},
		}));
	};

    const setAppointmentProduct = (appointmentProduct) => {
		setAppointmentDetails((appointmentDetails) => ({
			...appointmentDetails,
			product: appointmentProduct,			
		}));
	};

	const setAppointmentLocation = (appointmentLocation) => {
		if (appointmentLocation === null) {
			setAppointmentDetails((appointmentDetails) => ({
				...appointmentDetails,
				location: {				
					location: null,
					calendarID: null
				}
			}));
		} else {
			setAppointmentDetails((appointmentDetails) => ({
				...appointmentDetails,
				location: {				
					location: appointmentLocation.location,
					calendarID: appointmentLocation.calendarID
				}
			}));
		}
	}

	/*
	const setAppointmentDateTime = (appointmentDateTime) => {        
		setAppointmentDetails((appointmentDetails) => ({
			...appointmentDetails,
			datetime: appointmentDateTime
		}));		
	};
	*/
	



    const handleConfirmPatient = () => {
		setStep("visit-reason");
	}

    const handleConfirmProduct = () => {
		setStep("visit-date-time");
		//setShowAppointmentProduct(false);
        //setShowDateTime(true);
    }

	const handleConfirmDateTime = () => {
		setStep("confirm");		
	}

    const handleScheduleAppointment = () => {
		//Schedule appointment when details are complete
		//User has pressed the "Confirm" button save appointment
        
		setAppointmentProcessing(true);
		//Does not write to acuity because they were never actually on the calendar
		AppointmentService.adminAddWalkin(appointmentDetails).then(function (response) {
			console.log(response);
			setAppointmentProcessing(false);
			setAppointmentId(response.data.id);
			setStep("confirmation");

		}).catch((error) => {
            console.log("caught error");
			const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

			setAppointmentProcessing(false);

			setFlashMessage(resMessage);
			setFlashMessageType(CONSTANT.ALERT_TYPE.ERROR);
		});
        
	}

    const handleCancelScheduleAppointment = () => {
		setStep(null);

		setAppointmentDetails({
			datetime: null,
			location: {
				calendarID: null,
				location: null,
			},
			product: {
				price: 0,
			},
			account: null,
			accountEmail: null,
			accountMobile: null,
			employer: null,
			type: null,
			modality: "on-site",		
			alternative: "Unknown",
			contact: CONSTANT.CONTACT.PHONE,
			patient: null,
			receipt: {},
			employerName: null,
			patientFirstName: null,
			patientLastName: null
		});
	}

    const renderStep = (step) => {
		switch (step) {
            
			case "visit-reason":
				return <AppointmentProduct 
                    appointmentProduct={appointmentDetails.product}
                    employer={selectedEmployer}
					currentUser={appointmentDetails.account}
                    products={products}
                    handleSetAppointmentProduct={setAppointmentProduct}
                    handleNextClick={handleConfirmProduct}
                />;

			case "visit-date-time":
                return <Fragment>
					{/*
					Removed select date and time for walk in appointments. 
					After feedback from providers, decided that date and 
					time are not important to know for walkins and it's more 
					important to be able to create the appointment quickly.
                    <h5>Walk-in date/time:</h5>
					<p><input type="datetime-local" style={{'backgroundColor': 'white', 'fontSize': '20px', 'padding': '10px', 'borderLeft': 0, 'borderRight': 0, 'borderTop': 0, 'marginBottom': '30px'}} onChange={(e) => setAppointmentDateTime(e.target.value)} /></p>
					*/}
                        
                    <h5>Select Walk-in Location:</h5>

                    <div className="list-group">
                        {selectedEmployer?.calendars?.filter(calendar => calendar.allowedAppointmentTypes?.includes(appointmentDetails.product.appointmentTypeID)).map((calendar) => (
                            <Fragment key={calendar.calendarID}>
                                {/* If the user has access to all clinic locations, display the calendar, otherwise determine if the user has access to the calendar */}
                                {(currentUser?.authorizedLocations?.all  || currentUser?.authorizedLocations?.calendars.includes(calendar.calendarID))  && (
                                    <button
                                        key={calendar.location}
                                        className="list-group-item list-group-item-action"
                                        onClick={() => setAppointmentLocation(calendar)}>
                                        <span className="card-title">{calendar.location}</span>
                                    </button>
                                )}
                            </Fragment>
                        ))}
                    </div>
                        
                    
                    
                    <p className="py-3">
                        <button className="btn btn-primary btn-block" onClick={handleConfirmDateTime} disabled={appointmentDetails.datetime === "" || appointmentDetails.location.calendarID === null}>
                            NEXT &raquo;
                        </button>
                    </p>
                </Fragment>

			case "confirm":
				return <ConfirmAppointmentDetails
					appointment={appointmentDetails}
					handleScheduleAppointment={handleScheduleAppointment}
					handleCancelScheduleAppointment={handleCancelScheduleAppointment}
					appointmentProcessing={appointmentProcessing} />;

			case "confirmation":
				return <Confirmation 
					appointmentId={appointmentId}
				/>;
            
			default:
				return <SelectEmployer
				currentUser={currentUser}
                employers={employers}
                employees={employees}
                dependents={dependents}
                handleSetEmployer={setEmployer}
                handleSetAccount={setAccount}
                handleSetPatient={setPatient}
				handleNextClick={handleConfirmPatient}
                selectedEmployer={appointmentDetails.employer}
                selectedEmployee={appointmentDetails.account}
				selectedPatient={appointmentDetails.patient}
            />;
		}
	}


    return (
        <Fragment>                    
            <Loader show={loading} />

            {flashMessage.length > 0 &&
                <FlashMessage message={flashMessage} messageType={flashMessageType} />
            }

            {renderStep(step)}
        </Fragment>
    );
}

AppointmentWalkin.defaultProps = {}

export default AppointmentWalkin;

const ConfirmAppointmentDetails = ({ appointment, handleScheduleAppointment, handleCancelScheduleAppointment, appointmentProcessing }) => {
	const timeZone = "America/Chicago";

	return (
		<div className="card">
			<div className="card-body">
				<h5 className="card-title">Confirm Appointment Details</h5>
				<p className="card-text">
					Employer: {appointment.employerName}<br/>
					Patient: {appointment.patientFirstName}{" "}{appointment.patientLastname}<br/>
					Reason for Visit: {appointment.product?.name}<br/>
					Patient Cost: ${appointment.product?.price}<br/>
					Appointment Date/Time: {format(utcToZonedTime(appointment.datetime, timeZone), "EEEE, LLL dd, yyyy h:mm aaaa")}
				</p>
				{appointmentProcessing && 
					<p><FontAwesomeIcon icon={faCircleNotch} spin /> <em>Scheduling Appointment...</em></p>
				}
				<p>
					<button onClick={handleCancelScheduleAppointment} className="btn btn-outline-secondary mr-3">Cancel</button>
					<button onClick={handleScheduleAppointment} className="btn btn-primary">Schedule Appointment</button>
				</p>
			</div>
		</div>
	);
};

const Confirmation = ({appointmentId}) => {
	return (
		<div className="card">
			<div className="card-body">
				<h5 className="card-title">Appointment Scheduled</h5>
				<Link to={`/appointment/detail/${appointmentId}`}>Go to Appointment</Link> | 
				<Link to="/clinic/appointments">Return to Schedule Overview</Link>
			</div>
		</div>
	)
}