export default function validate(values) {
    let errors = {};

    if (!values.emailAddress) {
        errors.emailAddress = "Please enter email address.";
    }

    if (!values.password) {
        errors.password = "Please enter password.";
    }

    return errors;
};