import React, { Fragment, useState, useEffect } from "react";

const Modality = ({ appointmentModality, employer, handleSetAppointmentModality, handleNextClick }) => {
	const [selectedModality, setSelectedModality] = useState(null);

	useEffect(() => {
		if (appointmentModality) {
			setSelectedModality(appointmentModality);
		}
	}, [appointmentModality]);

	return (
		<Fragment>

			<div id="appointment-modality">
				<h1 className="my-3">Appointment Type</h1>
				
				{employer &&
					<div className="list-group">
						<button
							onClick={(e) => handleSetAppointmentModality("on-site")}
							className={`shadow-sm list-group-item list-group-item-action ${appointmentModality === "on-site" && "active"}`}>
							<span className="card-title">Onsite Clinic</span>
							<br />
							<span className="card-text">Get care in person at your employer's location.</span>
						</button>
					</div>
				}

				{employer?.permissions?.includes("telemedicine") && (
					<div className="list-group">
						<button
							onClick={(e) => handleSetAppointmentModality("on-demand")}
							className={`shadow-sm list-group-item list-group-item-action ${appointmentModality === "on-demand" && "active"}`}>
							<span className="card-title">Telemedicine</span>
							<br />
							<span className="card-text">Get care from a provider over the phone.</span>
						</button>
					</div>
				)}

				<p className="mt-5">
					<button className="btn btn-primary btn-block" onClick={handleNextClick} disabled={!selectedModality}>
						Next &raquo;
					</button>
				</p>
			</div>
		</Fragment>
	);
};

Modality.defaultProps = {};

export default Modality;
