import React, { Fragment, useEffect, useState } from "react";
import FlashMessage from "../../FlashMessage";
import * as CONSTANT from "../../../constants/constants";
import useForm from "../../useForm";
import validate from "./EmployerValidationRules";
import EmployerService from "../../../services/employer.service";
import PaymentService from "../../../services/payment.service";
import CheckboxGroup from "react-checkbox-group";
import { Link, useParams } from "react-router-dom";
import AddressForm from "../../Account/ContactInformation/AddressForm";
import { nanoid } from "nanoid";
import { useHistory } from "react-router-dom";
import ReturnHome from "../../Navigation/ReturnHome";
import Loader from "../../UIHelpers/Loader";

const EmployerDetail = ({currentUser}) => {
	const params = useParams();
	const [selectedPermissions, setSelectedPermissions] = useState([]);
	const [flashMessage, setFlashMessage] = useState("");
	const [flashMessageType, setFlashMessageType] = useState("");
	const [employees, setEmployees] = useState([]);

	const [permissions, setPermissions] = useState([]);
	const [loading, setLoading] = useState(false);

	const history = useHistory();

	useEffect(() => {
		PaymentService.getAllProducts().then((response) => {
			setPermissions(response.data);
		});
	}, []);

	const saveEmployer = () => {
		setLoading(true);
		
		//if !params.enployerId then create
		let employer = {
			_id: params.employerId || null,
			active: values.active,
			name: values.name,
			companyCode: values.companyCode,
			mailingAddress: values.mailingAddress,
			permissions: selectedPermissions,
			pricingTier: values.pricingTier,
		};

		if (params.employerId) {
			EmployerService.updateEmployer(employer)
				.then((response) => {
					setFlashMessage("Employer Updated");
					setFlashMessageType(CONSTANT.ALERT_TYPE.SUCCESS);
				})
				.catch((error) => {
					const resMessage =
						(error.response && error.response.data && error.response.data.message) || error.message || error.toString();

					setFlashMessage(resMessage);
					setFlashMessageType(CONSTANT.ALERT_TYPE.ERROR);
				})
				.finally(() => setLoading(false));
		} else {
			EmployerService.addEmployer(employer)
				.then((response) => {
					console.log(response);

					history.push({
						pathname: "/employer/detail/" + response.data._id,
					});
				})
				.catch((error) => {
					const resMessage =
						(error.response && error.response.data && error.response.data.message) || error.message || error.toString();

					setFlashMessage(resMessage);
					setFlashMessageType(CONSTANT.ALERT_TYPE.ERROR);
				})
				.finally(() => setLoading(false));
		}
	};
	const { setValues, values, errors, handleChange, handleSubmit } = useForm(saveEmployer, validate);

	useEffect(() => {
		/* Load Employer if employerId is defined, otherwise skip */
		if (!params.employerId) return;

		setLoading(true);

		const getEmployer = EmployerService.getEmployer(params.employerId);
		const getEmployerRoster = EmployerService.getEmployerRoster(params.employerId);

		Promise.all([
			getEmployer.catch((error) => {
				const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

				setFlashMessage(resMessage);
				setFlashMessageType(CONSTANT.ALERT_TYPE.ERROR);
			}),
			getEmployerRoster.catch((error) => {
				const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

				setFlashMessage(resMessage);
				setFlashMessageType(CONSTANT.ALERT_TYPE.ERROR);
			}),
		]).then(([resEmployer, resEmployees]) => {	
			setValues(resEmployer.data);
			setSelectedPermissions(resEmployer.data.permissions);
			setEmployees(resEmployees.data);
		})
		.finally(() => {
			setLoading(false);
		});
	}, [params.employerId, setValues]);

	return (
		<Fragment>
			<ReturnHome>
				<li className="breadcrumb-item"><Link to="/employer/list">Employers</Link></li>
				<li className="breadcrumb-item active">Employer Detail</li>
			</ReturnHome>

			<h1>{params.employerId ? "Employer Detail" : "New Employer"}</h1>

			{flashMessage.length > 0 && <FlashMessage message={flashMessage} messageType={flashMessageType} />}

			<Loader show={loading} />

			{!loading && (
				<div className="row">
					<div className="col-6">
						<div className="custom-control custom-switch">
							<input
								type="checkbox"
								id="is-active-employer"
								className="custom-control-input"
								checked={values.active || false}
								value={true}
								onChange={(event) => setValues({ ...values, active: !values.active })}
							/>{" "}
							<label className="custom-control-label" htmlFor="is-active-employer">Active</label>
						</div>

						<div className="form-group">
							<label>
								Company Name <span className="text-danger">*</span>
							</label>
							<input
								type="text"
								className={`form-control ${errors.name && "is-invalid"}`}
								name="name"
								value={values.name || ""}
								onChange={handleChange}
							/>
							<div className="invalid-feedback">{errors.name}</div>
						</div>

						<div className="form-group">
							<label>
								Employer Code <span className="text-danger">*</span>
							</label>
							<input
								type="text"
								className={`form-control ${errors.companyCode && "is-invalid"}`}
								name="companyCode"
								value={values.companyCode || ""}
								onChange={handleChange}
							/>
							<div className="invalid-feedback">{errors.companyCode}</div>
						</div>

						<AddressForm handleChange={handleChange} values={values} errors={errors} />

						<div className="form-group">
							<label>Permissions</label>
							<CheckboxGroup name="permissions" value={selectedPermissions} onChange={setSelectedPermissions}>
								{(Checkbox) => (
									<Fragment key={nanoid()}>
										<div className="form-check">
											<Checkbox className="form-check-input" value="dependents" />
											<label className="form-check-label">Allow Dependents</label>
										</div>

										<div className="form-check">
											<Checkbox className="form-check-input" value="telemedicine" />
											<label className="form-check-label">
												Telemedicine (Required to enable on-demand services)
											</label>
										</div>

										{permissions.map((permission) => (
											<div className="form-check" key={permission.productID}>
												<Checkbox className="form-check-input" value={permission.productID} />
												<label className="form-check-label">
													<strong className="text-uppercase">{permission.category}</strong> {permission.name}{" "}({permission.appointmentTypeID})
												</label>
											</div>
										))}
									</Fragment>
								)}
							</CheckboxGroup>
						</div>

						<div className="form-group">
							<label>Pricing Tier</label>
							<select
								className="form-control"
								value={values.pricingTier}
								onChange={(event) => setValues({ ...values, pricingTier: event.target.value })}>
								<option value="normal">Normal</option>
								<option value="0000">0000</option>
								<option value="0001">0001 (All services free to employees)</option>
								<option value="0002">0002</option>
								<option value="0003">0003</option>
								<option value="0004">0004</option>
								<option value="0005">0005</option>
							</select>
						</div>

						<p>
							<button className="btn btn-primary" onClick={handleSubmit}>
								Save
							</button>
						</p>
					</div>
					{params.employerId && (
						<div className="col-6">
							<div className="card mb-3">
								<div className="card-body">
									<p>
										Employees: {values.employeeCount} active
										<Link to={`/employee/list/${params.employerId}`} className="btn btn-outline-primary float-right">
											Manage
										</Link>
									</p>
								</div>
							</div>

							<div className="card mb-3">
								<div className="card-body">
									<p>
										Send Mass SMS
										<Link to={`/employer/sms/${params.employerId}`} className="btn btn-outline-primary float-right">
											Compose
										</Link>
									</p>
								</div>
							</div>

							<div className="card mb-3">
								<div className="card-body">
									<p>
										Utilization Report
										<Link
											to={`/employer/report/list/${params.employerId}`}
											className="btn btn-outline-primary float-right">
											View
										</Link>
									</p>
								</div>
							</div>

							{/* Only for Lee */}
							{params.employerId === "6033e63772c607d7e91daf91" &&
								<div className="card mb-3">
									<div className="card-body">
										<p>
											Biometrics XML Report
											<a target="_blank" rel="noreferrer"
												className="btn btn-outline-primary float-right" 
												href={`${process.env.REACT_APP_API_BASE_URL}/biometrics/employer/${params.employerId}`}>
												Download																							
											</a>
										</p>
									</div>
								</div>
							}

							<div className="card mb-3">
								<div className="card-body">
									<Link to={`/employee/list/${params.employerId}`} className="btn btn-outline-primary float-right mb-3">
										Select New
									</Link>
									<EmployerAdminList employerId={params.employerId} roster={employees} />
								</div>
							</div>

							<div className="card mb-3">
								<div className="card-body">
									<Link to={`/clinic/detail/${params.employerId}`} className="btn btn-outline-primary float-right">
										Create New
									</Link>
									<ClinicList employerId={params.employerId} clinics={values.calendars} />
								</div>
							</div>

							<div className="card">
								<div className="card-body">
									<strong>Telehealth Note:</strong> Telehealth groups do not need to be assigned manually. Employers who
									have telemedicine enabled allow their employees to create on demand requests. They will select a state
									during the request and any provider licensed in the selected state may pick up the request.
								</div>
							</div>
						</div>
					)}
				</div>
			)}
		</Fragment>
	);
};

const EmployerAdminList = ({ employerId, roster }) => {
	let employees = roster || [];
	let employerAdmins = employees.filter((employee) => employee.role === "employer");

	return (
		<Fragment>
			
			Employer Admins: {employerAdmins?.length}
			<table className="table table-sm table-hover mt-4">
				<tbody>
					{employerAdmins?.map((admin) => (
						<tr key={admin._id}>
							<td>
								<Link to={`/account/detail/${employerId}/${admin._id}`} key={admin._id}>
									{admin.firstName} {admin.lastName}
								</Link>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</Fragment>
	);
};

const ClinicList = ({ clinics, employerId }) => {
	return (
		<Fragment>
			Clinics: {clinics?.length}
			<table className="table table-sm table-hover mt-4">
				<tbody>
					{clinics?.map((clinic) => (
						<tr key={clinic._id}>
							<td>
								<Link to={`/clinic/detail/${employerId}/${clinic.calendarID}`} key={clinic.calendarID}>
									{clinic.location}
								</Link> {!clinic.active && <small>(Inactive)</small>}
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</Fragment>
	);
};

EmployerDetail.defaultProps = {};

export default EmployerDetail;
