import React, { Fragment, useEffect, useState } from 'react';
import AppointmentService from '../../../../services/appointment.service';
import * as CONSTANT from "../../../../constants/constants";
import FlashMessage from '../../../FlashMessage';
import { faPlusCircle, faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalHelper from '../../../UIHelpers/Modal';

/* Allow provider to block time on their calendar to prevent appointments being scheduled during that time */

const ClinicBlockSchedule = ({ currentUser }) => {

    const [flashMessage, setFlashMessage] = useState("");
	const [flashMessageType, setFlashMessageType] = useState("");

    const [blocks, setBlocks] = useState([]);
    const [calendars, setCalendars] = useState([]);
    const [showModal, setShowModal] = useState(false);

    const [block, setBlock] = useState({
        blockDate: null,
        startTime: null,
        endTime: null,
        calendar: null
    });


    const [saving, setSaving] = useState(false);

    useEffect(() => {
        AppointmentService.getClinicBlocks().then((res) => {
            //reverse the received array so it's in ascending order
            setBlocks(res.data?.reverse());
        })
        .catch((error) => {
            const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

            setFlashMessage(resMessage);
            setFlashMessageType(CONSTANT.ALERT_TYPE.ERROR);
        });

        AppointmentService.getAllLocations().then((res) => {
            let calendars = [];
            res.data.forEach(clinic => {
                clinic.calendars.forEach(calendar => {
                    if (calendar.active === true) {
                        calendars.push(calendar);
                    }
                });
            });

            setCalendars(calendars);
        })
        .catch((error) => {
            const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

            setFlashMessage(resMessage);
            setFlashMessageType(CONSTANT.ALERT_TYPE.ERROR);
        });
    }, []);

    const addScheduleBlock = (calendar) => {
        setShowModal(true);
        setBlock({...block, calendar: calendar});
    }

    const cancelSaveBlock = () => {
        //reset block values
        setBlock({...block, 
            blockDate: null,
            startTime: null,
            endTime: null,
            calendar: null
        });

        setShowModal(false);
    }

    const saveScheduleBlock = () => {
        setShowModal(false);
        setSaving(true);

        AppointmentService.saveClinicBlock(block)
			.then((res) => {
				console.log(res);

                blocks.push({
                    "id": res.data.id,
                    "calendarID": res.data.calendarID,
                    "start": res.data.start,
                    "end": res.data.end,
                    "description": res.data.description
                });

                setBlock({...block, 
                    blockDate: null,
                    startTime: null,
                    endTime: null,
                    calendar: null
                });
                
			})
			.catch((error) => {
				const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

				setFlashMessage(resMessage);
				setFlashMessageType(CONSTANT.ALERT_TYPE.ERROR);
			})
            .finally(() => {
                setSaving(false);
            });
    }

    return (
		<div>
			<h1>Manage Clinic Schedule</h1>

			{flashMessage.length > 0 && <FlashMessage message={flashMessage} messageType={flashMessageType} />}

			<p>Schedule blocks will prevent creating appointments during the blocked time.</p>

			<h4 className="mt-5">Upcoming Blocks</h4>

			{calendars.map((calendar) => {
				const clinicBlocks = blocks.filter((block) => block.calendarID === calendar.calendarID);
				return (
					<Fragment key={calendar._id}>
						{calendar.providers.includes(currentUser._id) && (
							<Fragment>
								<h5 className="pt-3">
									{calendar.location} <small>({calendar.calendarID})</small>
									<button className="btn btn-sm btn-link" onClick={() => addScheduleBlock(calendar)}>
										<FontAwesomeIcon icon={faPlusCircle} /> Add Schedule Block
									</button>
								</h5>
								{(!clinicBlocks || (clinicBlocks && clinicBlocks.length < 1)) && <p>No scheduled blocks.</p>}

								{clinicBlocks &&
									clinicBlocks.length > 0 &&
									clinicBlocks.map((block) => <p key={block.id}>{block.description}</p>)}
							</Fragment>
						)}
					</Fragment>
				);
			})}

			<ModalHelper show={showModal}>
				<h5 className="mb-5">
					Add Schedule Block for {block?.calendar?.location} <small>({block?.calendar?.calendarID})</small>
				</h5>
				
					<div className="form-group row">
						<label className="col-form-label col-sm-3">Date:</label>
                        <div className="col-sm-9">
                            <input
                                type="date"
                                className="form-control"
                                onChange={(e) => setBlock({ ...block, blockDate: e.target.value })}
                                style={{ backgroundColor: "#ffffff" }}
                            />
                        </div>
					</div>
					<div className="form-group row">
						<label className="col-form-label col-sm-3">Start time:</label>
                        <div className="col-sm-9">
                            <input
                                type="time"
                                className="form-control"
                                onChange={(e) => setBlock({ ...block, startTime: e.target.value })}
                                style={{ backgroundColor: "#ffffff" }}
                            />
                        </div>
					</div>
					<div className="form-group row">
						<label className="col-form-label col-sm-3">End time:</label>
                        <div className="col-sm-9">
                            <input
                                type="time"
                                className="form-control"
                                onChange={(e) => setBlock({ ...block, endTime: e.target.value })}
                                style={{ backgroundColor: "#ffffff" }}
                            />
                        </div>
					</div>
				

				<div className="row mt-5">
					<div className="col-6">
                        <button className="btn btn-secondary" onClick={cancelSaveBlock}>
							Cancel
						</button>
					</div>
					<div className="col-6 text-right">
						<button className="btn btn-primary" onClick={saveScheduleBlock}>
							Save
						</button>
					</div>
				</div>
			</ModalHelper>

			<ModalHelper show={saving}>
				<p>
					<FontAwesomeIcon icon={faCircleNotch} spin /> <em>Saving...</em>
				</p>
			</ModalHelper>
		</div>
    );
}

ClinicBlockSchedule.defaultProps = {}

export default ClinicBlockSchedule;