import React, { Fragment, useState, useEffect } from "react";
import AuthService from "../../../services/auth.service";
import { Link } from "react-router-dom";
import Loader from "../../UIHelpers/Loader";
import ReturnHome from "../../Navigation/ReturnHome";

const EmployerList = () => {
	const [employers, setEmployers] = useState([]);
	const [filteredEmployers, setFilteredEmployers] = useState([]);
	const [isActive, setIsActive] = useState("active");
	const [filterText, setFilterText] = useState("");
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setLoading(true);
		AuthService.getEmployers()
		.then((result) => {
			setEmployers(result.data);
		}).finally(() => setLoading(false));
	}, []);

	useEffect(() => {
		if (filterText.length === 0) {
			setFilteredEmployers(employers);
		} else {
			let filter = filterText.toLowerCase();
			let filteredEmployers = employers.filter((employer) => employer.name.toLowerCase().indexOf(filter) > -1);

			setFilteredEmployers(filteredEmployers);
		}
	}, [employers, filterText]);

	return (
		<Fragment>
			<ReturnHome>
				<li className="breadcrumb-item active">Employers</li>
			</ReturnHome>
			
			<h1>Employers</h1>

			<Loader show={loading} />

			{!loading && employers.length === 0 && 
				<div className="alert alert-info">No employers found.</div>
			}

			{employers.length > 0 && (
				<Fragment>
					<div className="row mb-5">
						<div className="col">
							<input type="text" placeholder="Search" className="form-control" value={filterText} onChange={(e) => setFilterText(e.target.value)} />
						</div>
						<div className="col">
							<select className="form-control" value={isActive} onChange={(event) => setIsActive(event.target.value)}>
								<option value="active">Active</option>
								<option value="inactive">Inactive</option>
							</select>
						</div>
						<div className="col text-right">
							<Link to={`/employer/detail`} className="btn btn-primary">
								Add New
							</Link>
						</div>
					</div>

					<div className="row">
						<div className="col">
							<div className="list-group">
								
								{filteredEmployers
									.filter((employer) => (employer.active === (isActive === "active") ? true : false))
									.map((employer) => (
										<Link
											to={`/employer/detail/${employer._id}`}
											className="list-group-item list-group-item-action"
											key={employer._id}>
											{employer.name}
										</Link>
									))}
									
							</div>
						</div>
					</div>
				</Fragment>
			)}
		</Fragment>
	);
};

EmployerList.defaultProps = {};

export default EmployerList;
