export default function validate(values) {
    
    let errors = {};

    if (!values.cardNumber) {
        errors.cardNumber = "Required";
    } else if(values.cardNumber.length !== 16) {
        errors.cardNumber = "Valid card information is required."
    }


    if (!values.cvv) {
        errors.cvv = "Required";
    } else if (values.cvv.length !== 3) {
        errors.cvv = "CVV must be 3 digits.";
    }

    if (!values.expiration) {
        errors.expiration = "Required";
    }

    return errors;
};