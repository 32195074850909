import API from "./API";
import serviceCodeList from "../constants/serviceCodes.json";

var AppointmentService = {};

const saveSessionAppointment = (appointment) => {
    //don't accidentally save an empty object
    if (appointment && Object.keys(appointment).length > 0 && appointment.constructor === Object) {        
        sessionStorage.setItem("archer-appointment", JSON.stringify(appointment));
    }
}

const getSessionAppointment = () => {
    let appointment = JSON.parse(sessionStorage.getItem("archer-appointment"));

    return appointment;
}

const clearSessionAppointment = () => {
    sessionStorage.removeItem("archer-appointment");
}

const serviceCodes = () => {
    try {
        return serviceCodeList;
    } catch (error) {
        console.error(error);
    }
}

const symptoms = () => {
    return ({
    gastro: [
        {name: "Abdominal pain", category: "gastro"},
        {name: "Blood in stool", category: "gastro"},
        {name: "Constipation", category: "gastro"},
        {name: "Nausea or vomiting", category: "gastro"},
        {name: "Diarrhea", category: "gastro"}
    ],
    head: [{name: "Blurred vision", category: "head"},
        {name: "Vision problems", category: "head"},
        {name: "Eye discomfort/redness", category: "head"},
        {name: "Difficulty swallowing", category: "head"},
        {name: "Nasal congestion", category: "head"},
        {name: "Dizziness or fainting", category: "head"},
        {name: "Ear ache", category: "head"},
        {name: "Neck pain", category: "head"},
        {name: "Head aches", category: "head"},
        {name: "Numbness or tingling", category: "head"}],
    chest: [{name: "Chest pressure or pain", category: "chest"},
        {name: "Cough", category: "chest"},
        {name: "Shortness of breath", category: "chest"},
        {name: "Sore throat", category: "chest"},
        {name: "Palpitations", category: "chest"}],
    general: [{name: "Fatigue", category: "general"},
        {name: "Fever", category: "general"},
        {name: "Difficulty sleeping", category: "general"},
        {name: "Loss of appetite", category: "general"},
        {name: "Pregnant", category: "general"},
        {name: "Foreign travel (past month)", category: "general"},
        {name: "Unexplained weight loss/gain", category: "general"}],
    skin: [{name: "Rash", category: "skin"},
        {name: "Hives", category: "skin"},
        {name: "Bites", category: "skin"},
        {name: "Bleeding", category: "skin"},
        {name: "Bruising", category: "skin"}],
    urinary: [{name: "Pelvic pain", category: "urinary"},
        {name: "Blood in urine", category: "urinary"},
        {name: "Discomfort with urination", category: "urinary"},
        {name: "Vaginal discharge", category: "urinary"},
        {name: "Vaginal bleeding", category: "urinary"},
        {name: "Penile discharge", category: "urinary"},
        {name: "Testicular pain or swelling", category: "urinary"},
        {name: "Numbness or tingling", category: "urinary"}],
    muscular: [{name: "Foot/leg swelling", category: "muscular"},
        {name: "Shoulder pain", category: "muscular"},
        {name: "Muscle pain or weakness", category: "muscular"},
        {name: "Back pain", category: "muscular"}]
    });
};

const alternatives= () => {
    return [
        "The emergency room",
        "An  urgent care or walk-in clinic",
        "My primary care doctor",
        "A specialist",
        "Nowhere"
    ]
}

const biometric_screening_types = () => {
    return [
        "onsite_special_biometrics_alabama_bluegrass", 
        "onsite_special_biometrics", 
        "onsite_thrive_fair",
        "onsite_biometric_screening"
    ]
}

const conditions = () => {
    return [
    {name: "Allergies"},
    {name: "Alzheimer's"},
    {name: "Anemia"},
    {name: "Breast cancer"},
    {name: "Dementia"},
    {name: "Alcohol/drug addiction"},
    {name: "Anxiety disorders"},
    {name: "Arthritis"},
    {name: "Asthma"},
    {name: "Colon cancer"},
    {name: "Depression"},
    {name: "Diabetes"},
    {name: "Digestive disorders"},
    {name: "Eating disorders"},
    {name: "Gout"},
    {name: "Heart attack"},
    {name: "Heart disease"},
    {name: "High blood pressure"},
    {name: "High cholesterol"},
    {name: "HIV/AIDS"},
    {name: "Kidney disease"},
    {name: "Lung diseases"},
    {name: "Osteoporosis"},
    {name: "Parkinson's Disease"},
    {name: "Prostate cancer"},
    {name: "Seizures"},
    {name: "Skin cancer"},
    {name: "Stroke"},
    {name: "Thyroid disorders"}
];
};

const familyConditions = () => {
    return [
    {name: "Anxiety"},
    {name: "Alcohol or drug abuse"},
    {name: "Alzheimer's / dementia"},
    {name: "Asthma / allergies"},
    {name: "Bipolar disorder"},
    {name: "Bleeding disorder"},
    {name: "Blood clots"},
    {name: "Brain aneurysm"},
    {name: "Cancer"},
    {name: "Dementia"},    
    {name: "Depression"},
    {name: "Diabetes"},
    {name: "Genetic disorders"},
    {name: "Heart disease"},
    {name: "High cholesterol or triglycerides"},
    {name: "Hypertension / High blood pressure"},
    {name: "Kidney disease"},
    {name: "Lung disease"},
    {name: "Osteoporosis"},
    {name: "Schizophrenia"},
    {name: "Stroke / TIA"},
    {name: "Sudden death"}
];
}

const allergies = () => {
    return [
    {name: "Acetone", category: "enviro"},
    {name: "Amoxicillin", category: "drug"},
    {name: "Amoxil", category: "drug"},
    {name: "Ampicillin", category: "drug"},
    {name: "Aspirin", category: "drug"},
    {name: "Bactrim", category: "drug"},
    {name: "Bees/Wasps", category: "enviro"},
    {name: "Ceftin", category: "drug"},
    {name: "Cefzil", category: "drug"},
    {name: "Cephalexin", category: "drug"},
    {name: "Codeine", category: "drug"},
    {name: "Dairy", category: "food"},
    {name: "Doxycycline", category: "drug"},
    {name: "Dust Mites", category: "enviro"},
    {name: "Eggs", category: "food"},
    {name: "Erythromycin", category: "drug"},
    {name: "Grass", category: "enviro"},
    {name: "Ibuprofen", category: "drug"},
    {name: "Insulin", category: "drug"},
    {name: "Iodine", category: "enviro"},
    {name: "Keflex", category: "drug"},
    {name: "Ketorolac", category: "drug"},
    {name: "Latex", category: "enviro"},
    {name: "Lidocaine", category: "drug"},
    {name: "Lisinopril", category: "drug"},
    {name: "Mold", category: "enviro"},
    {name: "Morphine", category: "drug"},
    {name: "Naproxen", category: "drug"},
    {name: "Oxycodone", category: "drug"},
    {name: "Oxytocin", category: "drug"},
    {name: "Peanuts", category: "food"},
    {name: "Pediazole", category: "drug"},
    {name: "Penicillin", category: "drug"},
    {name: "Pet Dander (Dog/Cats)", category: "enviro"},
    {name: "Pollen", category: "enviro"},
    {name: "Prochlorperazine", category: "drug"},
    {name: "Septra", category: "drug"},
    {name: "Shellfish", category: "food"},
    {name: "Sulfa", category: "drug"},
    {name: "Tetracycline", category: "drug"},
    {name: "Tramadol", category: "drug"},
    {name: "Vantin", category: "drug"},
    {name: "Wheat", category: "food"},
];
}

const getAvailableDates = async() => {
    //pulls from JWT to get employer ID and find the assigned calendars
    return await API.get(process.env.REACT_APP_API_BASE_URL + "/appointments/dates");
}

const getAvailableDatesByCalendar = async(calendarID, employerID) => {
    //pulls from JWT to get employer ID and find the assigned calendars
    return await API.get(process.env.REACT_APP_API_BASE_URL + "/appointments/dates/" + calendarID + "/" + employerID);
}

const getAvailableDatesByCalendarAndAppointmentTypeId = async(calendarID, employerID, appointmentTypeID) => {
    //pulls from JWT to get employer ID and find the assigned calendars
    return await API.get(process.env.REACT_APP_API_BASE_URL + "/appointments/dates/" + calendarID + "/" + employerID + "/" + appointmentTypeID);
}

const getAvailableTimes = async(calendarID, date) => {
    return await API.get(process.env.REACT_APP_API_BASE_URL + "/appointments/times/" + calendarID + '/' + date)
}

const getAvailableTimesByAppointmentTypeId = async(calendarID, date, appointmentTypeID) => {
    return await API.get(process.env.REACT_APP_API_BASE_URL + "/appointments/times/" + calendarID + "/" + date + "/" + appointmentTypeID);
}

const createRequest = async(appt) => {
    return await API.post(process.env.REACT_APP_API_BASE_URL + "/appointments/request", appt);
}

/*
const acceptRequest = async(appt) => {
    return await API.post(process.env.REACT_APP_API_BASE_URL + "/telehealthgroups/accept", appt);
}
*/

const startRequest = async(appointmentId) => {
    return await API.post(process.env.REACT_APP_API_BASE_URL + "/telehealthgroups/start/" + appointmentId);
}

/*
const completeRequest = async(appointmentId) => {
    return await API.post(process.env.REACT_APP_API_BASE_URL + "/telehealthgroups/complete/" + appointmentId);
}
*/
const completeRequest = async(appointment) => {
    return await API.post(process.env.REACT_APP_API_BASE_URL + "/appointments/complete", appointment);
}

const getAppointments = async(fromDate, toDate, calendarID) => {
    return await API.get(process.env.REACT_APP_API_BASE_URL + "/appointments/get/" + fromDate + "/" + toDate + "/" + calendarID);
}

const getRequests = async() => {
    return await API.get(process.env.REACT_APP_API_BASE_URL + "/telehealthgroups/requests");
}

const getAppointmentById = async(appointmentId) => {
    return await API.get(process.env.REACT_APP_API_BASE_URL + "/appointments/ID/" + appointmentId);
}

const getMyAppointments = async() => {
    return await API.get(process.env.REACT_APP_API_BASE_URL + "/appointments/me");
}

const addNotes = async(notes) => {
    return await API.post(process.env.REACT_APP_API_BASE_URL + "/appointments/notes/add", notes);
}

const getNotes = async(patientID) => {
    return await API.get(process.env.REACT_APP_API_BASE_URL + "/appointments/notes/" + patientID);
}

const cancelAppointment = async(appointment) => {
    return await API.post(process.env.REACT_APP_API_BASE_URL + "/appointments/cancel", appointment)
}



const rescheduleAppointment = async(appointment) => {
    return await API.post(process.env.REACT_APP_API_BASE_URL + "/appointments/reschedule", appointment);
}

const adminScheduleAppointment = async(appointment) => {
    return await API.post(process.env.REACT_APP_API_BASE_URL + "/appointments/admin", appointment);
}

const scheduleAppointment = async(appt) => {
    return await API.post(process.env.REACT_APP_API_BASE_URL + "/appointments/schedule", appt);
}



const getAllLocations = async() => {
    return await API.get(process.env.REACT_APP_API_BASE_URL + "/appointments/locations");
}

const getLocations = async(employerId) => {
    return await API.get(process.env.REACT_APP_API_BASE_URL + "/appointments/locations/" + employerId);
}

const getAll = async() => {
    return await API.get(process.env.REACT_APP_API_BASE_URL + "/appointments/all");
}

const getClinicBlocks = async() => {
    return await API.get(process.env.REACT_APP_API_BASE_URL + "/appointments/block");
}

const saveClinicBlock = async(block) => {
    return await API.post(process.env.REACT_APP_API_BASE_URL + "/appointments/block", block);
}

const getAppointmentsByAccount = async(accountID) => {
    return await API.get(process.env.REACT_APP_API_BASE_URL + "/appointments/account/" + accountID);
}

const adminAddWalkin = async(appointment) => {
    return await API.post(process.env.REACT_APP_API_BASE_URL + "/appointments/walkin", appointment);
}

const saveAppointmentServices = async(services) => {
    return await API.post(process.env.REACT_APP_API_BASE_URL + "/appointments/services", services);
}

AppointmentService = {
    saveSessionAppointment,
    getSessionAppointment,
    clearSessionAppointment,
    getAvailableDates,
    getAvailableDatesByCalendar,
    getAvailableDatesByCalendarAndAppointmentTypeId,
    getAvailableTimes,
    getAvailableTimesByAppointmentTypeId,
    serviceCodes,
    symptoms,
    conditions,
    familyConditions,
    allergies,
    scheduleAppointment,
    alternatives,
    biometric_screening_types,
    getAppointments,
    getAppointmentById,
    getMyAppointments,
    addNotes,
    getNotes,
    cancelAppointment,
    createRequest,
    getRequests,
    //acceptRequest,
    startRequest,
    completeRequest,
    rescheduleAppointment,
    adminScheduleAppointment,
    getAllLocations,
    getLocations,
    getAll,
    getClinicBlocks,
    saveClinicBlock,
    getAppointmentsByAccount,
    adminAddWalkin,
    saveAppointmentServices
}

export default AppointmentService;