import React, { Fragment } from 'react';
import MyAppointments from '../../components/Account/MyAppointments';
import HomeBookAppointment from '../../components/Home/BookAppointment';

const EmployeeContainer = ({currentUser}) => {
        return (
            <Fragment>
                <HomeBookAppointment currentUser={currentUser} />
                <MyAppointments currentUser={currentUser} />
            </Fragment>
        );
}

EmployeeContainer.defaultProps = {}

export default EmployeeContainer;