import API from "./API";


const changePassword = async (oldPassword, newPassword) => {
    return await API.put(process.env.REACT_APP_API_BASE_URL + "/auth/password", 
    { oldPassword: oldPassword, newPassword: newPassword })
};

const getAccountPatients = async () => {
    return await API.get(process.env.REACT_APP_API_BASE_URL + "/records/patients/me");
}

const addAccountPatient = async (patient) => {
    return await API.post(process.env.REACT_APP_API_BASE_URL + "/records/patients/add", patient);
}

const addProvider = async (provider) => {
    return await API.post(process.env.REACT_APP_API_BASE_URL + "/auth/provider", provider);
}



const AccountService = {
    changePassword,
    getAccountPatients,
    addAccountPatient,
    addProvider
}

export default AccountService;