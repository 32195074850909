import React, { Fragment, useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import CheckboxGroup from "react-checkbox-group";
import FlashMessage from "../../FlashMessage";
import * as CONSTANT from "../../../constants/constants";
import useForm from "../../useForm";
import validate from "./TelehealthValidationRules";
import AuthService from "../../../services/auth.service";
import TelehealthGroupService from "../../../services/telehealthgroups.service";
import { nanoid } from "nanoid";
import { useHistory } from "react-router-dom";
import Loader from "../../UIHelpers/Loader";
import ReturnHome from "../../Navigation/ReturnHome";

/* Detail for Telehealth or Physical Clinic */

const TelehealthGroupDetail = () => {
	const params = useParams();
	const [flashMessage, setFlashMessage] = useState("");
	const [flashMessageType, setFlashMessageType] = useState("");
	const [providers, setProviders] = useState([]);
	const [selectedProviders, setSelectedProviders] = useState([]);

	const [loading, setLoading] = useState(false);

	const history = useHistory();

	const saveTelehealthGroup = () => {
		//clear errors
		setFlashMessage("");
		
		//if !params.calendarId then create
		let telehealthGroup = {
			active: values.active,
			name: values.name,
			state: values.state,
			phone: values.phone,
			phoneExt: values.phoneExt,
			providers: selectedProviders,
		};

		if (params.groupId) {
			telehealthGroup._id = params.groupId;

			TelehealthGroupService.updateTelehealthGroup(telehealthGroup)
				.then((response) => {
					setFlashMessage("Telehealth Group Updated");
					setFlashMessageType(CONSTANT.ALERT_TYPE.SUCCESS);
				})
				.catch((error) => {
					const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

					setFlashMessage(resMessage);
					setFlashMessageType(CONSTANT.ALERT_TYPE.ERROR);
				})
				.finally(() => setLoading(false));
		} else {
			TelehealthGroupService.addTelehealthGroup(telehealthGroup)
			.then((response) => {
				console.log(response);
				
				history.push({
					pathname: "/telehealthgroup/detail/" + response.data._id,
				});
			})
			.catch((error) => {
				const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

				setFlashMessage(resMessage);
				setFlashMessageType(CONSTANT.ALERT_TYPE.ERROR);
			})
			.finally(() => setLoading(false));
		}
	};

	useEffect(() => {
		AuthService.getProviders().then((response) => {
			setProviders(response.data);
		});
	}, []);

	const { setValues, values, errors, handleChange, handleSubmit } = useForm(saveTelehealthGroup, validate);

	useEffect(() => {
		if (params.groupId) {
			TelehealthGroupService.getTelehealthGroupById(params.groupId)
				.then((response) => {
					setValues(response.data);
					setSelectedProviders(response.data.providers);
				})
				.catch((error) => {
					const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

					setFlashMessage(resMessage);
					setFlashMessageType(CONSTANT.ALERT_TYPE.ERROR);
				});
		}
	}, [params.groupId, setValues]);

	return (
		<Fragment>
			<ReturnHome>
				<li className="breadcrumb-item"><Link to="/telehealthgroup/list">Telehealth Groups</Link></li>
				<li className="breadcrumb-item active">Telehealth Group Detail</li>
			</ReturnHome>

			<h1>Telehealth Group Detail</h1>

			{flashMessage.length > 0 && <FlashMessage message={flashMessage} messageType={flashMessageType} />}			

			<Loader show={loading} />

			<div className="row">
				<div className="col-6">
					<div className="form-group">
						<input
							type="checkbox"
							className="form-control-check"
							checked={values.active || false}
							value={true}
							onChange={(event) => setValues({ ...values, active: !values.active })}
						/>{" "}
						Active
					</div>

					<div className="form-group">
						<label>
							Telehealth Group Name <span className="text-danger">*</span>
						</label>
						<input
							type="text"
							className={`form-control ${errors.name && "is-invalid"}`}
							name="name"
							value={values.name || ""}
							disabled={params.name}
							onChange={handleChange}
						/>
						<div className="invalid-feedback">{errors.name}</div>
					</div>

					<div className="form-row">
						<div className="col">
							<label>Phone</label>
							<input
								type="text"
								className={`form-control ${errors.phone && "is-invalid"}`}
								name="phone"
								value={values.phone || ""}
								onChange={handleChange}
							/>
							<div className="invalid-feedback">{errors.phone}</div>
						</div>

						<div className="col">
							<label>Extension (optional)</label>
							<input
								type="text"
								className={`form-control ${errors.phoneExt && "is-invalid"}`}
								name="phoneExt"
								value={values.phoneExt || ""}
								onChange={handleChange}
							/>
							<div className="invalid-feedback">{errors.phoneExt}</div>
						</div>
					</div>
									
					<div className="form-group">
						<label>State</label>
						<select
							name="state"
							className={`form-control ${errors.mailingAddress?.state && "is-invalid"}`}
							value={values.state || ""}
							onChange={handleChange}>
							<option value="">Select</option>
							<option value="AL">Alabama</option>
							<option value="AK">Alaska</option>
							<option value="AZ">Arizona</option>
							<option value="AR">Arkansas</option>
							<option value="CA">California</option>
							<option value="CO">Colorado</option>
							<option value="CT">Connecticut</option>
							<option value="DE">Delaware</option>
							<option value="FL">Florida</option>
							<option value="GA">Georgia</option>
							<option value="HI">Hawaii</option>
							<option value="ID">Idaho</option>
							<option value="IL">Illinois</option>
							<option value="IN">Indiana</option>
							<option value="IA">Iowa</option>
							<option value="KS">Kansas</option>
							<option value="KY">Kentucky</option>
							<option value="LA">Louisiana</option>
							<option value="ME">Maine</option>
							<option value="MD">Maryland</option>
							<option value="MA">Massachusetts</option>
							<option value="MI">Michigan</option>
							<option value="MN">Minnesota</option>
							<option value="MS">Mississippi</option>
							<option value="MO">Missouri</option>
							<option value="MT">Montana</option>
							<option value="NE">Nebraska</option>
							<option value="NV">Nevada</option>
							<option value="NH">New Hampshire</option>
							<option value="NJ">New Jersey</option>
							<option value="NM">New Mexico</option>
							<option value="NY">New York</option>
							<option value="NC">North Carolina</option>
							<option value="ND">North Dakota</option>
							<option value="OH">Ohio</option>
							<option value="OK">Oklahoma</option>
							<option value="OR">Oregon</option>
							<option value="PA">Pennsylvania</option>
							<option value="RI">Rhode Island</option>
							<option value="SC">South Carolina</option>
							<option value="SD">South Dakota</option>
							<option value="TN">Tennessee</option>
							<option value="TX">Texas</option>
							<option value="UT">Utah</option>
							<option value="VT">Vermont</option>
							<option value="VA">Virginia</option>
							<option value="WA">Washington</option>
							<option value="WV">West Virginia</option>
							<option value="WI">Wisconsin</option>
							<option value="WY">Wyoming</option>
						</select>
						<div className="invalid-feedback">{errors.mailingAddress?.state}</div>
					</div>
					

					<p>
						<button className="btn btn-primary btn-block" onClick={handleSubmit}>
							Save
						</button>
					</p>
				</div>
				<div className="col-6">
					<div className="card mb-3">
						<div className="card-body">
							<h5 className="card-title">Providers</h5>
							<CheckboxGroup name="clinicProviders" value={selectedProviders} onChange={setSelectedProviders}>
								{(Checkbox) => (
									<Fragment key={nanoid()}>
										{providers.map((provider) => (
											<div className="form-check" key={nanoid()}>
												<Checkbox className="form-check-input" value={provider._id} />
												<label className="form-check-label">{provider.firstName} {provider.lastName}</label>
											</div>
										))}
									</Fragment>
								)}
							</CheckboxGroup>
						</div>
					</div>
					
					{params.groupId &&
						<div className="card">
							<div className="card-body">
								<h5 className="card-title">Appointments</h5>
								<p>
									<Link to="/clinic/appointments" className="btn btn-outline-primary">
										Manage Appointments
									</Link>
								</p>
							</div>
						</div>
					}
				</div>
			</div>
		</Fragment>
	);
};

TelehealthGroupDetail.defaultProps = {};

export default TelehealthGroupDetail;
