import React, { Fragment } from 'react';
import MyAppointments from '../../components/Account/MyAppointments';
import HomeBookAppointment from '../../components/Home/BookAppointment';

const EmployerContainer = ({currentUser}) => {
        return (
            <Fragment>
                <HomeBookAppointment currentUser={currentUser} />
                <MyAppointments currentUser={currentUser}/>
            </Fragment>
        );
}

EmployerContainer.defaultProps = {}

export default EmployerContainer;