import React from "react";
import PropTypes from "prop-types";
import * as CONSTANT from "../../constants/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faExclamationCircle, faTimesCircle, faQuestionCircle, faFlag } from "@fortawesome/free-solid-svg-icons";
import HeadShake from "react-reveal/HeadShake";

const FlashMessage = ({ message, messageType }) => {
	let style;
	let icon;

	switch (messageType) {
		case CONSTANT.ALERT_TYPE.INFO:
			icon = faQuestionCircle;
			style = "alert alert-info";
			break;
		case CONSTANT.ALERT_TYPE.WARN:
			icon = faExclamationCircle;
			style = "alert alert-warning";
			break;
		case CONSTANT.ALERT_TYPE.ERROR:
			icon = faTimesCircle;
			style = "alert alert-danger";
			break;
		case CONSTANT.ALERT_TYPE.SUCCESS:
			icon = faCheckCircle;
			style = "alert alert-success";
			break;
		default:
			icon = faFlag;
			style = "alert";
	}

	return (
		<HeadShake>
			<div className={style}>
				<FontAwesomeIcon icon={icon} /> {message}
			</div>
		</HeadShake>
	);
};

FlashMessage.propTypes = {
	message: PropTypes.string,
};

export default FlashMessage;
