export default function validate(values) {
    let errors = {};

    if (!values.oldPassword) {
        errors.oldPassword = "Required";
    }

    if (!values.newPassword) {
        errors.newPassword = "Required";
    } else if (values.newPassword.length < 10) {
        errors.newPassword = "Must be at least 10 characters";
    }

    return errors;
};