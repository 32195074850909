import React, { useState } from "react";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReturnHome from "../../Navigation/ReturnHome";
import useForm from "../../useForm";
import validate from "./ChangePasswordValidationRules";
import FlashMessage from "../../FlashMessage";
import * as CONSTANT from "../../../constants/constants";
import AccountService from "../../../services/account.service";

const ChangePassword = () => {
	const [flashMessage, setFlashMessage] = useState("");
	const [flashMessageType, setFlashMessageType] = useState("");

	const [inputMaskType, setInputMaskType] = useState("password");

	const handleToggleShowPassword = () => {
		let maskType = inputMaskType === "password" ? "text" : "password";
		setInputMaskType(maskType);
	};

	const handleChangePassword = () => {
		AccountService.changePassword(values.oldPassword, values.newPassword).then((response) => {
			setFlashMessage("Password changed");
			setFlashMessageType(CONSTANT.ALERT_TYPE.SUCCESS);
		})
		.catch((error) => {
			const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

			setFlashMessage(resMessage);
			setFlashMessageType(CONSTANT.ALERT_TYPE.ERROR);
		});
	};

	const { values, errors, handleChange, handleSubmit } = useForm(handleChangePassword, validate);

	return (
		<div id="change-password">
			<ReturnHome />

			<h1 className="my-3">Change Password</h1>

			{flashMessage.length > 0 && <FlashMessage message={flashMessage} messageType={flashMessageType} />}

			<div className="form-group">
				<label>Enter your current password</label>
				<div className="input-group">
					<input type={inputMaskType} name="oldPassword" className={`form-control ${errors.oldPassword && "is-invalid"}`} value={values.oldPassword || ""} onChange={handleChange} />

					<div className="input-group-append">
						<div className="input-group-text">
							<a href="#" onClick={() => handleToggleShowPassword()}>
								{inputMaskType === "password" && <FontAwesomeIcon icon={faEye} />}
								{inputMaskType === "text" && <FontAwesomeIcon icon={faEyeSlash} />}
							</a>
						</div>
					</div>
					<div className="invalid-feedback">{errors.oldPassword}</div>
				</div>
				
			</div>

			<div className="form-group">
				<label>New password</label>
				<div className="input-group">
					<input type={inputMaskType} 
						name="newPassword" 
						className={`form-control ${errors.newPassword && "is-invalid"}`} 
						value={values.newPassword || ""} 
						onChange={handleChange} />

					<div className="input-group-append">
						<div className="input-group-text">
							<a href="#" onClick={() => handleToggleShowPassword()}>
								{inputMaskType === "password" && <FontAwesomeIcon icon={faEye} />}
								{inputMaskType === "text" && <FontAwesomeIcon icon={faEyeSlash} />}
							</a>
						</div>
					</div>
					<div className="invalid-feedback">{errors.newPassword}</div>
				</div>
				
				<small className="form-text text-muted">
					&bull; Use at least 10 characters
					<br />
					&bull; Use at least three of these four types of characters: lowercase, uppercase, digits, special characters (!@#$, etc.)
				</small>
			</div>

			<button className="btn btn-primary" onClick={handleSubmit}>
				Change password
			</button>
		</div>
	);
};

ChangePassword.defaultProps = {};

export default ChangePassword;
