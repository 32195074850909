import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { faCreditCard, faAddressCard, faLock } from "@fortawesome/free-solid-svg-icons";

const Account = ({ currentUser }) => {
	return (
		<div id="account-container">
			<h1>My Account</h1>

			<div className="list-group">
				{currentUser.role !== "admin" && (
					<Link to="/payment-methods" className="list-group-item list-group-item-action shadow-sm">
						<FontAwesomeIcon icon={faCreditCard} /> Payment Methods
					</Link>
				)}

				<Link to="/contact" className="list-group-item list-group-item-action shadow-sm">
					<FontAwesomeIcon icon={faAddressCard} /> Contact Information
				</Link>

				<Link to="/change-password" className="list-group-item list-group-item-action shadow-sm">
					<FontAwesomeIcon icon={faLock} /> Password
				</Link>
			</div>
		</div>
	);
};

Account.defaultProps = {};

export default Account;
