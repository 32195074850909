import React, { Fragment } from "react";
import { format } from "date-fns-tz";
import parseISO from "date-fns/parseISO";
import { enUS } from "date-fns/locale";
import * as CONSTANT from "../../../constants/constants";

const PatientSummary = ({ appointment }) => {
	return (
		<Fragment>
			<p style={{lineHeight: "32px"}}>
				Get Care: {appointment.modality === "on-demand" ? "Telehealth" : "Clinic Visit"}
				<br />
				For: <strong>{appointment.patient?.fullName}</strong>
				<br />
				{appointment.modality !== "on-demand" && appointment.datetime && (
					<Fragment>
						Date/time: {format(parseISO(appointment.datetime), "MMM dd, yyyy h:mm b", { locale: enUS })}
						<br />
					</Fragment>
				)}
				Patient Cost: ${appointment.receipt?.total}
				<br />
				Contact Us: {CONSTANT.CONTACT.PHONE}
				<br />
				{appointment.modality !== "on-demand" && <Fragment>Location: {appointment?.location?.location}</Fragment>}
			</p>

			{appointment.modality === "on-demand" && (
				<div className="alert alert-warning shadow-sm">
					<p>
						If you're using a cell phone for your appointment, remember to set your phone to accept anonymous calls. The call will
						use minutes from your cell phone plan.
					</p>
					<p>
						Your phone appointment will begin after a provider accepts the appointment and reviews the details, during regular business
						hours Monday - Friday 8am - 5pm.
					</p>
				</div>
			)}
		</Fragment>
	);
};

PatientSummary.defaultProps = {};

export default PatientSummary;