import React, { Fragment } from "react";
import { faCreditCard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {nanoid} from 'nanoid';


const CardList = ({ cards, clickAction, selectedPaymentMethod }) => {
	const handleClick = (card) => {
		clickAction(card);
	}

	return (
		<Fragment>			
			{cards?.map((card) => {
				return (
					<div className="list-group mb-3" key={card.id}>
						{clickAction && 
							<button type="button" className={`list-group-item list-group-item-action ${selectedPaymentMethod?.id === card.id && 'active'}`} onClick={ () => clickAction && handleClick(card) } key={nanoid()}>
								<FontAwesomeIcon icon={faCreditCard} /> Your card ending in {card.card.last4}
							</button>
						}

						{!clickAction &&
							<li className="list-group-item"><FontAwesomeIcon icon={faCreditCard} /> Your card ending in {card.card.last4}</li>
						}
					</div>
				)
			})}
		</Fragment>
	);
}


export default CardList;