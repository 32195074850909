export default function validate(values) {
    let errors = {};

    if (!values.firstName) {
        errors.firstName = "Required";
    }

    if (!values.lastName) {
        errors.lastName = "Required";
    }

    if (!values.mobile) {
        errors.mobile = "Required";
    }

    if (!values.email) {
        errors.email = "Required";
    }

    return errors;
};