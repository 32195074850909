import React, { Fragment, useEffect, useState } from "react";
import useForm from "../../useForm";
import validate from "./OnDemandConfirmValidationRules";
import { faCircleNotch, faHandPointRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PaymentMethods from "../../Account/PaymentMethods";

const ConfirmRequest = ({
	currentUser,
	patientCost,
	paymentMethod,
	appointmentProcessing,
	handleSetRequestState,
	handleSetRequestContactPhone,
	handleSetPaymentMethod,
	handleConfirmRequest,
}) => {



	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);

	const [callbackNumberConfirmed, setCallbackNumberConfirmed] = useState(false);



	const handleConfirmCallbackNumber = () => {
		handleSetRequestContactPhone(values.requestContactPhone);
		handleSetRequestState(values.requestState);
		setCallbackNumberConfirmed(true);
	};

	const { values, errors, handleChange, handleSubmit } = useForm(handleConfirmCallbackNumber, validate);


	useEffect(() => {
		if (paymentMethod) {
			setSelectedPaymentMethod(paymentMethod);
		}
	}, [paymentMethod]);


	const handleChangeCallbackNumber = () => {
		setCallbackNumberConfirmed(false);
	};

	return (
		<div id="appointment-confirm">
			<h1 className="my-3">Confirm Appointment</h1>

			{patientCost > 0 && (
				<Fragment>
					<h4 className="mt-5">Payment</h4>

					<h5>
						Your Cost: <strong>${patientCost}</strong>
					</h5>

					<PaymentMethods clickAction={handleSetPaymentMethod} selectedPaymentMethod={selectedPaymentMethod} showHeader={false} />

				</Fragment>
			)}



			<div className="form-group mt-3">
				<label>Your provider will call you at the number you enter here:</label>
				<input
					placeholder="000-000-0000"
					type="tel"
					name="requestContactPhone"
					className={`form-control ${errors.requestContactPhone && "is-invalid"}`}
					value={values.requestContactPhone || ""}
					onChange={handleChange}
					disabled={callbackNumberConfirmed}
				/>
				<div className="invalid-feedback">{errors.requestContactPhone}</div>
			</div>

			<div className="form-group">
				<label>In which state will you take the call?</label>
				<select name="requestState" className={`form-control ${errors.requestState && "is-invalid"}`} onChange={handleChange} disabled={callbackNumberConfirmed}>
					<option value="">Select</option>
					<option value="AL">Alabama</option>
					<option value="AK">Alaska</option>
					<option value="AZ">Arizona</option>
					<option value="AR">Arkansas</option>
					<option value="CA">California</option>
					<option value="CO">Colorado</option>
					<option value="CT">Connecticut</option>
					<option value="DE">Delaware</option>
					<option value="FL">Florida</option>
					<option value="GA">Georgia</option>
					<option value="HI">Hawaii</option>
					<option value="ID">Idaho</option>
					<option value="IL">Illinois</option>
					<option value="IN">Indiana</option>
					<option value="IA">Iowa</option>
					<option value="KS">Kansas</option>
					<option value="KY">Kentucky</option>
					<option value="LA">Louisiana</option>
					<option value="ME">Maine</option>
					<option value="MD">Maryland</option>
					<option value="MA">Massachusetts</option>
					<option value="MI">Michigan</option>
					<option value="MN">Minnesota</option>
					<option value="MS">Mississippi</option>
					<option value="MO">Missouri</option>
					<option value="MT">Montana</option>
					<option value="NE">Nebraska</option>
					<option value="NV">Nevada</option>
					<option value="NH">New Hampshire</option>
					<option value="NJ">New Jersey</option>
					<option value="NM">New Mexico</option>
					<option value="NY">New York</option>
					<option value="NC">North Carolina</option>
					<option value="ND">North Dakota</option>
					<option value="OH">Ohio</option>
					<option value="OK">Oklahoma</option>
					<option value="OR">Oregon</option>
					<option value="PA">Pennsylvania</option>
					<option value="RI">Rhode Island</option>
					<option value="SC">South Carolina</option>
					<option value="SD">South Dakota</option>
					<option value="TN">Tennessee</option>
					<option value="TX">Texas</option>
					<option value="UT">Utah</option>
					<option value="VT">Vermont</option>
					<option value="VA">Virginia</option>
					<option value="WA">Washington</option>
					<option value="WV">West Virginia</option>
					<option value="WI">Wisconsin</option>
					<option value="WY">Wyoming</option>
				</select>
				<div className="invalid-feedback">{errors.requestState}</div>
			</div>

			<p>
				{!callbackNumberConfirmed && (
					<button className="btn btn-outline-primary btn-block" onClick={handleSubmit} disabled={!values.requestState || !values.requestContactPhone || (patientCost > 0 && !selectedPaymentMethod)}>
						Confirm Callback Number and State
					</button>
				)}

				{callbackNumberConfirmed && (
					<button className="btn btn-outline-primary btn-block" onClick={handleChangeCallbackNumber}>
						Change Callback Number
					</button>
				)}
			</p>

			{callbackNumberConfirmed && (
				<Fragment>
					<div className="alert alert-warning shadow-sm">
						<p>
							<FontAwesomeIcon icon={faHandPointRight} /> If you're using a cell phone for your appointment, remember to set your
							phone to accept anonymous calls. The call will use minutes from your cell phone plan.
						</p>
						<p>
							Your phone appointment will begin after a provider accepts the appointment and reviews the details, during regular business
							hours Monday - Friday 8am - 5pm.
						</p>
					</div>

					

					{appointmentProcessing && (
						<Fragment>
							<p>
								<FontAwesomeIcon icon={faCircleNotch} spin /> <em>One moment, booking appointment...</em>
							</p>
						</Fragment>
					)}

					<p className="my-3">
						<button className="btn btn-primary btn-block" onClick={handleConfirmRequest}>
							Confirm Appointment
						</button>
					</p>
				</Fragment>
			)}


		</div>
	);
};

ConfirmRequest.defaultProps = {};

export default ConfirmRequest;
