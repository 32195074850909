import React, { Fragment, useState } from 'react';
import { Modal } from "react-bootstrap";
import { useParams } from 'react-router';
import EmployerService from '../../../services/employer.service';
import FlashMessage from '../../FlashMessage';
import * as CONSTANT from "../../../constants/constants";

const EmployerSMS = () => {
    const [smsMessage, setSMSMessage] = useState(null);
    const [show, setShow] = useState(false);
    const [flashMessage, setFlashMessage] = useState("");
	const [flashMessageType, setFlashMessageType] = useState("");

    const params = useParams();

    const handleConfirmSend = () => {
        setShow(true);
    }

    const handleCancelSMS = () => {
        setShow(false);
    }

    const handleClose = () => {
		//hide modal
		setShow(false);
	};

    const handleSendSMS = () => {
        let sms = {
            employerId: params.employerId,
            smsBody: smsMessage
        };
        EmployerService.sendSMS(sms).then((response) => {
            setShow(false);
            setFlashMessage("Sent " + response.data.sentCount + " text messages. You may log into Twilio to view details.");
            setFlashMessageType(CONSTANT.ALERT_TYPE.SUCCESS);

            setSMSMessage(null);
        }).catch((error) => {
			const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

			setFlashMessage(resMessage);
			setFlashMessageType(CONSTANT.ALERT_TYPE.ERROR);
		});;
    }

    return (
        <Fragment>
            <h1>Employer SMS</h1>

            {flashMessage.length > 0 && <FlashMessage message={flashMessage} messageType={flashMessageType} />}

            <p>Send a mass SMS text to all <strong>active</strong> employees and employer admins. Text and data rates will apply to recipients.</p>

            <div className="alert alert-info">This text will be delivered to all active employees with a valid mobile phone number.</div>

            <div className="form-row">
                <label>Compose Message:</label>
                <textarea className="form-control" onChange={(e) => setSMSMessage(e.target.value)}></textarea>
            </div>
            
            <p className="mt-3"><button className="btn btn-primary btn-block" onClick={handleConfirmSend}>Send Text</button></p>


            {/* Confirm Send SMS Modal */}
            <Modal show={show} onHide={handleClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Send Mass SMS</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="alert alert-warning">
                        <strong>Are you sure you want to send this text to all active employees?</strong>
                    </div>

                    <button
                        className="btn btn-outline-primary btn-block"
                        onClick={handleSendSMS}>
                        Send SMS Text
                    </button>

                    <button className="btn btn-block" onClick={handleCancelSMS}>Cancel</button>
                </Modal.Body>
            </Modal>
            
        </Fragment>
    );
}

EmployerSMS.defaultProps = {}

export default EmployerSMS;