export default function validate(values) {
    let errors = {};

    if (!values.newPassword) {
        errors.newPassword = "Required"
    }

    if (!values.newPasswordConfirm) {
        errors.newPasswordConfirm = "Required"
    }

    if (values.newPassword !== values.newPasswordConfirm) {
        errors.passwordMismatch = "Passwords do not match";
    }

    return errors;
};