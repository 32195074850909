import React, { Fragment, useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import AddressForm from "../../Account/ContactInformation/AddressForm";
import CheckboxGroup from "react-checkbox-group";
import FlashMessage from "../../FlashMessage";
import * as CONSTANT from "../../../constants/constants";
import useForm from "../../useForm";
import validate from "./ClinicValidationRules";
import AuthService from "../../../services/auth.service";
import EmployerService from "../../../services/employer.service";
import { nanoid } from "nanoid";
import { useHistory } from "react-router-dom";
import Loader from "../../UIHelpers/Loader";

/* Detail for Telehealth or Physical Clinic */

const ClinicDetail = () => {
	const params = useParams();
	const [flashMessage, setFlashMessage] = useState("");
	const [flashMessageType, setFlashMessageType] = useState("");
	const [providers, setProviders] = useState([]);
	const [selectedProviders, setSelectedProviders] = useState([]);
	const [allowedAppointmentTypes, setAllowedAppointmentTypes] = useState([]);
	const appointmentTypes = [
		{
			acuityTypeName: "Prescription Refill",
			acuityTypeId: 14876850,
			permissionName: "onsite_prescription"
		},
		{
			acuityTypeName: "Biometrics Screening",
			acuityTypeId: 14876850,
			permissionName: "onsite_biometric_screening"
		},
		{
			acuityTypeName: "Acute Care",
			acuityTypeId: 30115342,
			permissionName: "onsite_illness"
		},
		{
			acuityTypeName: "Primary Care",
			acuityTypeId:14876850,
			permissionName: "onsite_disease_management"
		},
		{
			acuityTypeName: "Wellness Coaching",
			acuityTypeId: 14876850,
			permissionName: "onsite_wellness_coaching"
		},
		{
			acuityTypeName: "Physical",
			acuityTypeId: 29498535,
			permissionName: "onsite_physical"
		},
		{
			acuityTypeName: "Work-Related Injury",
			acuityTypeId: 14876850,
			permissionName: "onsite_work_injury"
		},
		{
			acuityTypeName: "Non-Work-Related Injury",
			acuityTypeId: 14876850,
			permissionName: "onsite_nonwork_injury"
		},
		{
			acuityTypeName: "Influenza Vaccine",
			acuityTypeId: 14876850,
			permissionName: "onsite_flu_shot"
		},
		{
			acuityTypeName: "Other Issue",
			acuityTypeId: 14876850,
			permissionName: "onsite_other"
		},
		{
			acuityTypeName: "Thrive Fair Screening",
			acuityTypeId: 29307036,
			permissionName: "onsite_thrive_fair"
		},
		{
			acuityTypeName: "DOT Physical",
			acuityTypeId: 57228748,
			permissionName: "onsite_dot_physical"
		},
		{
			acuityTypeName: "New Patient Visit",
			acuityTypeId: 57716201,
			permissionName: "onsite_new_patient"
		}
	];
	const [employer, setEmployer] = useState();
	const [loading, setLoading] = useState(false);

	const history = useHistory();

	const saveClinic = () => {
		//convert providers to array of objects
		let oProviders = selectedProviders.map((provider) => {
			return { _id: provider };
		});

		//Get the unique appointment types for this employer and calendar
		let oAppointmentTypes = [];
		allowedAppointmentTypes.forEach((apptType) => {
			let oApptType = appointmentTypes.find(x => x.permissionName === apptType);
			if (oApptType !== undefined && oApptType !== null && !oAppointmentTypes.includes(oApptType.acuityTypeId)) {
				oAppointmentTypes.push(oApptType.acuityTypeId);
			}
		});

		if (params.calendarId) {
			/* Update existing clinic */
			employer.calendars.forEach((calendar) => {
				if (calendar.calendarID === parseInt(params.calendarId)) {
					calendar.active = values.active;
					calendar.location = values.location;
					calendar.providers = oProviders;
					calendar.allowedAppointmentTypes = oAppointmentTypes;

					if (values.mailingAddress) calendar.mailingAddress = values.mailingAddress;
					if (values.phone) calendar.phone = values.phone;
				}
			});
		} else {
			/* Create new clinic */
			let clinic = {
				calendarID: values.calendarID,
				active: values.active,
				location: values.location,
				providers: oProviders,
				allowedAppointmentTypes: oAppointmentTypes
			};

			if (values.mailingAddress) clinic.mailingAddress = values.mailingAddress;
			if (values.phone) clinic.phone = values.phone;

			employer.calendars.push(clinic);
		}

		//console.log(employer);


		EmployerService.updateEmployer(employer)
			.then((response) => {
				setFlashMessage("Employer Updated");
				setFlashMessageType(CONSTANT.ALERT_TYPE.SUCCESS);

				if (!params.calendarId) {
					history.push({ pathname: "/clinic/detail/" + params.employerId + "/" + values.calendarID })
				}
			})
			.catch((error) => {
				const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

				setFlashMessage(resMessage);
				setFlashMessageType(CONSTANT.ALERT_TYPE.ERROR);
			})
			.finally(() => setLoading(false));

	};

	useEffect(() => {
		AuthService.getProviders().then((response) => {
			setProviders(response.data);
		});
	}, []);

	const { setValues, values, errors, handleChange, handleSubmit } = useForm(saveClinic, validate);

	useEffect(() => {
		EmployerService.getEmployer(params.employerId)
			.then((response) => {
				setEmployer(response.data);


				if (params.calendarId) {
					response.data.calendars.forEach((calendar) => {
						if (calendar.calendarID === parseInt(params.calendarId)) {
							setValues(calendar);
							setSelectedProviders(calendar.providers.map((provider) => provider._id));
							//key off permissions because multiple appointment types have the same acuity ID
							setAllowedAppointmentTypes(response.data.permissions);
						}
					});
				} else {
					//Set new clinic as active by default
					setValues({ ...values, active: true });
				}
			})
			.catch((error) => {
				const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

				setFlashMessage(resMessage);
				setFlashMessageType(CONSTANT.ALERT_TYPE.ERROR);
			});		
	}, [params.employerId, params.calendarId]);

	return (
		<Fragment>
			<h1>Clinic Detail</h1>

			{flashMessage.length > 0 && <FlashMessage message={flashMessage} messageType={flashMessageType} />}

			<Loader show={loading} />

			<div className="row">
				<div className="col-6">
					<div className="form-group">
						<input
							type="checkbox"
							name="active"
							className="form-control-check"
							checked={values.active || false}
							value={true}
							onChange={(event) => setValues({ ...values, active: !values.active })}
						/>{" "}
						Active
					</div>

					<div className="form-group">
						<label>
							Acuity Calendar ID <span className="text-danger">*</span>
						</label>
						<input
							type="number"
							className={`form-control ${errors.calendarID && "is-invalid"}`}
							name="calendarID"
							value={values.calendarID || ""}
							onChange={handleChange}
							disabled={params.calendarId}
						/>
						<div className="invalid-feedback">{errors.calendarID}</div>
					</div>

					<div className="form-group">
						<label>
							Clinic Name/Location <span className="text-danger">*</span>
						</label>
						<input
							type="text"
							className={`form-control ${errors.location && "is-invalid"}`}
							name="location"
							value={values.location || ""}
							onChange={handleChange}
						/>
						<div className="invalid-feedback">{errors.location}</div>
					</div>

					<div className="form-group">
						<label>Phone</label>
						<input
							type="text"
							className={`form-control ${errors.phone && "is-invalid"}`}
							name="phone"
							value={values.phone || ""}
							onChange={handleChange}
						/>
						<div className="invalid-feedback">{errors.phone}</div>
					</div>

					<AddressForm handleChange={handleChange} values={values} errors={errors} />

					<p>
						<button className="btn btn-primary" onClick={handleSubmit}>
							Save
						</button>
					</p>
				</div>
				<div className="col-6">
					<div className="card mb-3">
						<div className="card-body">
							<p>Providers</p>
							<CheckboxGroup name="clinicProviders" value={selectedProviders}
										onChange={setSelectedProviders}>
								{(Checkbox) => (
									<Fragment key={nanoid()}>
										{providers.map((provider) => (
											<div className="form-check" key={nanoid()}>
												<Checkbox className="form-check-input"
														value={provider._id} />
												<label
													className="form-check-label">{provider.firstName + " " + provider.lastName}</label>
											</div>
										))}
									</Fragment>
								)}
							</CheckboxGroup>
						</div>
					</div>

					<div className="card mb-3">
						<div className="card-body">
							<p>Allowed Appointment Types</p>
							<CheckboxGroup name="allowedAppointmentTypes" value={allowedAppointmentTypes}
										onChange={setAllowedAppointmentTypes}>
								{(Checkbox) => (
									<Fragment key={nanoid()}>
										{appointmentTypes.map((apptType) => (
											<div className="form-check" key={nanoid()}>
												<Checkbox className="form-check-input"
														value={apptType.permissionName} />
												<label
													className="form-check-label">{apptType.acuityTypeName} <small>({apptType.permissionName} {apptType.acuityTypeId})</small></label>
											</div>
										))}
									</Fragment>
								)}
							</CheckboxGroup>
						</div>
					</div>

					{params.calendarId && (
						<Fragment>
							<div className="card mb-3">
								<div className="card-body">
									<p>
										Available Days and Times
										<a
											href="https://secure.acuityscheduling.com/admin/calendars"
											target="new"
											className="btn btn-outline-primary float-right">
											Edit Available Days and Times
										</a>
									</p>
								</div>
							</div>

							<div className="card">
								<div className="card-body">
									<p>
										Appointments
										<Link to="/clinic/appointments"
											 className="btn btn-outline-primary float-right">
											Manage Appointments
										</Link>
									</p>
								</div>
							</div>
						</Fragment>
					)}
				</div>
			</div>


			{/*
			<pre>{JSON.stringify(allowedAppointmentTypes, null, 2)}</pre>
			<pre>{JSON.stringify(values, null, 2)}</pre>
			<pre>{JSON.stringify(selectedProviders, null, 2)}</pre>
			<pre>{JSON.stringify(employer, null, 2)}</pre>
			*/}

		</Fragment>
	);
};

ClinicDetail.defaultProps = {};

export default ClinicDetail;
