import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import EmployerService from "../../../services/employer.service";
//import scheduleAppointment from "./schedule_appointment.jpg";
import * as CONSTANT from "../../../constants/constants";
import FlashMessage from "../../FlashMessage";


const HomeBookAppointment = ({currentUser}) => {
	const history = useHistory();

	const [flashMessage, setFlashMessage] = useState("");
	const [flashMessageType, setFlashMessageType] = useState("");

	const [employer, setEmployer] = useState(null);
	const [apptType, setApptType] = useState(null);

	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setLoading(true);
		//let currentUser = AuthService.getCurrentUser();

		EmployerService.getEmployer(currentUser?.employer._id).then((res) => {
			setEmployer(res.data);
		}).catch((error) => {
			const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

			setFlashMessage(resMessage);
			setFlashMessageType(CONSTANT.ALERT_TYPE.ERROR);
		}).finally(() => {
			setLoading(false);
		});
	}, [currentUser]);

	const handleBookSelfAppointment = () => {
		setApptType(CONSTANT.APPT_TYPE.SELF);
	};

	const handleSelectDependent = () => {
		setApptType(CONSTANT.APPT_TYPE.DEPENDENT);
	};

	const handleNextClick = () => {
		if (apptType === CONSTANT.APPT_TYPE.SELF) {
			history.push({
				pathname: "/book-appointment/self",
			});
		}

		if (apptType === CONSTANT.APPT_TYPE.DEPENDENT) {
			history.push({
				pathname: "/select-dependent",
			});
		}
	};

	return (
		<Fragment>
			{flashMessage.length > 0 && <FlashMessage message={flashMessage} messageType={flashMessageType} />}

			<div className="row py-1" id="home-book-appointment">
				<div className="col-12">
					<h2 className="pt-4">Get started now to get the care you need.</h2>

					<hr />

					<h3 className="pt-1 pb-4">I want to make an appointment for</h3>

					{!loading &&
						<div className="list-group">
							<button
								className={`shadow-sm list-group-item list-group-item-action ${apptType === CONSTANT.APPT_TYPE.SELF && "active"}`}
								onClick={handleBookSelfAppointment}>
								<span className="card-title">Myself</span>
							</button>

							{employer?.permissions?.includes("dependents") && (
								<button
									className={`shadow-sm list-group-item list-group-item-action ${
										apptType === CONSTANT.APPT_TYPE.DEPENDENT && "active"
									}`}
									onClick={handleSelectDependent}>
									<span className="card-title">My Spouse or Child</span>
								</button>
							)}
						</div>
					}

					<p className="mt-5">
						<button className="btn btn-primary btn-block" disabled={!apptType} onClick={handleNextClick}>
							Next &raquo;
						</button>
					</p>
				</div>
			</div>
		</Fragment>
	);
};

HomeBookAppointment.defaultProps = {};

export default HomeBookAppointment;
