import React, { Fragment, useState, useEffect } from "react";
import CSVReader from "react-csv-reader";
import AuthService from "../../../services/auth.service";
import * as CONSTANT from "../../../constants/constants";
import FlashMessage from "../../FlashMessage";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "../../UIHelpers/Loader";
import ReturnHome from "../../Navigation/ReturnHome";


//https://www.npmjs.com/package/react-csv-reader

const UploadEmployees = ({currentUser}) => {
	const [selectedEmployer, setSelectedEmployer] = useState("");
	const [employers, setEmployers] = useState([]);

	const [fileEmployees, setFileEmployees] = useState([]);

	const [newUsers, setNewUsers] = useState([]);
	const [updatedUsers, setUpdatedUsers] = useState([]);
	const [rejectedUsers, setRejectedUsers] = useState([]);

	const [loading, setLoading] = useState(false);
	const [census, setCensus] = useState([]);

	const [flashMessage, setFlashMessage] = useState("");
	const [flashMessageType, setFlashMessageType] = useState("");

	useEffect(() => {
		AuthService.getEmployers().then((response) => {
			setEmployers(response.data);
		});
	}, []);

	const parserOptions = {
		header: true,
		skipEmptyLines: true,
	};

	useEffect(() => {
		if (currentUser.role === "employer") {
			setSelectedEmployer(currentUser.employer._id);
		}
	}, [currentUser]);

	const handleFileUpload = (data, fileInfo) => {
		setLoading(true);
		let employees = [];

		console.dir(data, fileInfo);
		console.table(data);

		setFileEmployees(data);

		data.forEach((element) => {
			if (element.employee_id !== "") {
				employees.push(element);
			}
		});

		//send to users/upload with body:
		//census
		//notifyEmail: true/false to send email to new users
		//employer: id of employer uploaded employees belong to

		setCensus(employees);

		setLoading(false);
	};

	const handleUpload = () => {
		setLoading(true);

		let reqBody = {};
		reqBody.employer = selectedEmployer;
		reqBody.census = census;
		reqBody.notifyEmail = false;

		AuthService.uploadEmployeesFromCsv(reqBody).then((response) => {
			//let rejected = response.data.rejected;
			//let totalUsers = response.data.rejected.length + response.data.resolved.length;
			let usersNew = [];
			let usersUpdated = [];

			response.data.resolved.forEach((value) => {
				if (value.info.isNewUser) {
					usersNew.push(value.info);
				} else {
					usersUpdated.push(value.info);
				}
			});

			setNewUsers(usersNew);
			setUpdatedUsers(usersUpdated);
			setRejectedUsers(response.data.rejected);
		})
		.catch((error) => {
			const resMessage =
				(error.response && error.response.data && error.response.data.message) || error.message || error.toString();

			setFlashMessage(resMessage);
			setFlashMessageType(CONSTANT.ALERT_TYPE.ERROR);
		})
		.finally(() => setLoading(false));
	};
	return (
		<Fragment>
			{/* 
			<pre>New: {JSON.stringify(newUsers, null, 2)}</pre>
			<pre>Updated: {JSON.stringify(updatedUsers, null, 2)}</pre>
			<pre>Rejected: {JSON.stringify(rejectedUsers, null, 2)}</pre>
			*/}
			<ReturnHome />
			
			<h1>Upload Employees</h1>			

			{flashMessage.length > 0 && <FlashMessage message={flashMessage} messageType={flashMessageType} />}

			<Loader show={loading} />

			<div className="form-group">
				<label>Employer: </label>
				{currentUser.role === "admin" &&
					<select value={selectedEmployer} onChange={(event) => setSelectedEmployer(event.target.value)} className="form-control">
						<option value="">Select</option>
						{employers.map((employer) => (
							<option value={employer._id} key={employer._id}>
								{employer.name}
							</option>
						))}
					</select>
				}
				{currentUser.role === "employer" && 
					<select value={selectedEmployer} className="form-control" disabled>						
						<option value={currentUser.employer._id}>{currentUser.employer.name}</option>
					</select>
				}
			</div>

			
			<CSVReader onFileLoaded={handleFileUpload} parserOptions={parserOptions} />
			{fileEmployees.length > 0 &&
				<p>Found {fileEmployees.length} employees</p>
			}
			

			<p className="mt-3">
				<button className="btn btn-primary" onClick={handleUpload}>
					Upload Employees
				</button>
			</p>

			{newUsers.length > 0 && 
				<div className="alert alert-success">
					<FontAwesomeIcon icon={faCheckCircle} /> Created {newUsers.length} new employee accounts</div>
			}

			{updatedUsers.length > 0 && 
				<div className="alert alert-success p-3">
					<FontAwesomeIcon icon={faCheckCircle} /> Updated {updatedUsers.length} employee accounts</div>
			}

			{rejectedUsers.length > 0 &&
				<div className="alert-warning p-3">
					Failed to upload the following users:
					{rejectedUsers.map((user) => (
						<p key={user.info.employee_id}>{user.info.employee_id} {user.info.firstName} {user.info.lastName}<br/>
						<small>Error: {user.error.message}</small></p>
					))}
				</div>
			}
		</Fragment>
	);
};

UploadEmployees.defaultProps = {};

export default UploadEmployees;
