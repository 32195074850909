export default function validate(values) {
    let errors = {};

    if (!values.firstName) {
        errors.firstName = "Required"
    }

    if (!values.lastName) {
        errors.lastName = "Required"
    }

    if (!values.dob) {
        errors.dob = "Required"
    }

    if (!values.gender) {
        errors.gender = "Required"
    }

    return errors;
};