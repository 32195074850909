import React, { Fragment, useState, useEffect } from "react";
import EmployerService from "../../../services/employer.service";
//import AuthService from "../../../services/auth.service"
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { faArrowAltCircleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FlashMessage from "../../FlashMessage";
import * as CONSTANT from "../../../constants/constants";
import Loader from "../../UIHelpers/Loader";
import ReturnHome from "../../Navigation/ReturnHome";
//import { setDayOfYear } from "date-fns";

const EmployeeList = () => {
	const [employees, setEmployees] = useState([]);
	const [filteredEmployees, setFilteredEmployees] = useState([]);
	const [isActive, setIsActive] = useState("active");
	const [loading, setLoading] = useState(true);
	const [flashMessage, setFlashMessage] = useState("");
	const [flashMessageType, setFlashMessageType] = useState("");
	const [filterText, setFilterText] = useState("");

	const params = useParams();

	let employerId = params.employerId;


	useEffect(() => {
		EmployerService.getEmployerRoster(employerId).then((result) => {
			setEmployees(result.data);
		})
		.catch((error) => {
			const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

			setFlashMessage(resMessage);
			setFlashMessageType(CONSTANT.ALERT_TYPE.ERROR);
		})
		.finally(() => {
			setLoading(false);
		});
	}, [employerId]);

	useEffect(() => {
		if (filterText.length === 0) {
			setFilteredEmployees(employees);
		} else {		
			let filter = filterText.toLowerCase();
			let filteredEmployees = employees.filter((employee) => 
				(employee.firstName.toLowerCase().indexOf(filter) > -1 || employee.lastName.toLowerCase().indexOf(filter) > -1)
			);

			setFilteredEmployees(filteredEmployees);
		}
	}, [employees, filterText]);

	return (
		<Fragment>
			<ReturnHome>
				<li className="breadcrumb-item"><Link to="/employer/list">Employers</Link></li>
				<li className="breadcrumb-item"><Link to={`/employer/detail/${employerId}`}>Employer Detail</Link></li>
				<li className="breadcrumb-item active">Employees</li>
			</ReturnHome>
			<h1>Employee Roster</h1>

			{flashMessage.length > 0 && <FlashMessage message={flashMessage} messageType={flashMessageType} />}

			<Loader show={loading} />

			{employees.length === 0 &&
				<div className="alert alert-info">No employees found.</div>
			}

			
				
					<div className="row mb-3">
						{employees.length > 0 &&
							<>
								<div className="col">
									<input type="text" placeholder="Search" className="form-control" value={filterText} onChange={(e) => setFilterText(e.target.value)}  />
								</div>
							
								<div className="col">
									<select className="form-control" value={isActive} onChange={(event) => setIsActive(event.target.value)}>
										<option value="active">Active</option>
										<option value="inactive">Inactive</option>
									</select>
								</div>
							</>
						}
						<div className="col text-right">
							<Link to={`/account/detail/${params.employerId}`} className="btn btn-primary mr-3">
								Add New
							</Link>
							<Link to="/employee/upload" className="btn btn-primary">
								<FontAwesomeIcon icon={faArrowAltCircleUp} /> Upload
							</Link>
						</div>
					</div>

					<div className="row mb-5">
						<div className="col">
							Activated accounts: {employees.filter(employee => employee.activated).length} of {employees.length}
						</div>
					</div>

					<div className="list-group">
						{filteredEmployees.filter(employer => employer.active === (isActive === "active") ? true : false).map((employee) => (
							<Link 
								key={employee._id}
								to={`/account/detail/${params.employerId}/${employee._id}`} 
								className="list-group-item list-group-item-action">
								{employee.lastName}, {employee.firstName}
							</Link>
						))}
					</div>
				
			
		</Fragment>
	);
};

EmployeeList.defaultProps = {};

export default EmployeeList;
