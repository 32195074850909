import React, { useEffect, useState } from "react";
import AuthService from "../../services/auth.service";
import { useHistory } from "react-router-dom";
import validate from "./LoginValidationRules";
import useForm from "../useForm";
import FlashMessage from "../FlashMessage";
import * as CONSTANT from "../../constants/constants";

const Login = () => {
	const [flashMessage, setFlashMessage] = useState("");
	const [flashMessageType, setFlashMessageType] = useState("");
	const history = useHistory();

	const handleLogin = () => {
		AuthService.login(values.emailAddress, values.password)
			.then((response) => {
				if (response.data.token) {
					AuthService.saveProfile(response.data.profile);
					AuthService.saveToken(response.data.token);

					history.push({
						pathname: "/",
					});
					window.location.reload();
				}
			})
			.catch((error) => {
				const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
				console.error(resMessage);

				setFlashMessage(resMessage);
				setFlashMessageType(CONSTANT.ALERT_TYPE.ERROR);
			});
	};

	const { setValues, values, errors, handleChange, handleSubmit } = useForm(handleLogin, validate);

	useEffect(() => {
		if (localStorage.getItem("archer-user-username") !== null) {
			setValues({ ...values, emailAddress: localStorage.getItem("archer-user-username") });
		}
	}, []);

	return (
		<div className="row justify-content-center" id="login-container">
			<div className="col-sm-12 col-md-6">
			
			<h1>Login</h1>

			{flashMessage.length > 0 && <FlashMessage message={flashMessage} messageType={flashMessageType} />}

			<div className="form-group">
				<label>Email or Phone</label>
				<input
					type="text"
					name="emailAddress"
					className={`form-control ${errors.emailAddress && "is-invalid"}`}
					value={values.emailAddress || ""}
					onChange={handleChange}
				/>
				<div className="invalid-feedback">{errors.emailAddress}</div>
			</div>
			<div className="form-group">
				<label>Password</label>
				<input
					type="password"
					name="password"
					className={`form-control ${errors.password && "is-invalid"}`}
					value={values.password || ""}
					onChange={handleChange}
				/>
				<div className="invalid-feedback">{errors.password}</div>
			</div>
			<p>
				<a href="/forgot-password">Forgot Your Password?</a>
			</p>
			<p>
				<button type="submit" className="btn btn-primary btn-block p-2" onClick={handleSubmit}>
					LOGIN
				</button>
			</p>
			<p>
				<a href="/verify-account">New User? Verify Your Account</a>
			</p>
			<p className="alert alert-info">
				Have questions or need help? Please email <a href="mailto:tech@archerhealth.com?subject=Archer Health Website">tech@archerhealth.com</a>
			</p>
			</div>
		</div>
	);
};

Login.defaultProps = {};

export default Login;
