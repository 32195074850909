import React, { Fragment, useEffect, useState } from 'react';
import AuthService from '../../../services/auth.service';
import { Link } from "react-router-dom";
import ReturnHome from '../../Navigation/ReturnHome';

const ProviderList = ({currentUser}) => {
	const [providers, setProviders] = useState([]);
	const [filteredProviders, setFilteredProviders] = useState([]);
	const [isActive, setIsActive] = useState("active");
	const [filterText, setFilterText] = useState("");

	//let currentUser = AuthService.getCurrentUser();

	useEffect(() => {
		AuthService.getProviders().then((result) => {
			setProviders(result.data);
		});
	}, []);

	useEffect(() => {
		if (filterText.length === 0) {
			setFilteredProviders(providers);
		} else {
			let filter = filterText.toLowerCase();
			let filteredProviders = providers.filter((provider) => 
				(provider.firstName.toLowerCase().indexOf(filter) > -1 || provider.lastName.toLowerCase().indexOf(filter) > -1)
			);

			setFilteredProviders(filteredProviders);
		}
	}, [providers, filterText]);

	return (
		<Fragment>
			<ReturnHome>
				<li className="breadcrumb-item active">Providers</li>				
			</ReturnHome>

			<h1>Providers</h1>

			<div className="row mb-3">
				<div className="col"><input type="text" placeholder="Search" className="form-control" value={filterText} onChange={(e) => setFilterText(e.target.value)} /></div>
				<div className="col">
					<select className="form-control" value={isActive} onChange={(event) => setIsActive(event.target.value)}>
						<option value="active">Active</option>
						<option value="inactive">Inactive</option>
					</select>
				</div>
				<div className="col text-right">
					<Link to="/provider/detail" className="btn btn-primary">Add New</Link>
				</div>
			</div>
			
			<div className="list-group">
				{filteredProviders.filter(provider => provider.active === (isActive === "active") ? true : false).map((provider) => (
					<Link to={`/provider/detail/${provider._id}`} className="list-group-item list-group-item-action" key={provider._id}>
						{provider.firstName}{" "}
						{provider.lastName}
					</Link>
				))}
			</div>
			
		</Fragment>
	);
}

ProviderList.defaultProps = {}

export default ProviderList;