import React from 'react';
import { Modal } from "react-bootstrap";

const ModalHelper = (props) => {
    return (
        <Modal backdrop="static" show={props.show} centered>
            <Modal.Body>
                {props.children}
            </Modal.Body>
        </Modal>
    );
}

ModalHelper.defaultProps = {
    show: false
}

export default ModalHelper;