import { useState, useEffect, useCallback } from "react";

const useForm = (callback, validate) => {
	const [values, setValues] = useState({});
	const [errors, setErrors] = useState({});
	const [isSubmitting, setIsSubmitting] = useState(false);

	const userCallback = useCallback(() => {
		callback();
	}, [callback]);

	useEffect(() => {
		if (Object.keys(errors).length === 0 && isSubmitting) {
			userCallback();
			setIsSubmitting(false);
		}
	}, [errors, userCallback, isSubmitting]);

	const handleSubmit = (event) => {
		if (event) event.preventDefault();
		setErrors(validate(values));
		setIsSubmitting(true);
	};

	const handleChange = (event) => {
		event.persist();

		if (
			event.target.name === "phone" || 
			event.target.name === "reminderPhone" || 
			event.target.name === "requestContactPhone" || 
			event.target.name === "mobile") {
				
			let inputValue = event.target.value;

			if (!inputValue) return inputValue;

			//clean any non-digits
			let number = inputValue.replace(/[^\d]/g, "");

			const numberLength = number.length;

			
			if (numberLength < 4) {
				number = inputValue;
			}
			else if (numberLength < 7) {
				number = `(${number.slice(0,3)}) ${number.slice(3)}`;
			} else { number = `(${number.slice(0, 3)}) ${number.slice(3, 6)}-${number.slice(6,10)}`;}

			//Contact Information
			if (event.target.name === "phone") {
				setValues((values) => ({ ...values, phone: number }));
			}

			//Book appointment: confirm appointment component
			if (event.target.name === "reminderPhone") {
				setValues((values) => ({ ...values, reminderPhone: number }));
			}

			//Book appointment: confirm on demand component
			if (event.target.name === "requestContactPhone") {
				setValues((values) => ({ ...values, requestContactPhone: number }));
			}

			//Account detail
			if (event.target.name === "mobile") {
				setValues((values) => ({...values, mobile: number }));
			}
		} else if (event.target.name ===  "cardNumber") {
			let cardNumber = event.target.value;

			if (isNaN(cardNumber)) return;

			if (cardNumber.length > 16) {
				cardNumber = cardNumber.substring(0, 16);
			}

			setValues((values) => ({ ...values, cardNumber: cardNumber }));
		} else if (event.target.name === "cvv") {
			if (!event.target.value) return;
			let cvv;

			cvv = event.target.value;

			if (event.target.value.length > 3) {
				cvv = cvv.substring(0, 3);
			}

			setValues((values) => ({ ...values, cvv: cvv }));
		} else if (event.target.name === "mailingAddress.address1") {
			setValues((values) => ({ ...values, mailingAddress: { ...values.mailingAddress, address1: event.target.value } }));
		} else if (event.target.name === "mailingAddress.address2") {
			setValues((values) => ({ ...values, mailingAddress: { ...values.mailingAddress, address2: event.target.value } }));
		} else if (event.target.name === "mailingAddress.city") {
			setValues((values) => ({ ...values, mailingAddress: { ...values.mailingAddress, city: event.target.value } }));
		} else if (event.target.name === "mailingAddress.state") {
			setValues((values) => ({ ...values, mailingAddress: { ...values.mailingAddress, state: event.target.value } }));
		} else if (event.target.name === "mailingAddress.zipCode") {
			let zipCode = event.target.value;

			if (isNaN(zipCode)) return;

			if (zipCode.length > 5) {
				zipCode = zipCode.substring(0, 5);
			}

			setValues((values) => ({ ...values, mailingAddress: { ...values.mailingAddress, zipCode: zipCode } }));
		} else {
			setValues((values) => ({ ...values, [event.target.name]: event.target.value }));
		}
	};

	return {
		setValues,
		handleChange,
		handleSubmit,
		values,
		errors,
	};
};

export default useForm;
