import React, { Fragment } from "react";
import AuthService from "../../services/auth.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import AppointmentService from "../../services/appointment.service";

const Navigation = ({ currentUser }) => {
	const handleLogout = (event) => {
		event.preventDefault();

		AppointmentService.clearSessionAppointment();
		AuthService.logout();

		window.location.replace("/login");
	};

	return (
		<Fragment>
			{!currentUser && (
				<p className="text-center">
					<img src="/img/Logo-Archer-Health.png" alt="Archer Health" className="img-fluid" style={{ maxWidth: "350px" }} />
				</p>
			)}
			{/* removed expand class to keep as hamburger 
			 <nav className="navbar navbar-expand-lg bg-white navbar-light fixed-top"> */}

			{currentUser && (
				<nav className="navbar bg-white navbar-light fixed-top">
					<div className="container">
						<Link to="/" className="navbar-brand">
							<img src="/img/Logo-Archer-Health.png" alt="Archer Health" width="150" />
						</Link>
						<button
							className="navbar-toggler"
							type="button"
							data-toggle="collapse"
							data-target="#navbarMenuItems"
							aria-controls="navbarMenuItems"
							aria-expanded="false"
							aria-label="Toggle navigation">
							<span className="navbar-toggler-icon"></span>
						</button>

						<div className="collapse navbar-collapse justify-content-end" id="navbarMenuItems">
							<ul className="navbar-nav">
								{currentUser && (
									<Fragment>
										<li className="nav-item text-right">
											<span>
												<FontAwesomeIcon icon={faUserCircle} /> {currentUser.firstName} {currentUser.lastName}
											</span>
										</li>
										{/* Do not show payment methods for admins, providers, or users with no-cost plans */}
										{(["admin", "provider"].includes(currentUser.role) || currentUser.pricingTier !== "0001") && (
											<li className="nav-item text-right">
												<Link to="/payment-methods" className="nav-link">
													Payment Methods
												</Link>
											</li>
										)}
										{currentUser.role === "employer" &&
											<li className="nav-item text-right">
												<Link to={`/employee/list/${currentUser.employer._id}`} className="nav-link">Manage Employees</Link>
											</li>
										}
										{currentUser.role === "provider" &&
											<li className="nav-item text-right">
												<Link to="/clinic/schedule/block" className="nav-link">Clinic Schedule</Link>
											</li>
										}
										<li className="nav-item text-right">
											<Link to="/contact" className="nav-link">
												Contact Information
											</Link>
										</li>
										<li className="nav-item text-right">
											<Link to="/change-password" className="nav-link">
												Password
											</Link>
										</li>
										<li className="nav-item text-right">
											<a className="nav-link" onClick={handleLogout} id="link-pointer">
												Logout
											</a>
										</li>
									</Fragment>
								)}
							</ul>
						</div>
					</div>
				</nav>
			)}
		</Fragment>
	);
};

Navigation.defaultProps = {};

export default Navigation;
