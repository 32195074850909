export default function validate(values) {
    let errors = {};

    if (!values.name) {
        errors.name = "Telehealth group name required.";
    }

    if (!values.state) {
        errors.companyCode = "Telehealth group state is required.";
    }

    return errors;
};