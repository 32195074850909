import React, { useState, useEffect, Fragment } from "react";
import AppointmentList from "../../../Appointment/List";
import { format } from "date-fns";
import add from "date-fns/add";
import "react-nice-dates/build/style.css";
import { utcToZonedTime } from "date-fns-tz";
import AppointmentService from "../../../../services/appointment.service";
import FlashMessage from "../../../FlashMessage";
import * as CONSTANT from "../../../../constants/constants";
import RequestList from "../../../Request/List";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import TelehealthGroupService from "../../../../services/telehealthgroups.service";
import MasterAppointmentList from "../../../Appointment/List/master";
import moment from "moment";

const ClinicAppointmentList = ({ currentUser }) => {
	const [appointmentType, setAppointmentType] = useState("on-site");
	const [flashMessage, setFlashMessage] = useState("");
	const [flashMessageType, setFlashMessageType] = useState("");

	const [clinicAppointments, setClinicAppointments] = useState([]);
	const [telehealthRequests, setTelehealthRequests] = useState([]);
	const [allAppointments, setAllAppointments] = useState([]);

	const [locations, setLocations] = useState([]);
	const [telehealthGroups, setTelehealthGroups] = useState([]);

	const [selectedLocation, setSelectedLocation] = useState("");
	const [filteredClinicAppointments, setFilteredClinicAppointments] = useState([]);

	const [selectedTelehealthGroup, setSelectedTelehealthGroup] = useState("");
	const [filteredRequests, setFilteredRequests] = useState([]);

	const [fromDate, setFromDate] = useState(moment().format("YYYY-MM-DD"));

	useEffect(() => {
		if (currentUser.role !== "admin") return;

		AppointmentService.getAll()
			.then((res) => {
				setAllAppointments(res.data);
			})
			.catch((error) => {
				const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

				setFlashMessage(resMessage);
				setFlashMessageType(CONSTANT.ALERT_TYPE.ERROR);
			});
	}, [currentUser.role]);

	useEffect(() => {
		//let fromDate = format(utcToZonedTime(new Date(), "America/Chicago"), "yyyy-MM-dd");
		let toDate = format(utcToZonedTime(add(new Date(), { months: 3 }), "America/Chicago"), "yyyy-MM-dd");

		//Get appointments for date range
		AppointmentService.getAppointments(fromDate, toDate)
			.then((res) => {
				let appointments = [];

				if (res.data && res.data.length > 0) {
					res.data.forEach((appointment) => {						
						
						if (appointment.employer.calendars) {
							appointment.employer.calendars.forEach((calendar) => {
								//show appointments only assigned to this provider's location, or if admin role
								if (
									calendar.calendarID === appointment.location.calendarID &&
									(calendar.providers.includes(currentUser._id) || currentUser.role === "admin")
								) {
									appointments.push(appointment);
								}
							});
						}
					});

					setClinicAppointments(appointments);
				}
			})
			.catch((error) => {
				const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

				setFlashMessage(resMessage);
				setFlashMessageType(CONSTANT.ALERT_TYPE.ERROR);
			});
	}, [currentUser._id, currentUser.role, fromDate]);

	useEffect(() => {
		AppointmentService.getRequests()
			.then((res) => {
				setTelehealthRequests(res.data);
			})
			.catch((error) => {
				const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

				setFlashMessage(resMessage);
				setFlashMessageType(CONSTANT.ALERT_TYPE.ERROR);
			});
	}, []);

	useEffect(() => {
		//Only admin can see all locations, so don't load it if the user isn't admin role
		if (currentUser.role !== "admin") return;

		let locations = [];		
		AppointmentService.getAllLocations()
			.then((res) => {
				res.data.forEach((employer) =>
					employer.calendars.forEach((calendar) =>
						locations.push({
							calendarID: calendar.calendarID,
							location: calendar.location,
						})
					)
				);
				setLocations(locations);
			})
			.catch((error) => {
				const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

				setFlashMessage(resMessage);
				setFlashMessageType(CONSTANT.ALERT_TYPE.ERROR);
			});
	}, [currentUser.role]);

	useEffect(() => {
		TelehealthGroupService.getTelehealthGroups().then((res) => {
			setTelehealthGroups(res.data);
		});
	}, []);

	useEffect(() => {
		if (selectedLocation.length === 0) {
			setFilteredClinicAppointments(clinicAppointments);
		} else {
			let filteredAppointments = clinicAppointments.filter((appointment) => appointment.location.calendarID === selectedLocation);
			setFilteredClinicAppointments(filteredAppointments);
		}
	}, [clinicAppointments, selectedLocation]);

	useEffect(() => {
		if (selectedTelehealthGroup.length === 0) {
			setFilteredRequests(telehealthRequests);
		} else {
			let filteredReqs = telehealthRequests.filter((request) => request.state === selectedTelehealthGroup);
			setFilteredRequests(filteredReqs);
		}
	}, [telehealthRequests, selectedTelehealthGroup]);

	return (
		<div id="clinic-appointment-list">
			<h1>Welcome</h1>

			<nav className="nav nav-fill py-3">
				<a className={`nav-link ${appointmentType === "on-site" && "active"}`} onClick={() => setAppointmentType("on-site")}>
					Your Clinics
				</a>
				<a className={`nav-link ${appointmentType === "on-demand" && "active"}`} onClick={() => setAppointmentType("on-demand")}>
					Your Telehealth
				</a>
				{currentUser?.role === "admin" && (
					<a className={`nav-link ${appointmentType === "master" && "active"}`} onClick={() => setAppointmentType("master")}>
						Master Appointment List
					</a>
				)}
			</nav>

			{flashMessage.length > 0 && <FlashMessage message={flashMessage} messageType={flashMessageType} />}

			<div className="row">
				<div className="col">
					<p>						
						<strong>Showing appointments {moment(fromDate).format("dddd, MMMM Do YYYY")}</strong>
						<input style={{border: 'none'}} type="date" value={fromDate} onChange={(e) => setFromDate(e.target.value)} className="ml-3" />
					</p>
				</div>
			</div>

			{/* On Site Clinic Appointments List */}
			{appointmentType === "on-site" && (
				<Fragment>
					{currentUser?.role === "admin" && (
						<select
							className="form-control"
							value={selectedLocation}
							onChange={(event) => setSelectedLocation(parseInt(event.target.value) || "")}>
							<option value="">All Locations</option>
							{locations.map((location, index) => (
								<option value={location.calendarID} key={index}>
									{location.location}
								</option>
							))}
						</select>
					)}
					<AppointmentList appointments={filteredClinicAppointments} />
				</Fragment>
			)}

			{/* Telehealth List */}
			{appointmentType === "on-demand" && (
				<Fragment>
					<h5 className="mt-3">Open Requests</h5>

					<select
						className="form-control"
						value={selectedTelehealthGroup}
						onChange={(event) => setSelectedTelehealthGroup(event.target.value)}>
						<option value="">All Telehealth Groups</option>
						{telehealthGroups.map((group) => (
							<option value={group.state} key={group.state}>
								{group.name}
							</option>
						))}
					</select>

					{telehealthRequests.length > 0 ? (
						<RequestList telehealthRequests={filteredRequests.filter((request) => request.status === "processing")} />
					) : (
						<p>No open telehealth requests</p>
					)}
				</Fragment>
			)}

			{/* Master Appointment List */}
			{appointmentType === "master" && (
				<Fragment>
					<MasterAppointmentList appointments={allAppointments} />
				</Fragment>
			)}

			<p className="mt-3">
				<Link to="/book-appointment/proxy" className="btn btn-primary btn-block">
					<FontAwesomeIcon icon={faPlusCircle} /> Add New Scheduled Appointment
				</Link>
			</p>

			<p className="mt-3">
				<Link to="/book-appointment/walkin" className="btn btn-primary btn-block">
					<FontAwesomeIcon icon={faPlusCircle} /> Add New Walk-In Appointment
				</Link>
			</p>

			{currentUser?.role === "provider" && (
				<p className="mt-3">
					<Link to="/clinic/schedule/block" className="btn btn-secondary btn-block">Manage Clinic Availability</Link>
				</p>
			)}
			{/*
			<pre>
				{JSON.stringify(clinicAppointments, null, 2)}
			</pre>
			*/}
		</div>
	);
};

ClinicAppointmentList.defaultProps = {};

export default ClinicAppointmentList;
