import API from "./API";

const saveAppointmentBiometrics = async (body) => {
    return await API.post(process.env.REACT_APP_API_BASE_URL + "/biometrics/screening/", body)
};

const getAppointmentBiometrics = async(appointmentID) => {
    return await API.get(process.env.REACT_APP_API_BASE_URL + "/biometrics/screening/" + appointmentID);
}

const getAllScreenings = async () => {
    return await API.get(process.env.REACT_APP_API_BASE_URL + "/biometrics/all");
}

const getScreeningsByEmployer = async(employerID) => {
    return await API.get(process.env.REACT_APP_API_BASE_URL + "/biometrics/employer/" + employerID);
}

const getScreeningsFromAccountID = async(accountID, patientID = null) => {
    if (patientID === null) {
        return await API.get(process.env.REACT_APP_API_BASE_URL + "/biometrics/account/" + accountID);
    }
    return await API.get(process.env.REACT_APP_API_BASE_URL + "/biometrics/account/" + accountID + "/" + patientID);
}

const BiometricsService = {
    saveAppointmentBiometrics,
    getAppointmentBiometrics,
    getAllScreenings,
    getScreeningsByEmployer,
    getScreeningsFromAccountID
}

export default BiometricsService; 