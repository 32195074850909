import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import moment from "moment";

const ProviderNotes = ({ values, errors, handleChange, handleSubmit, appointmentId, providerNotes }) => {
	return (
		<Fragment>
			<p>You may add any notes to this appointment. 
				Notes will be visible to other providers for future reference, but <strong>will not</strong> be visible to patients.</p>

			<div className="form-group">
				<textarea
					name="providerNotes"
					className={`form-control ${errors.providerNotes && "is-invalid"}`}
					placeholder="Provider notes (optional)"
					value={values.providerNotes || ""}
					onChange={handleChange}
					rows="5"></textarea>
				<div className="invalid-feedback">{errors.providerNotes}</div>
			</div>
			<button className="btn btn-primary btn-block mb-5" onClick={handleSubmit} disabled={!values.providerNotes}>
				Save Notes
			</button>

			<div id="provider-notes">
				{/* An appointment may contain multiple note sets. 
					If the note is from a previous telehealth appointment, add a 
					link to the appointment details.
				*/}
				{providerNotes?.map((appointmentNote, index) => (
					<Fragment key={index}>
						{appointmentNote.notes.map((note) => (
							<div className="card shadow-sm mb-3" key={note._id}>
								<div className="card-header">{moment(note.noteDate).format("MM/DD/YYYY")} {note.provider.fullName} wrote:</div>
								<div className="card-body">									
									{note.providerNotes}

									{appointmentNote.appointmentId !== appointmentId && (
										<p className="card-text mt-3">
											<small>This note is for a prior appointment. </small>
											<Link
												to={`/appointment/detail/${appointmentNote.appointmentId}`}
												key={appointmentNote.appointmentNoteId}>
												<small>View Appointment Detail</small>
											</Link>
										</p>
									)}
								</div>
							</div>
						))}
					</Fragment>
				))}
			</div>
		</Fragment>
	);
};

ProviderNotes.defaultProps = {};

export default ProviderNotes;