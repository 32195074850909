import React, { Fragment } from "react";
//import HomeBookAppointment from "../../components/Home/BookAppointment";
import { Link } from "react-router-dom";
//import CTABlock from "../../components/CTABlock";


const SuperadminContainer = () => {
	return (
		<Fragment>
			

			<div className="list-group shadow-sm">
				<Link to="/employer/list" className="list-group-item list-group-item-action">Manage Employers</Link>

				<Link to="/provider/list" className="list-group-item list-group-item-action">Manage Providers</Link>

				<Link to="/telehealthgroup/list" className="list-group-item list-group-item-action">Manage Telehealth Groups</Link>

				<Link to="/clinic/appointments" className="list-group-item list-group-item-action">Appointments</Link>
			</div>

			
			{/*
			<div className="row">
				<div className="col-md-6">
					<CTABlock CTATitle="Manage Employers" button1Title="Manage Employers" button1LinkTo="/employer/list">
						Add and edit employers, including:
						<ul>
							<li>Employees</li>
							<li>Clinics</li>
							<li>Utilization Reports</li>
						</ul>
					</CTABlock>
				</div>

				<div className="col-md-6">
					<CTABlock CTATitle="Manage Providers" button1Title="Manage Providers" button1LinkTo="/provider/list">
						Create or edit provider accounts
					</CTABlock>
				</div>
			</div>

			<div className="row">
				<div className="col-md-6">
					<CTABlock CTATitle="Manage Telehealth Groups" button1Title="Manage Telehealth Groups" button1LinkTo="/telehealthgroup/list">
						Create or edit telehealth groups and assign providers
					</CTABlock>
				</div>

				<div className="col-md-6">
					<CTABlock CTATitle="Manage Schedules" button1Title="Manage Schedules" button1LinkTo="/clinic/appointments">
						Get a comprehensive view of your clinic schedules and telehealth requests
					</CTABlock>
				</div>
			</div>
			*/}
		</Fragment>
	);
};

SuperadminContainer.defaultProps = {};

export default SuperadminContainer;
