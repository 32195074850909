import React, { Fragment, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";

const MedicalHistoryMedications = ({ appointmentMedications, handleSetAppointmentMedications, handleNextClick }) => {
	const [medications, setMedications] = useState([{ name: "", howLong: "", notes: "" }]);
	const [noSelection, setNoSelection] = useState(false);

	useEffect(() => {
		if (appointmentMedications) {
			setMedications(appointmentMedications);
		}
	}, [appointmentMedications]);

	const addMedication = () => {
		const medicationList = medications.concat({ name: "", howLong: "" });
		handleSetAppointmentMedications(medicationList);
	};

	function handleChange(index, event) {
		const values = [...medications];
		if (event.target.name === "medicationName") {
			values[index].name = event.target.value;
		}

		if (event.target.name === "medicationLength") {
			values[index].howLong = event.target.value;
		}

		if (event.target.name === "notes") {
			values[index].notes = event.target.value;
		}

		handleSetAppointmentMedications(values);
	}

	const handleRemoveMedication = (index) => {
		const values = [...medications];
		values.splice(index, 1);
		handleSetAppointmentMedications(values);
	};

	const handleSelectNone = (event) => {
		let isChecked = event.target.checked;
		setNoSelection(isChecked);

		if (isChecked) {
			handleSetAppointmentMedications([{ name: "", howLong: "" }]);
		}
	};

	return (
		<div id="appointment-medications">
			{/*<pre>{JSON.stringify(medications, null, 2)}</pre>*/}
			<h1 className="my-3">What medications are you currently taking?</h1>
			<p className="font-italic">List all medications including over-the-counter and vitamins</p>

			<ul className="list-group my-5">
				<li className={`list-group-item ${noSelection && "active"}`}>
					<div className="form-check">
						<input type="checkbox" className="form-check-input" onChange={handleSelectNone} />{" "}
						<label className="form-check-label">I am not currently taking medication</label>
					</div>
				</li>
			</ul>

			{!noSelection && (
				<Fragment>
					{medications.map((medication, index) => (
						<AddMedication
							handleRemove={handleRemoveMedication}
							onChange={(event) => handleChange(index, event)}
							medication={medication}
							index={index}
							key={index}
						/>
					))}
					<p>
						<button className="btn btn-outline-primary btn-block" onClick={addMedication}>
							+ List Another Medication
						</button>
					</p>
				</Fragment>
			)}
			<p className="my-3">
				<button className="btn btn-primary btn-block" onClick={handleNextClick}>
					NEXT &raquo;
				</button>
			</p>
		</div>
	);
};

const AddMedication = ({ onChange, handleRemove, medication, index }) => (
	<Fragment>
		<div className="form-row" id="medication-list">
			<div className="form-group col-md-4 col-sm-12">
				<input
					type="text"
					placeholder="Medication Name"
					className="form-control"
					name="medicationName"
					value={medication.name}
					onChange={onChange}
				/>
			</div>
			<div className="form-group col-md-4 col-sm-12">
				<div className="input-group">
					<input type="text" placeholder="1x a day, as needed, etc." className="form-control" name="medicationLength" value={medication.howLong} onChange={onChange} />
				</div>
			</div>
			<div className="form-group col-md-4 col-sm-12">
				<div className="input-group">
					<input type="text" placeholder="10mg, etc." className="form-control" name="notes" value={medication.notes} onChange={onChange} />

					<div className="input-group-append">
						
						<button className="btn" onClick={() => handleRemove(index)}>
							<FontAwesomeIcon icon={faWindowClose} style={{ color: "#FC4C4C" }} />
						</button>
						
					</div>
				</div>
			</div>
		</div>
	</Fragment>
);

MedicalHistoryMedications.defaultProps = {};

export default MedicalHistoryMedications;
