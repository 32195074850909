import React, { Fragment, useEffect, useState } from "react";
import TelehealthGroupService from "../../../../services/telehealthgroups.service";
import { Link } from "react-router-dom";
import ReturnHome from "../../../Navigation/ReturnHome";

const TelehealthGroupList = () => {
	const [groups, setGroups] = useState([]);

	useEffect(() => {
		TelehealthGroupService.getTelehealthGroups().then((response) => {
			const groups = response.data;

			setGroups(groups);
		});
	}, []);

	return (
		<Fragment>
			<ReturnHome>
				<li className="breadcrumb-item">Telehealth Groups</li>
			</ReturnHome>
			
			<h1>Telehealth Groups</h1>

			<div className="row mb-5">
				<div className="col text-right">
					<Link to="/telehealthgroup/detail" className="btn btn-primary">
						Add New
					</Link>
				</div>
			</div>

			<div className="list-group">
				{groups.map((group) => (
					<Link to={`/telehealthgroup/detail/${group._id}`} key={group._id} className="list-group-item list-group-item-action">
						{group.name}
					</Link>
				))}
			</div>
		</Fragment>
	);
};

TelehealthGroupList.defaultProps = {};

export default TelehealthGroupList;
