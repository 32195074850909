import React from 'react';
import { Link } from 'react-router-dom';

const ReturnHome = (props) => {    
    return (
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                {props.children}
            </ol>
        </nav>        
    );
}

ReturnHome.defaultProps = {}

export default ReturnHome;