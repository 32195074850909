export default function validate(values) {
    
    let errors = {};

    if (!values.requestContactPhone) {
        errors.requestContactPhone = "Required for provider to contact you"
    }

    if (values.requestContactPhone && (values.requestContactPhone.length < 10)) {
        errors.requestContactPhone = "Invalid phone number"
    }

    if (!values.requestState) {
        errors.requestState = "Required";
    }

    if (values.requestState && values.requestState.length < 2) {
        errors.requestState = "Required";
    }

    return errors;
};