export default function validate(values) {
    let errors = {};

    if (!values.firstName) {
        errors.firstName = "Required";
    }

    if (!values.lastName) {
        errors.lastName = "Required";
    }

    if (!values.birthday) {
        errors.birthday = "Required.";
    }

    if (!values.employee_id) {
        errors.employee_id = "Required";
    }

    if (!values.email && !values.mobile) {
        errors.mobile = "Email or mobile is required.";
        errors.email = "Email or mobile is required.";
    }

    if (values.email && values.email.indexOf("@") < 0) {
        errors.email = "Invalid email.";
    }

    return errors;
};