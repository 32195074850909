import React, { Fragment, useState, useEffect } from "react";
import moment from "moment";
import AppointmentService from "../../../services/appointment.service";
//import AuthService from '../../../services/auth.service';
import { nanoid } from "nanoid";
//import { DatePickerCalendar } from "react-nice-dates"; //https://reactnicedates.hernansartorio.com/#installation
import "react-nice-dates/build/style.css";
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
//import EmployerService from "../../../services/employer.service";
//import FlashMessage from "../../FlashMessage";
//import * as CONSTANT from '../../../constants/constants';
import PropTypes from 'prop-types';
import Loader from "../../UIHelpers/Loader";
import CalendarService from "../../../services/calendar.service";
import { getTimezoneName, TIMEZONE } from "../../../constants/constants";

const AppointmentDateTime = ({	
	appointmentDateTime,
	appointmentTypeID,
	appointmentLocation,
	employer,
	currentUser,
	//handleSetAppointmentCalendarID,
	handleSetAppointmentLocation,
	handleSetAppointmentDateTime,
	handleNextClick,
	showHeader
}) => {
	//appointmentdatetime comes from book appointment parent
	//handle next click continues to next after date and time are selected
	//handleSetAppointmentDateTime will set date and time in Book Appointment parent component

	const [selectedAppointmentDateTime, setSelectedAppointmentDateTime] = useState(null);
	const [selectedAppointmentLocation, setSelectedAppointmentLocation] = useState({ calendarID: "", location: "" });

	//const [employer, setEmployer] = useState("");
	const [availableDates, setAvailableDates] = useState([]);
	const [availableTimes, setAvailableTimes] = useState([]);
	const [loading, setLoading] = useState(false);
	//const [flashMessage, setFlashMessage] = useState("");
	//const [flashMessageType, setFlashMessageType] = useState("");

	const timeZone = TIMEZONE.CENTRAL; //Default timezone is central "America/Chicago";

	const [selectedDate, setSelectedDate] = useState(null);
	const [calendars, setCalendars] = useState([]);
	const [calendarTimeZone, setCalendarTimeZone] = useState(TIMEZONE.CENTRAL);

	useEffect(() => {
		CalendarService.getCalendars()
			.then((res) => {
				setCalendars(res.data);
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	//let currentUser = AuthService.getCurrentUser();

	
	//useEffect(() => {		
	//	setLoading(true);

	//	EmployerService.getEmployer(currentUser?.employer._id).then((res) => {		
	//		setEmployer(res.data);
	//		setLoading(false);
	//	})
	//	.catch((error) => {
	//		const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
	//
	//			setFlashMessage(resMessage);
	//			setFlashMessageType(CONSTANT.ALERT_TYPE.ERROR);
	//		});
	//	}, [currentUser?.employer._id])


	useEffect(() => {
		if (appointmentDateTime) {
			setSelectedAppointmentDateTime(appointmentDateTime);
			setSelectedDate(format(utcToZonedTime(appointmentDateTime, timeZone), "yyyy-MM-dd"));
		}
	}, [appointmentDateTime, timeZone]);

	useEffect(() => {
		if (appointmentLocation) {
			setSelectedAppointmentLocation(appointmentLocation);
		}
	}, [appointmentLocation]);

	useEffect(() => {
		//service gets the next 60 days of available appointments for the user's employer calendars
		if (appointmentLocation?.calendarID) {
			setLoading(true);

			AppointmentService.getAvailableDatesByCalendarAndAppointmentTypeId(appointmentLocation.calendarID, employer._id, appointmentTypeID).then((response) => {
				setLoading(false);
				setAvailableDates(response.data);
			});

		}
		//TODO: error catch
	}, [appointmentLocation, employer, appointmentTypeID]);

	const handleSelectDate = (availableDate) => {		
		setSelectedDate(availableDate.date);
	};

	const handleSelectLocation = (calendar) => {
		//handleSetAppointmentCalendarID(calendar.calendarID);
		//handleSetAppointmentLocation(calendar.location);
		handleSetAppointmentLocation(calendar);
	};

	useEffect(() => {
		if (
			selectedDate &&
			selectedDate.length > 0 &&
			selectedAppointmentLocation.calendarID !== null &&
			selectedAppointmentLocation.calendarID !== ""
		) {
			setLoading(true);

			AppointmentService.getAvailableTimesByAppointmentTypeId(selectedAppointmentLocation.calendarID, selectedDate, appointmentTypeID).then(
				(response) => {
					setAvailableTimes(response.data);
					setCalendarTimeZone(calendars.find((calendar) => calendar.id === selectedAppointmentLocation.calendarID)?.timezone || timeZone);
					setLoading(false);
				}
			);
		}
	}, [selectedAppointmentLocation, selectedDate, appointmentTypeID, calendars, timeZone]);

	const handleChangeAppointment = () => {
		//handleSetAppointmentCalendarID(null);
		handleSetAppointmentLocation(null);
		handleSetAppointmentDateTime(null);
		setSelectedDate(null);
		setSelectedAppointmentDateTime(null);
	};

	return (
		<div id="appointment-date-time">

			{showHeader && <h1 className="my-3">Request Date and Time</h1>}

			{/*flashMessage.length > 0 && <FlashMessage message={flashMessage} messageType={flashMessageType} />*/}
			
			<Loader show={loading} />

			{!selectedAppointmentLocation.calendarID && (
				<Fragment>
					<h5>Select Location:</h5>


					<div className="list-group">
						{employer?.calendars?.filter(calendar => calendar.active === true && calendar.allowedAppointmentTypes?.includes(appointmentTypeID)).map((calendar) => (
							<Fragment key={nanoid()}>
								{/* If the user has access to all clinic locations, display the calendar, otherwise determine if the user has access to the calendar */}
								{(currentUser?.authorizedLocations?.all  || currentUser?.authorizedLocations?.calendars.includes(calendar.calendarID))  && (
									<button
										key={calendar.location}
										className="list-group-item list-group-item-action"
										onClick={() => handleSelectLocation(calendar)}>
										<span className="card-title">{calendar.location}</span>
									</button>
								)}
							</Fragment>
						))}
					</div>
				</Fragment>
			)}

			{/* Display Selected Appointment Location */}
			{selectedAppointmentLocation.calendarID && (
				<div className="list-group">
					<div className="list-group-item list-group-item-action active">
						<span className="card-title">{selectedAppointmentLocation.location}</span>
					</div>
				</div>
			)}

			{selectedAppointmentLocation.calendarID && !selectedDate && (
				<Fragment>
					{availableDates.length > 0 && (
						<Fragment>
							<h5>Select Date:</h5>
							<div className="list-group">
								{availableDates.map((availableDate) => (
									<AppointmentDate
										key={nanoid()}
										selectedDate={selectedDate}
										availableDate={availableDate}
										selectedCalendarId={selectedAppointmentLocation.calendarID}
										handleSelectDate={() => handleSelectDate(availableDate)}
									/>
								))}
							</div>
						</Fragment>
					)}
				</Fragment>
			)}

			{/* Display Selected Appointment Date */}
			{selectedDate && (
				<div className="list-group">
					<div className="list-group-item list-group-item-action active">
						<span className="card-title">{moment(selectedDate).format("dddd, MMMM Do YYYY")}</span>
					</div>
				</div>
			)}			

			{selectedDate && !selectedAppointmentDateTime && (
				<Fragment>
					{availableTimes.length > 0 && (
						<Fragment>
							<h5 className="mt-5">Select Time:</h5>

							<div className="list-group">
								{availableTimes.map((availableTime) => (
									<AppointmentTime
										key={nanoid()}
										availableTime={availableTime}
										handleSelectTime={() => handleSetAppointmentDateTime(availableTime.time)}
										selectedAppointmentDateTime={selectedAppointmentDateTime}
										calendarTimeZone={calendarTimeZone}
									/>
								))}
							</div>
						</Fragment>
					)}
				</Fragment>
			)}

			{/* Display Selected Appointment Time */}
			{selectedAppointmentDateTime && (
				<div className="list-group">
					<div className="list-group-item list-group-item-action active">
						<span className="card-title">{format(utcToZonedTime(selectedAppointmentDateTime, calendarTimeZone), "h:mm bbbb")}</span>
					</div>
				</div>
			)}

			{selectedAppointmentLocation.calendarID && (
				<p className="py-3">
					<button className="btn btn-outline-primary btn-block" onClick={handleChangeAppointment}>
						Change?
					</button>
				</p>
			)}

			<p className="py-3">
				<button className="btn btn-primary btn-block" onClick={handleNextClick} disabled={appointmentDateTime === null}>
					NEXT &raquo;
				</button>
			</p>
		</div>
	);
};

const AppointmentDate = ({ selectedDate, handleSelectDate, availableDate, selectedCalendarId }) => (
	<button className={`list-group-item list-group-item-action ${selectedDate === availableDate.date && "active"}`} onClick={handleSelectDate}>
		<span className="card-title">
			{moment(availableDate.date).format("dddd, MMMM Do YYYY")}
			{/* TODO: This needs to be deleted, and I'm not proud of this code. It was a workaround because we ran out of calendars in Acuity and needed to use a single calendar for multiple locations. */}
			
			{/* 3/19 - Alabama - FS/HS/FM2/SP
				(selectedCalendarId === 7912465 && (availableDate.date === "2024-03-19")) &&
				<Fragment>
					<br/>
					<small>Alabama – FS/HS/FM2/SP<br/>
					Huntsville Botanical Gardens, 4747 Bob Wallace Ave SW, Huntsville, AL 35805</small>
				</Fragment>
			*/}

			{/* 3/26 - Franklin – FS/HS (includes Franklin and BG)
				(selectedCalendarId === 7912465 && availableDate.date === "2024-03-26") &&
				<Fragment>
					<br/>
					<small>Franklin - FS/HS (includes Franklin and BG)<br/>
					Williamson Co Ag Center, 4215 Long Lane, Franklin, TN  37064</small>
				</Fragment>
			*/}

		</span>
	</button>
);

const AppointmentTime = ({ handleSelectTime, availableTime, selectedAppointmentDateTime, calendarTimeZone }) => (
	<button
		className={`list-group-item list-group-item-action ${selectedAppointmentDateTime === availableTime.time && "active"}`}
		onClick={handleSelectTime}>
		<span className="card-title">{format(utcToZonedTime(availableTime.time, calendarTimeZone), "h:mm b")} {getTimezoneName(calendarTimeZone)}</span>
	</button>
)
;

AppointmentDateTime.defaultProps = {
	showHeader: true
};

AppointmentDateTime.propTypes = {
	showHeader: PropTypes.bool
}

export default AppointmentDateTime;
