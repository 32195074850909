import React, { Fragment} from 'react';
import { faExclamationCircle, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { nanoid } from "nanoid";

const BiometricsResults = ({appointmentBiometrics}) => {
    const results = Object.keys(appointmentBiometrics?.results);

    return (
        <Fragment>
            {results.map((result) => (
                <Fragment key={nanoid()}>
                    {appointmentBiometrics?.results[result]?.value &&
                        <div className="card mb-3">
                            <div className="card-header">
                                <h5>
                                    {appointmentBiometrics?.results[result].riskLevel !== "Normal" &&
                                        <span className="pr-2"><FontAwesomeIcon icon={faExclamationCircle} style={{ 'color' : 'red' }} /></span>
                                    }
                                    {appointmentBiometrics?.results[result].riskLevel === "Normal" &&
                                        <span className="pr-2"><FontAwesomeIcon icon={faCheckCircle} style={{ 'color' : 'green' }} /></span>
                                    }
                                    {appointmentBiometrics?.results[result].name}
                            </h5>
                            </div>
                            <div className="card-body">

                                    <div className="w-50 float-right bg-dark ml-5 mb-5 p-5 text-center text-white shadow-sm">
                                        <h5 className="text-white">Your Value</h5>
                                        <h2 className="text-white">{appointmentBiometrics?.results[result]?.value}</h2>
                                        <h4 className="text-white">{appointmentBiometrics?.results[result]?.riskLevel}</h4>
                                    </div>

                                <p className="card-text" dangerouslySetInnerHTML={{ __html: appointmentBiometrics?.results[result]?.overview}}></p>

                                <p className="card-text" dangerouslySetInnerHTML={{ __html: appointmentBiometrics?.results[result]?.description}}></p>

                                {(appointmentBiometrics?.results[result]?.recommendations && appointmentBiometrics?.results[result]?.recommendations !== null) &&
                                    <Fragment>
                                    <strong>Recommendations:</strong>
                                    <div dangerouslySetInnerHTML={{ __html: appointmentBiometrics?.results[result]?.recommendations}}></div>
                                    </Fragment>
                                }

                                {(appointmentBiometrics?.results[result]?.nextSteps && appointmentBiometrics?.results[result]?.nextSteps !== null) &&
                                    <Fragment>
                                    <strong>Next Steps:</strong>
                                    <ul>
                                    {appointmentBiometrics?.results[result]?.nextSteps?.map((step) => (
                                        <li className="mb-3" key={nanoid()}>
                                            <strong>{step.title}</strong><br/>
                                            <span dangerouslySetInnerHTML={{ __html: step.description}}></span>
                                        </li>
                                    ))}
                                    </ul>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    }
                </Fragment>
            ))}

        </Fragment>
    );
}

BiometricsResults.defaultProps = {}

export default BiometricsResults; 