import React, { Fragment, useState } from "react";
import useForm from "../useForm";
import { useParams } from "react-router-dom";
import validate from "./ResetPasswordValidationRules";
import FlashMessage from "../FlashMessage";
import AuthService from "../../services/auth.service";
import * as CONSTANT from "../../constants/constants";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ResetPassword = () => {
	const [inputMaskType, setInputMaskType] = useState("password");
	const [flashMessage, setFlashMessage] = useState("");
	const [flashMessageType, setFlashMessageType] = useState("");

	const params = useParams();

	const handleToggleShowPassword = () => {
		let maskType = inputMaskType === "password" ? "text" : "password";
		setInputMaskType(maskType);
	};

	const handleResetPassword = () => {
		let user = {};
		user.password = values.newPassword;

		AuthService.resetPassword(params.resetToken, user)
			.then((res) => {
				setFlashMessage(res.data.message);
				setFlashMessageType(CONSTANT.ALERT_TYPE.SUCCESS);

				setValues((values) => ({ ...values, newPassword: "", newPasswordConfirm: "" }));
			})
			.catch((error) => {
				const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
				console.error(resMessage);

				setFlashMessage(resMessage);
				setFlashMessageType(CONSTANT.ALERT_TYPE.ERROR);
			});
	};

	const { setValues, values, errors, handleChange, handleSubmit } = useForm(handleResetPassword, validate);

	return (
		<Fragment>
			<h1>Reset Password</h1>

			{flashMessage.length > 0 && <FlashMessage message={flashMessage} messageType={flashMessageType} />}

			<div className="form-group">
				<label>Password</label>
				<div className="input-group">
					<input
						type={inputMaskType}
						name="newPassword"
						className={`form-control ${errors.newPassword && "is-invalid"}`}
						value={values.newPassword || ""}
						onChange={handleChange}
					/>
					<div className="invalid-feedback">{errors.newPassword}</div>

					<div className="input-group-append">
						<div className="input-group-text">
							<a href="#" onClick={() => handleToggleShowPassword()}>
								{inputMaskType === "password" && <FontAwesomeIcon icon={faEye} />}
								{inputMaskType === "text" && <FontAwesomeIcon icon={faEyeSlash} />}
							</a>
						</div>
					</div>
				</div>
			</div>

			<div className="form-group">
				<label>Confirm</label>
				<div className="input-group">
					<input
						type={inputMaskType}
						name="newPasswordConfirm"
						className={`form-control ${errors.newPasswordConfirm && "is-invalid"}`}
						value={values.newPasswordConfirm || ""}
						onChange={handleChange}
					/>
					<div className="invalid-feedback">
						{errors.newPasswordConfirm}
						{errors.passwordMismatch}
					</div>

					<div className="input-group-append">
						<div className="input-group-text">
							<a href="#" onClick={() => handleToggleShowPassword()}>
								{inputMaskType === "password" && <FontAwesomeIcon icon={faEye} />}
								{inputMaskType === "text" && <FontAwesomeIcon icon={faEyeSlash} />}
							</a>
						</div>
					</div>
				</div>
			</div>
			<p>
				<button className="btn btn-primary" onClick={handleSubmit}>
					Reset Password
				</button>
			</p>
		</Fragment>
	);
};

ResetPassword.defaultProps = {};

export default ResetPassword;
