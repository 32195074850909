import API from "./API";


const getEmployerRoster = async (employerId) => {
    return await API.get(process.env.REACT_APP_API_BASE_URL + "/employer/roster/" + employerId);
};

const getEmployer = async (employerId) => {
    return await API.get(process.env.REACT_APP_API_BASE_URL + "/employer/employer/" + employerId);
}

const updateEmployer = async (employer) => {
    return await API.patch(process.env.REACT_APP_API_BASE_URL + "/employer/employer", employer);
}

const addEmployer = async (employer) => {
    return await API.post(process.env.REACT_APP_API_BASE_URL + "/employer/employer", employer);
}

const addEmployee = async (employee) => {
    return await API.post(process.env.REACT_APP_API_BASE_URL + "/employer/add", employee);
}

const sendSMS = async (sms) => {
    return await API.post(process.env.REACT_APP_API_BASE_URL + "/employer/sms", sms);
}

const EmployerService = {
    getEmployerRoster,
    getEmployer,
    updateEmployer,
    addEmployer,
    addEmployee,
    sendSMS,
}

export default EmployerService;