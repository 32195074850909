import { nanoid } from "nanoid";
import React, { Fragment, useEffect, useState } from "react";

const AppointmentProduct = ({ appointmentProduct, products, employer, handleSetAppointmentProduct, handleNextClick }) => {
	const [selectedProduct, setSelectedProduct] = useState({});

	useEffect(() => {
		if (appointmentProduct) {
			setSelectedProduct(appointmentProduct);
		}
	}, [appointmentProduct]);

	return (
		
		<Fragment>
			<div id="appointment-product">
				<h1 className="my-3">
					Reason for Visit
				</h1>

				<div className="list-group">
					{products.map((product) => (
						<Fragment key={nanoid()}>
							{employer?.permissions?.includes(product.productID) && (
								<button
									onClick={(e) => handleSetAppointmentProduct(product, e)}
									className={`shadow-sm list-group-item list-group-item-action ${selectedProduct._id === product._id && "active"}`}
									key={product._id}>
									<span className="card-title">{product.name}</span>
									<br />
									<span className="card-text">{product.description}</span>
								</button>
							)}
						</Fragment>
					))}
				</div>

				<p className="mt-5">
					<button className="btn btn-primary btn-block" onClick={handleNextClick} disabled={!selectedProduct._id}>
						Next &raquo;
					</button>
				</p>
			</div>
		</Fragment>
		
	);
};

AppointmentProduct.defaultProps = {};

export default AppointmentProduct;
