import React, { Fragment, useEffect, useState } from "react";
import AppointmentService from "../../../services/appointment.service";
import CheckboxGroup from "react-checkbox-group";
//import differenceInYears from "date-fns/differenceInYears";
//import parseISO from "date-fns/parseISO";

const CPTCodes = ({ appointment, handleSaveCPTCodes }) => {
	const [cptCodes, setCptCodes] = useState([]);
	const [filteredCptCodes, setFilteredCptCodes] = useState([]);
	const [selectedCptCodes, setSelectedCptCodes] = useState([]);
	const [serviceAddons, setServiceAddons] = useState("");

	//const patientAge = differenceInYears(new Date(), parseISO(appointment.patient.birthday));

	useEffect(() => {
		setCptCodes(AppointmentService.serviceCodes);
		setFilteredCptCodes(AppointmentService.serviceCodes);
		setSelectedCptCodes(appointment.serviceCodes);
		setServiceAddons(appointment.serviceAddons);
	}, [appointment]);

	return (
		<Fragment>
			<div className="row mb-5">
				<div className="col-9">
					<h4>Select Services Performed:</h4>
					{cptCodes.length > 0 && (
						<Fragment>
							<input type="text" className="form-control mb-3" placeholder="Search..."
								  onChange={(e) => setFilteredCptCodes(cptCodes.filter((cptCode) => (cptCode.description.toLowerCase().includes(e.target.value.toLowerCase()) || cptCode.code.toLowerCase().includes(e.target.value))))} />
							<button
								className="btn btn-secondary"
								onClick={() => handleSaveCPTCodes(selectedCptCodes, serviceAddons)}
								disabled={selectedCptCodes.length < 1}>
								Save Selected Services
							</button>
							<CheckboxGroup name="cptCodes" value={selectedCptCodes}
										onChange={setSelectedCptCodes}>
								{(Checkbox) => (
									<Fragment>
										{filteredCptCodes.map(
											(cptCode) =>

												<div className="form-check mb-5" key={cptCode.code}>
													<Checkbox className="form-check-input"
															value={cptCode.code} />
													<label className="form-check-label">
														<small>
															<strong>{cptCode.code} {cptCode.short}</strong>
														</small>{" "}
														{cptCode.description}
													</label>
												</div>
										)}
									</Fragment>
								)}
							</CheckboxGroup>
						</Fragment>
					)}
				</div>
				<div className="col-3">
					{selectedCptCodes.length > 0 && (
						<Fragment>
							<h4>Selected Codes:</h4>
							{selectedCptCodes.map((code) => (
								<p key={code}>{code}</p>
							))}
						</Fragment>
					)}
				</div>
			</div>

			{/*
			<CheckboxGroup name="cptCodes" value={selectedCptCodes} onChange={setSelectedCptCodes}>
				{(Checkbox) => (
					<Fragment>
						{cptCodes.map(
							(cptCode) =>
								cptCode.modalities.includes(appointment.modality) &&
								cptCode.minAge <= patientAge &&
								cptCode.maxAge >= patientAge && (
									<div className="form-check mb-3" key={cptCode.code}>
										<Checkbox className="form-check-input" value={cptCode.code} />
										<label className="form-check-label">
											<small>
												(<strong>{cptCode.code}</strong>)
											</small>{" "}
											{cptCode.description}
										</label>
									</div>
								)
						)}
					</Fragment>
				)}
			</CheckboxGroup>
			*/}

			<div className="row mb-5">
				<div className="col">
					<label className="form-label">Please describe any additional services performed during this
						visit:</label>
					<textarea className="form-control" onChange={(e) => setServiceAddons(e.target.value)}
							value={serviceAddons || ""}></textarea>
				</div>
			</div>

			<div className="row mb-5">
				<div className="col">
					<button
						className="btn btn-secondary"
						onClick={() => handleSaveCPTCodes(selectedCptCodes, serviceAddons)}
						disabled={selectedCptCodes.length < 1}>
						Save Selected Services
					</button>
				</div>
			</div>
		</Fragment>
	);
};

CPTCodes.defaultProps = {};

export default CPTCodes;
