import React, { Fragment } from "react";

const AddressForm = ({ handleChange, values, errors }) => {
	return (
		<Fragment>
			<div className="form-group">
				<label>Mailing address</label>
				<input
					type="text"
					className="form-control"
					name="mailingAddress.address1"
					value={values.mailingAddress?.address1 || ""}
					onChange={handleChange}
				/>
			</div>

			<div className="form-group">
				<label>Suite / apartment / unit number (optional)</label>
				<input
					type="text"
					className="form-control"
					name="mailingAddress.address2"
					value={values.mailingAddress?.address2 || ""}
					onChange={handleChange}
				/>
			</div>

			<div className="form-group">
				<label>City</label>
				<input
					type="text"
					className="form-control"
					name="mailingAddress.city"
					value={values.mailingAddress?.city || ""}
					onChange={handleChange}
				/>
			</div>

			<div className="form-group">
				<label>State</label>
				{/* 
				<input
					type="text"
					className="form-control"
					name="mailingAddress.state"
					value={values.mailingAddress?.state || ""}
					onChange={handleChange}
				/>
				*/}
				<select name="mailingAddress.state" className={`form-control ${errors.mailingAddress?.state && "is-invalid"}`} value={values.mailingAddress?.state || ""} onChange={handleChange}>
					<option value="">Select</option>
					<option value="AL">Alabama</option>
					<option value="AK">Alaska</option>
					<option value="AZ">Arizona</option>
					<option value="AR">Arkansas</option>
					<option value="CA">California</option>
					<option value="CO">Colorado</option>
					<option value="CT">Connecticut</option>
					<option value="DE">Delaware</option>
					<option value="FL">Florida</option>
					<option value="GA">Georgia</option>
					<option value="HI">Hawaii</option>
					<option value="ID">Idaho</option>
					<option value="IL">Illinois</option>
					<option value="IN">Indiana</option>
					<option value="IA">Iowa</option>
					<option value="KS">Kansas</option>
					<option value="KY">Kentucky</option>
					<option value="LA">Louisiana</option>
					<option value="ME">Maine</option>
					<option value="MD">Maryland</option>
					<option value="MA">Massachusetts</option>
					<option value="MI">Michigan</option>
					<option value="MN">Minnesota</option>
					<option value="MS">Mississippi</option>
					<option value="MO">Missouri</option>
					<option value="MT">Montana</option>
					<option value="NE">Nebraska</option>
					<option value="NV">Nevada</option>
					<option value="NH">New Hampshire</option>
					<option value="NJ">New Jersey</option>
					<option value="NM">New Mexico</option>
					<option value="NY">New York</option>
					<option value="NC">North Carolina</option>
					<option value="ND">North Dakota</option>
					<option value="OH">Ohio</option>
					<option value="OK">Oklahoma</option>
					<option value="OR">Oregon</option>
					<option value="PA">Pennsylvania</option>
					<option value="RI">Rhode Island</option>
					<option value="SC">South Carolina</option>
					<option value="SD">South Dakota</option>
					<option value="TN">Tennessee</option>
					<option value="TX">Texas</option>
					<option value="UT">Utah</option>
					<option value="VT">Vermont</option>
					<option value="VA">Virginia</option>
					<option value="WA">Washington</option>
					<option value="WV">West Virginia</option>
					<option value="WI">Wisconsin</option>
					<option value="WY">Wyoming</option>
				</select>
				<div className="invalid-feedback">{errors.mailingAddress?.state}</div>
			</div>

			<div className="form-group">
				<label>ZIP</label>
				<input
					type="number"
					className="form-control"
					name="mailingAddress.zipCode"
					value={values.mailingAddress?.zipCode || ""}
					onChange={handleChange}
				/>
			</div>
		</Fragment>
	)
}

export default AddressForm;