import React from 'react';

const Progress = ({ progressPercent, barHeight }) => {
        return (
            <div className="progress my-4" style={{height: barHeight + 'px'}}>
                <div 
                className="progress-bar"                 
                role="progressbar" 
                style={{width: progressPercent + '%', backgroundColor: '#2CC36B'}}
                aria-valuenow={{progressPercent}} 
                aria-valuemin="0" 
                aria-valuemax="100">
                </div>
            </div>
        );
}

Progress.defaultProps = {}

export default Progress;