import API from "./API";


const getPatient = async (patientId) => {
    return await API.get(process.env.REACT_APP_API_BASE_URL + "/records/patients/find/" + patientId)
};

const getPrimaryPatient = async () => {
    return await API.get(process.env.REACT_APP_API_BASE_URL + "/records/patients/primary")
};


const RecordsService = {
    getPatient,
    getPrimaryPatient
}

export default RecordsService;