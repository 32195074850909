export default function validate(values) {
    
    let errors = {};

    if (!values.calendarID) {
        errors.calendarID = "Acuity ID is required.";
    }

    if (!values.location) {
        errors.location = "Name/Location is required.";
    }
    
    return errors;
};