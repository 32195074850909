import React, { Fragment, useEffect, useState } from 'react';
import { useParams, Link } from "react-router-dom";
import ReportService from '../../../services/reports.service';

const ReportList = () => {
    const [years, setYears] = useState([]);

    let params = useParams();

    useEffect(() => {
        //Load report list
        ReportService.getListOfReports(params.employerId).then((res) => {
            let years = [];

            res.data.forEach((period) => {                
                if (!years.includes(period.year)) { 
                    years.push(period.year);
                }
            });

            setYears(years);
        })
    }, [params.employerId]);

    return (
        <Fragment>
            <h1 className="my-3">Year-to-Date Reports</h1>
            <p>There are currently {years.length} reports available. New year-to-date reports will be available immediately with each new year. Existing reports will udpate in real-time as new data is made available.</p>

            {years.map((year) => (
                <li key={year}>
                    <Link to={`/employer/report/utilization/ytd/${params.employerId}/${year}`}>{year}</Link></li>
            ))}
        </Fragment>
    );
}

ReportList.defaultProps = {}

export default ReportList;