import React, { Fragment, useState, useEffect } from "react";
import AppointmentService from "../../services/appointment.service";
import EmployerService from '../../services/employer.service';
import RecordsService from "../../services/records.service";
import * as CONSTANT from "../../constants/constants";
import AuthService from '../../services/auth.service';
import PaymentService from '../../services/payment.service';
import FlashMessage from "../FlashMessage";

//Appointment Components
import Modality from "./Modality";
import Product from "./Product";
import Symptoms from "./Symptoms";
import Medications from "./History/Medications";
import Allergies from "./History/Allergies";
import Conditions from "./History/Conditions";
import Surgeries from "./History/Surgeries";
import Family from "./History/Family";
import Alternative from './Alternative';
import Confirm from "./Confirm";
import ConfirmRequest from './Confirm/request';
import Confirmation from "./Confirmation";
import Summary from "./Summary";
import AppointmentDateTime from "./DateTime";

import { useHistory } from "react-router-dom";
import Progress from "./Progress";
import AppointmentNavigation from "./Navigation";

const BookAppointment = ({ currentUser, match }) => {
	const [flashMessage, setFlashMessage] = useState("");
	const [flashMessageType, setFlashMessageType] = useState("");

	const [appointmentDetails, setAppointmentDetails] = useState({});
	const [products, setProducts] = useState([]);
	const [employer, setEmployer] = useState(null);
	const [appointmentProcessing, setAppointmentProcessing] = useState(false);

	const [progressPercent, setProgressPercent] = useState(5);

	const history = useHistory();
	const patientId = match.params.patient;
	const step = match.params.step;

	//Set skeleton appointment object	
	useEffect(() => {
		let savedAppointment = AppointmentService.getSessionAppointment();

		//If there is no saved appointment, create a shell so we can save progress
		//in the event the user has to come back or an unintentional refresh happens
		//Do not create the shell if this is a confirmation - it has been scheduled and should be deleted from session storage
		if (step !== "confirm" && step !== "confirmation") {
			if (savedAppointment === null || (typeof savedAppointment !== "undefined" && Object.keys(savedAppointment).length === 0)) {
				//Set empty appointment shell
				setAppointmentDetails({
					//modality: "",
					//datetime is the complete date object passed to Acuity and saved to Appointments collection
					datetime: null,

					location: {
						calendarID: null,
						location: null,
					},

					product: {
						price: 0,
					},

					symptoms: [],
					medications: [{ name: "", howLong: "" }],
					allergies: [],
					conditions: [],
					surgeries: [{ description: "" }],
					familyHistory: null,

					alternative: "Unknown",

					contact: CONSTANT.CONTACT.PHONE,
					
					patient: {},
					receipt: {},
					/* TODO: revisit this. For now, removing this from the object 
							because it causes a payment error and no payments are being 
							accepted at this time
					paymentMethod: {
						id: null,
					},
					*/
					reminderEmail: null,
					reminderPhone: null,

					requestContactPhone: null,

					modality: "Unknown", //on-demand or on-site
					//state: defaults to TN, othewise set on telehealth calls on confirmation screen

				});
			} else {
				setAppointmentDetails(savedAppointment);
			}
		}
	}, [step]);

	useEffect(() => {
		let currentUser = AuthService.getCurrentUser();

		setAppointmentDetails((appointmentDetails) => ({
			...appointmentDetails,
			reminderEmail: currentUser.email || null,
			reminderPhone: currentUser.mobile || null
		}))

		EmployerService.getEmployer(currentUser?.employer._id).then((res) => {
			setEmployer(res.data);
		});
	}, []);

	useEffect(() => {		
		PaymentService.findProductsByCategory(appointmentDetails.modality).then(function (products) {
			setProducts(products.data);
		});
	}, [appointmentDetails.modality])

	//Set patient
	useEffect(() => {
		if (patientId === "self") {
			let allergies = [];
			let conditions = [];
			let familyHistory = [];

			RecordsService.getPrimaryPatient().then((patient) => {
				allergies = patient.data.allergies.map((allergy) => (
					allergy.name
				));

				conditions = patient.data.conditions.map((condition) => (
					condition.name
				));

				familyHistory = patient.data.familyHistory.map((history) => (
					history.condition
				));


				setAppointmentDetails((appointmentDetails) => ({ 
					...appointmentDetails, 
					patient: patient.data,
					medications: patient.data.medications,
					allergies: allergies,
					conditions: conditions,
					surgeries: patient.data.surgeries,
					familyHistory: familyHistory
				}));
			});
			
		} else {
			RecordsService.getPatient(patientId).then((res) => {
				setAppointmentDetails((appointmentDetails) => ({ ...appointmentDetails, patient: res.data }));
			});
		}
	},[patientId]);

	useEffect(() => {
		//save the appointment automatically any time the details are updated
		AppointmentService.saveSessionAppointment(appointmentDetails);
	}, [appointmentDetails]);

	//Get patient cost for selected service
	useEffect(() => {
		let profile = AuthService.getCurrentUser();
		
		if (appointmentDetails.product?.productID) {
			PaymentService.getProductPricing(profile._id, appointmentDetails.product?.productID).then(function (price) {
				setPatientCost(price.data.price);	
			});
		}

	}, [appointmentDetails.product?.productID])


	//1: MODALITY =================================	

	const setAppointmentModality = (appointmentModality) => {
		setAppointmentDetails((appointmentDetails) => ({ 
			...appointmentDetails, 
			modality: appointmentModality
		}));
	};

	//2: PRODUCT ==================================

	const setAppointmentProduct = (appointmentProduct) => {
		setAppointmentDetails((appointmentDetails) => ({ 
			...appointmentDetails, 
			product: appointmentProduct
		}));	
	};

	//3: DATE AND TIME ============================

	/*
	This should be lumped into one Location object that fills the location and calendarID properties
	const setAppointmentCalendarId = (appointmentCalendarId) => {
		setAppointmentDetails((appointmentDetails) => ({
			...appointmentDetails,
			location: {
				...appointmentDetails.location,
				calendarID: appointmentCalendarId
			}
		}));
	}*/

	const setAppointmentLocation = (appointmentLocation) => {
		if (appointmentLocation === null) {
			setAppointmentDetails((appointmentDetails) => ({
				...appointmentDetails,
				location: {				
					location: null,
					calendarID: null
				}
			}));
		} else {
			setAppointmentDetails((appointmentDetails) => ({
				...appointmentDetails,
				location: {				
					location: appointmentLocation.location,
					calendarID: appointmentLocation.calendarID
				}
			}));
		}
	}

	const setAppointmentDateTime = (appointmentDateTime) => {
		setAppointmentDetails((appointmentDetails) => ({
			...appointmentDetails,
			datetime: appointmentDateTime
		}));		
	};

	//4: SYMPTOMS =================================

	const setAppointmentSymptoms = (appointmentSymptoms) => {
		setAppointmentDetails((appointmentDetails) => ({
			...appointmentDetails,
			symptoms: appointmentSymptoms
		}));		
	};

	//5: MEDICATIONS ==============================

	const setAppointmentMedications = (appointmentMedications) => {
		setAppointmentDetails((appointmentDetails) => ({
			...appointmentDetails,
			medications: appointmentMedications
		}));		
	};

	//6: ALLERGIES =================================

	const setAppointmentAllergies = (appointmentAllergies) => {
		setAppointmentDetails((appointmentDetails) => ({ 
			...appointmentDetails, 
			allergies: appointmentAllergies
		}));		
	};

	//7: CONDITIONS ================================

	const setAppointmentConditions = (appointmentConditions) => {
		setAppointmentDetails((appointmentDetails) => ({ 
			...appointmentDetails, 
			conditions: appointmentConditions			
		}));		
	};

	//8: SURGERIES =================================

	const setAppointmentSurgeries = (appointmentSurgeries) => {
		setAppointmentDetails((appointmentDetails) => ({ 
			...appointmentDetails, 
			surgeries: appointmentSurgeries
		}));		
	};

	//9: FAMILY HISTORY ============================

	const setAppointmentFamilyHistory = (appointmentFamilyHistory) => {
		setAppointmentDetails((appointmentDetails) => ({
			...appointmentDetails,
			familyHistory: appointmentFamilyHistory
		}));		
	};

	//10: CARE ALTERNATIVE

	const setAppointmentAlternative = (appointmentAlternative) => {
		setAppointmentDetails((appointmentDetails) => ({
			...appointmentDetails,
			alternative: appointmentAlternative
		}));		
	};

	const setAppointmentReminderEmail = (appointmentReminderEmail) => {
		setAppointmentDetails((appointmentDetails) => ({
			...appointmentDetails,
			reminderEmail: appointmentReminderEmail
		}));
	};

	const setAppointmentReminderPhone = (appointmentReminderPhone) => {
		setAppointmentDetails((appointmentDetails) => ({
			...appointmentDetails,
			reminderPhone: appointmentReminderPhone
		}));
	};

	const setRequestContactPhone = (requestContactPhone) => {
		setAppointmentDetails((appointmentDetails) => ({
			...appointmentDetails,
			requestContactPhone: requestContactPhone
		}));
	}

	const setRequestState = (requestState) => {
		setAppointmentDetails((appointmentDetails) => ({
			...appointmentDetails,
			state: requestState
		}));
	}

	//11: CONFIRM APPOINTMENT DETAILS

	const handleConfirmAppointment = () => {
		//Schedule appointment when details are complete
		//User has pressed the "Confirm" button save appointment

		setAppointmentProcessing(true);
		setProgressPercent(100);
		
		AppointmentService.scheduleAppointment(appointmentDetails).then(function (response) {
			//Clear appointment data
			AppointmentService.clearSessionAppointment();
			
			setAppointmentProcessing(false);

			history.push({
				pathname: "/book-appointment/" + patientId + "/confirmation",
				state: { appointmentInformation: response.data }
			});
		}).catch((error) => {
			const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

			setAppointmentProcessing(false);

			setFlashMessage(resMessage);
			setFlashMessageType(CONSTANT.ALERT_TYPE.ERROR);
		});
		
	}

	const handleConfirmRequest = () => {
		//Send SMS to providers when details are complete
		//User has pressed the "Confirm" button save telemedicine request

		setAppointmentProcessing(true);
		setProgressPercent(100);
		
		AppointmentService.createRequest(appointmentDetails).then(function (response) {
			//Clear appointment data
			AppointmentService.clearSessionAppointment();
			
			setAppointmentProcessing(false);

			history.push({
				pathname: "/book-appointment/" + patientId + "/confirmation",
				state: { appointmentInformation: response.data }
			});

		}).catch((error) => {
			const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

			setAppointmentProcessing(false);

			setFlashMessage(resMessage);
			setFlashMessageType(CONSTANT.ALERT_TYPE.ERROR);
		});
	}

	const setPatientCost = (patientCost) => {
		setAppointmentDetails((appointmentDetails) => ({
			...appointmentDetails,
			product: {
				...appointmentDetails.product,
				price: patientCost,
			}
		}));
	};



	const setPaymentMethod = (paymentMethod) => {
		setAppointmentDetails((appointmentDetails) => ({
			...appointmentDetails,
			paymentMethod: {
				...appointmentDetails.paymentMethod,
				id: paymentMethod.id,
				last4: paymentMethod.card.last4,
				brand: paymentMethod.card.brand,
				exp_month: paymentMethod.card.exp_month,
				exp_year: paymentMethod.card.exp_year,
			},
		}));
	};

	const handleStartOver = () => {
		AppointmentService.clearSessionAppointment();
		history.push("/");
	}

	const handleBack = () => {
		history.goBack();
	}

	const handleNextStep = () => {
		switch (step) {
			case "visit-reason":
				setProgressPercent(20);

				//If the client is requesting on-demand, they do not pick a date and time.
				//The provider will confirm the appointment and set a start time
				if (appointmentDetails.modality === "on-demand") {
					history.push({
						pathname: "/book-appointment/" + patientId + "/symptoms",
					});
				} else {
					history.push({
						pathname: "/book-appointment/" + patientId + "/visit-date-time",
					});
				}
				break;

			case "visit-date-time":
				setProgressPercent(30);

				history.push({
					pathname: "/book-appointment/" + patientId + "/symptoms",
				});
				break;

			case "symptoms":
				setProgressPercent(40);

				history.push({
					pathname: "/book-appointment/" + patientId + "/medications",
				});
				break;

			case "medications":
				setProgressPercent(50);

				history.push({
					pathname: "/book-appointment/" + patientId + "/allergies",
				});
				break;

			case "allergies":
				setProgressPercent(60);

				history.push({
					pathname: "/book-appointment/" + patientId + "/conditions",
				});
				break;

			case "conditions":
				setProgressPercent(70);

				history.push({
					pathname: "/book-appointment/" + patientId + "/surgeries",
				});
				break;

			case "surgeries":
				setProgressPercent(80);

				history.push({
					pathname: "/book-appointment/" + patientId + "/family-history",
				});
				break;

			case "family-history":
				setProgressPercent(90);

				history.push({
					pathname: "/book-appointment/" + patientId + "/alternative",
				});
				break;

			case "alternative":
				setProgressPercent(95);

				history.push({
					pathname: "/book-appointment/" + patientId + "/confirm",
				});
				break;

			default:
				//modality
				setProgressPercent(10);

				//history.push({
				//	pathname: "/",
				//});
				history.push({
					pathname: "/book-appointment/" + patientId + "/visit-reason",
				});
				break;
		}
	}

	const renderStep = (step) => {
		switch (step) {
			case "visit-reason":
				return <Product 
					appointmentProduct={appointmentDetails.product} 
					employer={employer}
					products={products}
					handleSetAppointmentProduct={setAppointmentProduct} 
					handleNextClick={handleNextStep}  />;

			case "visit-date-time":
				return <AppointmentDateTime 
					appointmentDateTime={appointmentDetails.datetime} 
					appointmentTypeID={appointmentDetails.product?.appointmentTypeID}
					appointmentLocation={appointmentDetails.location}
					employer={employer}
					currentUser={currentUser}
					//handleSetAppointmentCalendarID={setAppointmentCalendarId}
					handleSetAppointmentLocation={setAppointmentLocation}
					handleSetAppointmentDateTime={setAppointmentDateTime} 
					handleNextClick={handleNextStep} />;

			case "symptoms":
				return <Symptoms 
					appointmentSymptoms={appointmentDetails.symptoms}
					handleSetAppointmentSymptoms={setAppointmentSymptoms}
					handleNextClick={handleNextStep} />;

			case "medications":
				return <Medications 
					appointmentMedications={appointmentDetails.medications}
					handleSetAppointmentMedications={setAppointmentMedications}
					handleNextClick={handleNextStep} />;

			case "allergies":
				return <Allergies 
					appointmentAllergies={appointmentDetails.allergies}
					handleSetAppointmentAllergies={setAppointmentAllergies}
					handleNextClick={handleNextStep} />;

			case "conditions":
				return <Conditions 
					appointmentConditions={appointmentDetails.conditions}
					handleSetAppointmentConditions={setAppointmentConditions}
					handleNextClick={handleNextStep} />;

			case "surgeries":
				return <Surgeries 
					appointmentSurgeries={appointmentDetails.surgeries}
					handleSetAppointmentSurgeries={setAppointmentSurgeries}
					handleNextClick={handleNextStep} />;

			case "family-history":
				return <Family 
					appointmentFamilyHistory={appointmentDetails.familyHistory}
					handleSetAppointmentFamilyHistory={setAppointmentFamilyHistory}
					handleNextClick={handleNextStep} />;

			case "alternative":
				return <Alternative 
					appointmentAlternative={appointmentDetails.alternative} 
					handleSetAppointmentAlternative={setAppointmentAlternative}
					handleNextClick={handleNextStep} />;

			case "confirm":				
					return (appointmentDetails.modality === "on-demand") ? 
						<ConfirmRequest
							currentUser={currentUser}
							appointmentProcessing={appointmentProcessing}
							handleSetRequestState={setRequestState}
							handleSetRequestContactPhone={setRequestContactPhone}
							handleSetPaymentMethod={setPaymentMethod}
							handleConfirmRequest={handleConfirmRequest}
							patientCost={appointmentDetails.product?.price}		
							paymentMethod={appointmentDetails.paymentMethod}				
						/> : 
						<Confirm
							reminderEmail={appointmentDetails.reminderEmail}
							reminderPhone={appointmentDetails.reminderPhone}
							appointmentProcessing={appointmentProcessing}
							handleSetAppointmentReminderPhone={setAppointmentReminderPhone}
							handleSetAppointmentReminderEmail={setAppointmentReminderEmail}
							handleSetPaymentMethod={setPaymentMethod}
							handleConfirmAppointment={handleConfirmAppointment}
							patientCost={appointmentDetails.product?.price}		
							paymentMethod={appointmentDetails.paymentMethod}				
						/>
					
				

			case "confirmation":
				return <Confirmation />;

			default:				
				return <Modality 
					appointmentModality={appointmentDetails.modality} 
					employer={employer}
					handleSetAppointmentModality={setAppointmentModality} 
					handleNextClick={handleNextStep} 
				/>;
		}
	};

	return (
		<Fragment>
			
			{step !== "confirmation" &&
				<AppointmentNavigation startOver={handleStartOver} back={handleBack} />
			}

			<Summary employer={employer} oAppointment={appointmentDetails} />

			{flashMessage.length > 0 && <FlashMessage message={flashMessage} messageType={flashMessageType} />}

			<Progress progressPercent={progressPercent} barHeight="8" />

			{renderStep(step)}

			{/*<pre className="bg-dark text-white mt-5">{JSON.stringify(appointmentDetails, null, 2)}</pre>*/}
		</Fragment>
	);
};

BookAppointment.defaultProps = {};

export default BookAppointment;
