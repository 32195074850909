import React, { Fragment } from "react";
import { format } from "date-fns";
import { enUS } from "date-fns/locale";
import parseISO from "date-fns/parseISO";
import { CSVLink } from "react-csv";

const MasterAppointmentList = ({ appointments }) => {

	return (
		<Fragment>

            <CSVLink data={appointments.map(appt => ({
                created: appt.created,
                modality: appt.modality,
                patientLast: appt.patient?.lastName,
                patientFirst: appt.patient?.firstName,
                location: appt.location?.location,
                type: appt.type,
                employer: appt.employer.name
            }))}>Download as CSV</CSVLink>

			<table className="table">
                <thead>
                    <tr>
                        <th>Created</th>
                        <th>Modality</th>
                        <th>Patient</th>
                        <th>Clinic</th>
                        <th>Appointment Reason</th>
                        <th>Employer</th>
                        <th>Appointment Date / Started</th>
                    </tr>
                </thead>
				<tbody>
					{appointments.map((appointment, index) => {
						return (

                            <tr key={appointment._id}>
                                <td>{format(parseISO(appointment.created), "MM/dd/yyyy h:mm b", { locale: enUS })}</td>
                                <td>{appointment.modality}</td>
                                <td>{appointment.patient?.lastName}, {appointment.patient?.firstName}</td>
                                <td>{appointment.location?.location}</td>
                                <td>{appointment.type || ""}</td>
                                <td>{appointment.employer.name}</td>
                                <td>
                                    {appointment.modality === "on-site" &&
                                        format(parseISO(appointment.datetime), "MM/dd/yyyy h:mm b", { locale: enUS })
                                    }
                                    {appointment.modality === "on-demand" &&
                                        appointment.startTime?.length > 0 &&
                                            format(parseISO(appointment.startTime), "MM/dd/yyyy h:mm b", { locale: enUS })
                                    }
                                </td>
                            </tr>
						);
					})}
				</tbody>
			</table>
		</Fragment>
	);
};

export default MasterAppointmentList;
