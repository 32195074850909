import React, { useState, useEffect } from "react";
import AuthService from "../../../services/auth.service";
import useForm from "../../useForm";
import validate from "./ContactInformationValidationRules";
import * as CONSTANT from "../../../constants/constants";
import FlashMessage from "../../FlashMessage";
import ReturnHome from "../../Navigation/ReturnHome";

const ContactInformation = () => {
	const [flashMessage, setFlashMessage] = useState("");
	const [flashMessageType, setFlashMessageType] = useState("");

	const handleUpdateContact = () => {
		//'firstName', 'lastName', 'fullName', 'email', 'mobile', 'mailingAddress'
		let account = {
			firstName: values.firstName,
			lastName: values.lastName,
			email: values.email,
			mobile: values.mobile,
			mailingAddress: values.mailingAddress,
		};

		AuthService.updateMyAccount(account)
			.then((response) => {
				setFlashMessage("Account updated.");
				setFlashMessageType(CONSTANT.ALERT_TYPE.SUCCESS);
			})
			.catch((error) => {
				const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

				setFlashMessage(resMessage);
				setFlashMessageType(CONSTANT.ALERT_TYPE.ERROR);
			});
	};

	const { setValues, values, errors, handleChange, handleSubmit } = useForm(handleUpdateContact, validate);

	useEffect(() => {
		AuthService.getMyProfile().then((response) => {
			let account = response.data;
			setValues({ ...values, ...account });
		});
	}, []);

	return (
		<div id="contact-information">
			<ReturnHome />
			
			<h1 className="my-3">Contact Information</h1>

			{flashMessage.length > 0 && <FlashMessage message={flashMessage} messageType={flashMessageType} />}

			<div className="form-group">
				<label>First name</label>
				<input
					type="text"
					className="form-control"
					name="firstName"
					value={values.firstName || ""}
					onChange={handleChange}
					disabled={values.role === "employee"}
				/>
			</div>

			<div className="form-group">
				<label>Last name</label>
				<input
					type="text"
					className="form-control"
					name="lastName"
					value={values.lastName || ""}
					onChange={handleChange}
					disabled={values.role === "employee"}
				/>
			</div>

			<div className="form-group">
				<label>Email address</label>
				<input
					type="text"
					className="form-control"
					name="email"
					value={values.email || ""}
					onChange={handleChange}
					disabled={values.role === "employee"}
				/>
			</div>

			<div className="form-group">
				<label>Phone</label>
				<input type="text" className="form-control" name="mobile" value={values.mobile || ""} onChange={handleChange} />
			</div>

			<div className="form-group">
				<label>Mailing address</label>
				<input
					type="text"
					className="form-control"
					name="mailingAddress.address1"
					value={values.mailingAddress?.address1 || ""}
					onChange={handleChange}
				/>
			</div>

			<div className="form-group">
				<label>Suite / apartment / unit number (optional)</label>
				<input
					type="text"
					className="form-control"
					name="mailingAddress.address2"
					value={values.mailingAddress?.address2 || ""}
					onChange={handleChange}
				/>
			</div>

			<div className="form-group">
				<label>City</label>
				<input
					type="text"
					className="form-control"
					name="mailingAddress.city"
					value={values.mailingAddress?.city || ""}
					onChange={handleChange}
				/>
			</div>

			<div className="form-group">
				<label>State</label>
				<input
					type="text"
					className="form-control"
					name="mailingAddress.state"
					value={values.mailingAddress?.state || ""}
					onChange={handleChange}
				/>
			</div>

			<div className="form-group">
				<label>ZIP</label>
				<input
					type="number"
					className="form-control"
					name="mailingAddress.zipCode"
					value={values.mailingAddress?.zipCode || ""}
					onChange={handleChange}
				/>
			</div>

			<button className="btn btn-primary" onClick={handleSubmit}>
				Save changes
			</button>
		</div>
	);
};

ContactInformation.defaultProps = {};

export default ContactInformation;
