//AgencyDetail 
export const MODE = {
    EDIT: "EDIT",
    CREATE: "CREATE"
}

//Flash Message Alert Types
export const ALERT_TYPE = {
    INFO: "INFO",
    WARN: "WARN",
    ERROR: "ERROR",
    SUCCESS: "SUCCESS"
}

export const CONTACT = {
    PHONE: "601-565-0075",
    EMAIL: "hello@archerhealth.com"
}

export const APPT_TYPE = {
    SELF: "SELF",
    DEPENDENT: "DEPENDENT"
}

export const TIMEZONE = {
     CENTRAL: "America/Chicago",
     EASTERN: "America/New_York",
     PACIFIC: "America/Los_Angeles",
     MOUNTAIN: "America/Denver",
     ALASKA: "America/Anchorage",
     HAWAII: "Pacific/Honolulu"
}

export function getTimezoneName(timezone) {
    switch(timezone) {
        case TIMEZONE.CENTRAL:
            return "Central Time";
        case TIMEZONE.EASTERN:
            return "Eastern Time";
        case TIMEZONE.PACIFIC:
            return "Pacific Time";
        case TIMEZONE.MOUNTAIN:
            return "Mountain Time";
        case TIMEZONE.ALASKA:
            return "AKT";
        case TIMEZONE.HAWAII:
            return "HT";
        default:
            return "";
    }
}