import API from "./API";

const getListOfReports = async (employerId) => {
    return await API.get(process.env.REACT_APP_API_BASE_URL + "/reports/utilization/list/" + employerId);
}

const utilizationYTD = async(employerId, year) => {
    return await API.get(process.env.REACT_APP_API_BASE_URL + "/reports/utilization/ytd/" + employerId + "/" + year);
}

const ReportService = {
    getListOfReports,
    utilizationYTD
}

export default ReportService;