import React, { Fragment, useEffect, useState } from "react";
import AppointmentService from "../../../services/appointment.service";
import { useParams } from "react-router-dom";
import FlashMessage from "../../FlashMessage";
import * as CONSTANT from "../../../constants/constants";
import moment from "moment";
import useForm from "../../useForm";
import validate from "./AppointmentDetailValidationRules";
import { Link } from "react-router-dom";
import ReturnHome from "../../Navigation/ReturnHome";
//import { add } from "date-fns";
//import differenceInMinutes from "date-fns/differenceInMinutes";
import { Modal } from "react-bootstrap";
import AppointmentDateTime from "../../BookAppointment/DateTime";
import Loader from "../../UIHelpers/Loader";
import ProviderNotes from "./ProviderNotes";
import PatientSummary from "./PatientSummary";
import ProviderSummary from "./ProviderSummary";
import Biometrics from "./Biometrics";
import BiometricsService from "../../../services/biometrics.service";
import ModalHelper from "../../UIHelpers/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import BiometricsResults from "./BiometricsResults";
import CPTCodes from "./CPTCodes";
import { utcToZonedTime } from "date-fns-tz";
import { Bar, BarChart, CartesianGrid, Line, LineChart, Tooltip, XAxis, YAxis } from "recharts";
//import { set } from "date-fns";

const AppointmentDetail = ({ currentUser }) => {
	const params = useParams();
	const [appointment, setAppointment] = useState(null);
	const [appointmentBiometrics, setAppointmentBiometrics] = useState(null);
	const [providerNotes, setProviderNotes] = useState([]);

	const [flashMessage, setFlashMessage] = useState("");
	const [flashMessageType, setFlashMessageType] = useState("");

	const [cancelAppointment, setCancelAppointment] = useState(false);

	const [loading, setLoading] = useState(true);
	const [saving, setSaving] = useState(false);

	const [show, setShow] = useState(false);

	//biometrics history state data
	const [weightData, setWeightData] = useState([]);
	const [bloodPressureData, setBloodPressureData] = useState([]);
	const [bloodData, setBloodData] = useState([]);
	const [cholesterolData, setCholesterolData] = useState([]);

	const TABS = {
		PATIENT: "patient",
		BIOMETRICS: "biometrics",
		CODES: "codes",
		PROVIDERNOTES: "providernotes",
		HISTORY: "history",
	};

	const [tab, setTab] = useState(TABS.PATIENT);

	const handleClose = () => {
		//hide modals
		setShow(false);
		setCancelAppointment(false);
	};

	useEffect(() => {
		AppointmentService.getAppointmentById(params.appointmentId)
			.then((res) => {
				setAppointment(res.data);
				setLoading(false);
			})
			.catch((error) => {
				const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
setLoading(false);
				setFlashMessage(resMessage);
				setFlashMessageType(CONSTANT.ALERT_TYPE.ERROR);
			});

		BiometricsService.getAppointmentBiometrics(params.appointmentId)
			.then((res) => {
				console.log("GOT APPOINTMENT BIOMETRICS");
				setAppointmentBiometrics(res.data);
			})
			.catch((error) => {
				const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

				setFlashMessage(resMessage);
				setFlashMessageType(CONSTANT.ALERT_TYPE.ERROR);
			});
	}, [params.appointmentId]);

	useEffect(() => {
		if (!appointment) return;

		//the history is only for providers or admins
		if (currentUser.role === "provider" || currentUser.role === "admin") {
			BiometricsService.getScreeningsFromAccountID(appointment.account._id, appointment.patient._id)
				.then((res) => {				

					const weightData = res.data.map((screening) => ({
						date: screening.screeningDate,
						weight: screening.weight,
					}));

					const bloodPressureData = res.data.map((screening) => ({
						date: screening.screeningDate,
						systolic: screening.systolicBloodPressure,
						diastolic: screening.diastolicBloodPressure,
					}));

					const bloodData = res.data.map((screening) => ({
						date: screening.screeningDate,
						glucose: screening.glucose,
						hbA1c: screening.hbA1c,						
					}));

					const cholesterolData = res.data.map((screening) => ({
						date: screening.screeningDate,
						totalCholesterol: screening.cholesterolTotal,
						hdlCholesterol: screening.hdl,
						ldlCholesterol: screening.ldl,
						triglycerides: screening.triglycerides
					}));

					setWeightData(weightData);
					setBloodPressureData(bloodPressureData);
					setBloodData(bloodData);
					setCholesterolData(cholesterolData);
				})
				.catch((error) => {
					const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

					setFlashMessage(resMessage);
					setFlashMessageType(CONSTANT.ALERT_TYPE.ERROR);
				});
		}

		/* Notes are only for providers */
		if (currentUser.role === "provider") {
			AppointmentService.getNotes(appointment.patient._id)
				.then((res) => {
					const notes = res.data.map((appointmentNote) => ({
						appointmentId: appointmentNote._id,
						notes: appointmentNote.providerNotes,
					}));
					setProviderNotes(notes);
				})
				.catch((error) => {
					const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

					setFlashMessage(resMessage);
					setFlashMessageType(CONSTANT.ALERT_TYPE.ERROR);
				});		
		}
	}, [appointment, currentUser]);

	const setCancellationReason = (cancellationReason) => {
		setAppointment((appointment) => ({
			...appointment,
			cancelReason: cancellationReason,
		}));
	};

	const handleSaveProviderNotes = () => {
		setFlashMessage("");

		let notes = {
			appointmentId: params.appointmentId,
			provider: currentUser._id,
			providerNotes: values.providerNotes,
			noteDate: moment().utc().format(),
		};

		AppointmentService.addNotes(notes)
			.then((res) => {
				//Clear entered notes from textbox
				setValues((values) => ({
					...values,
					providerNotes: "",
				}));

				setAppointment(res.data);

				setFlashMessage("Appointment notes saved.");
				setFlashMessageType(CONSTANT.ALERT_TYPE.SUCCESS);
			})
			.catch((error) => {
				const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
				console.error(resMessage);

				setFlashMessage(resMessage);
				setFlashMessageType(CONSTANT.ALERT_TYPE.ERROR);
			});
	};

	const handleCancelAppointment = () => {
		setLoading(true);
		AppointmentService.cancelAppointment(appointment)
			.then((res) => {
				setLoading(false);
				setCancelAppointment(false);

				setAppointment((appointment) => ({
					...appointment,
					status: "canceled",
					canceledBy: res.data.canceledBy,
				}));
			})
			.catch((error) => {
				setLoading(false);
				const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
				console.error(resMessage);

				setFlashMessage(resMessage);
				setFlashMessageType(CONSTANT.ALERT_TYPE.ERROR);
			});
	};

	const handleStartRequest = () => {
		//navigate to telephone number

		//populate start time
		AppointmentService.startRequest(appointment._id)
			.then((res) => {})
			.catch((error) => {
				const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

				setFlashMessage(resMessage);
				setFlashMessageType(CONSTANT.ALERT_TYPE.ERROR);
			});

		const updatedAppointment = {
			...appointment,
			startTime: new Date(),
		};

		setAppointment(updatedAppointment);
	};

	const handleCompleteRequest = () => {
		AppointmentService.completeRequest(appointment).then((res) => {
			setAppointment((appointment) => ({
				...appointment,
				status: "completed",
				completedTime: res.data.completedTime,
			}));
		});
	};

	//Rescheduling methods -----------------------------

	const setAppointmentLocation = (appointmentLocation) => {
		if (appointmentLocation === null) {
			setAppointment((appointment) => ({
				...appointment,
				location: {
					location: null,
					calendarID: null,
				},
			}));
		} else {
			setAppointment((appointment) => ({
				...appointment,
				location: {
					location: appointmentLocation.location,
					calendarID: appointmentLocation.calendarID,
				},
			}));
		}
	};

	const setAppointmentDateTime = (appointmentDateTime) => {
		setAppointment((appointment) => ({
			...appointment,
			datetime: appointmentDateTime,
		}));
	};

	const handleConfirmRescheduleAppointment = () => {
		AppointmentService.rescheduleAppointment(appointment)
			.then((res) => {
				setFlashMessage("Your appointment has been rescheduled.");
				setFlashMessageType(CONSTANT.ALERT_TYPE.SUCCESS);
				setShow(false);
			})
			.catch((error) => {
				const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

				setFlashMessage(resMessage);
				setFlashMessageType(CONSTANT.ALERT_TYPE.ERROR);
			});
	};

	const handleBeginReschedule = () => {
		setAppointment((appointment) => ({
			...appointment,
			datetime: null,
			location: {
				location: null,
				calendarID: null,
			},
		}));

		setShow(true);
	};

	const cancellationReasons = {
		"on-demand": ["Patient didn't answer", "Requested service unavailable", "Patient got care elsewhere", "Other"],
		"on-site": ["No show", "Patient cancelled"],
	};

	/* End Rescheduling methods -----------------------*/

	const handleSaveScreening = (biometrics) => {
		setSaving(true);
		setFlashMessage("");

		//build screening object
		const screening = {
			...biometrics,
			appointment: params.appointmentId,
			account: appointment?.account?._id,
			employer: appointment?.account?.employer?._id,
		};

		BiometricsService.saveAppointmentBiometrics(screening)
			.then((res) => {
				setFlashMessage("Screening saved.");

				setAppointmentBiometrics(res.data);
			})
			.catch((error) => {
				const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

				setFlashMessage(resMessage);
				setFlashMessageType(CONSTANT.ALERT_TYPE.ERROR);
			})
			.finally(() => setSaving(false));
	};

	const handleSaveCPTCodes = (cptCodes, addOns) => {
		setSaving(true);
		setFlashMessage("");

		//build services object
		const services = {
			appointmentId: params.appointmentId,
			serviceAddons: addOns,
			serviceCodes: cptCodes,
		};

		AppointmentService.saveAppointmentServices(services)
		.then((res) => {
			setFlashMessage("Services saved.");
		})
		.catch((error) => {
			const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

			setFlashMessage(resMessage);
			setFlashMessageType(CONSTANT.ALERT_TYPE.ERROR);
		})
		.finally(() => setSaving(false));
	}

	const { setValues, values, errors, handleChange, handleSubmit } = useForm(handleSaveProviderNotes, validate);

	return (
		<div id="appointment-detail">
			<Loader message="Loading Appointment Details. One moment..." show={loading} />
			{appointment && (
				<Fragment>
					<ReturnHome />

					{appointment.status === "canceled" && (
						<Fragment>
							{(currentUser.role === "provider" || currentUser.role === "admin") && (
								<div className="alert alert-info">
									<strong>This appointment was canceled by the {appointment.canceledBy}.</strong>
								</div>
							)}

							{currentUser.role === "employee" && (
								<div className="alert alert-success mt-5">
									<h5>Appointment Canceled</h5>
									<p>Your appointment was successfully canceled.</p>
									<p>
										<Link to="/" className="btn btn-primary btn-block">
											Return Home
										</Link>
									</p>
								</div>
							)}
						</Fragment>
					)}

					{appointment.status !== "canceled" && (
						<Fragment>
							<h1 className="my-3">Appointment Detail</h1>

							<NavTabs
								role={currentUser.role}
								productID={appointment?.receipt?.items[0]?.productID}
								modality={appointment?.modality}
								handleSetTab={setTab}
								currentTab={tab}
								tabs={TABS}
							/>

							{flashMessage.length > 0 && <FlashMessage message={flashMessage} messageType={flashMessageType} />}

							{appointment.status === "completed" && (
								<div className="alert alert-success">The appointment has been marked completed.</div>
							)}

							{tab === TABS.PATIENT && (
								<Fragment>
									<AppointmentSummary
										role={currentUser.role}
										appointment={appointment}
										appointmentBiometrics={appointmentBiometrics}
										handleStartRequest={handleStartRequest}
										handleCompleteRequest={handleCompleteRequest}
										setCancelAppointment={setCancelAppointment}
									/>

									{(appointmentBiometrics !== null && currentUser.role === "employee") &&  
										<BiometricsResults appointmentBiometrics={appointmentBiometrics} />
									}
								</Fragment>
							)}

							{tab === TABS.CODES && (
								<CPTCodes 
									appointment={appointment}
									handleSaveCPTCodes={handleSaveCPTCodes} />
							)}

							{tab === TABS.BIOMETRICS && (
								<Fragment>
									{appointmentBiometrics === null && <Biometrics handleSaveScreening={handleSaveScreening} />}

									{appointmentBiometrics !== null && <BiometricsResults appointmentBiometrics={appointmentBiometrics} />}
								</Fragment>
							)}

							{/* Provider notes only available on telehealth requests */}
							{tab === TABS.PROVIDERNOTES && (
								<ProviderNotes
									values={values}
									errors={errors}
									handleChange={handleChange}
									appointmentId={params.appointmentId}
									providerNotes={providerNotes}
									handleSubmit={handleSubmit}
								/>
							)}

							{tab === TABS.HISTORY && (
								<Fragment>
									<h4 style={{marginBottom: "40px"}}>Biometric History</h4>

									<h6>Weight</h6>
										<LineChart width={730} height={250} data={weightData}>
											<XAxis dataKey="date" />
											<YAxis />
											<Tooltip />
											<CartesianGrid strokeDasharray="3 3" />
											<Line type="monotone" dataKey="weight" stroke="#8884d8" />
										</LineChart>

									<p style={{marginBottom: "40px"}}></p>

									<h6>Blood Pressure</h6>
										<LineChart width={730} height={250} data={bloodPressureData}>
											<XAxis dataKey="date" />
											<YAxis />
											<Tooltip />
											<CartesianGrid strokeDasharray="3 3" />
											<Line type="monotone" dataKey="systolic" stroke="#8884d8" />
											<Line type="monotone" dataKey="diastolic" stroke="#ff0000" />
										</LineChart>

									<p style={{marginBottom: "40px"}}></p>	

									<h6>Blood Values</h6>
										<LineChart width={730} height={250} data={bloodData}>
											<XAxis dataKey="date" />
											<YAxis />
											<Tooltip />
											<CartesianGrid strokeDasharray="3 3" />
											<Line type="monotone" dataKey="glucose" stroke="#8884d8" />
											<Line type="monotone" dataKey="hbA1c" stroke="#ff0000" />
											
										</LineChart>

									<p style={{marginBottom: "40px"}}></p>

									<h6>Cholesterol</h6>
									<BarChart width={730} height={250} data={cholesterolData} barSize={8}>
											<XAxis dataKey="date" />
											<YAxis />
											<Tooltip />
											<CartesianGrid strokeDasharray="3 3" />
											<Bar dataKey="totalCholesterol" fill="#8884d8" />
											<Bar dataKey="ldlCholesterol" fill="#ff0000" />
											<Bar dataKey="hdlCholesterol" fill="#cc0000" />
											<Bar dataKey="triglycerides" stroke="#dd0000" />
										</BarChart>

								</Fragment>
							)}

							{/* If the appointment is not in the past, allow them to cancel or reschedule */}


								<div className="row bg-light p-3">
									<div className="col-12">
										{appointment.modality !== "on-demand" && (
											<button className="btn btn-outline-primary mr-3" onClick={handleBeginReschedule}>
												Reschedule Appointment
											</button>
										)}

										<button className="btn btn-outline-secondary" onClick={() => setCancelAppointment(true)}>
											Cancel Appointment
										</button>
									</div>
								</div>

						</Fragment>
					)}

					{/* Modals */}

					{/* Reschedule Appointment Modal */}
					<Modal show={show} onHide={handleClose} animation={false}>
						<Modal.Header closeButton>
							<Modal.Title>Reschedule Appointment</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<AppointmentDateTime
								appointmentDateTime={appointment.datetime}
								appointmentLocation={appointment.location}
								employer={appointment.employer}
								currentUser={currentUser}
								handleSetAppointmentLocation={setAppointmentLocation}
								handleSetAppointmentDateTime={setAppointmentDateTime}
								handleNextClick={handleConfirmRescheduleAppointment}
								showHeader={false}
							/>
						</Modal.Body>
					</Modal>

					{/* Cancel Appointment Modal */}
					<Modal show={cancelAppointment} onHide={handleClose} animation={false}>
						<Modal.Header closeButton>
							<Modal.Title>Cancel Appointment</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							{["employee", "employer"].includes(currentUser.role) && (
								<Fragment>
									<div className="alert alert-warning">
										<strong>Are you sure you want to cancel this appointment?</strong>
									</div>

									<p>Once cancelled, appointment cannot be reinstated.</p>

									<div className="form-row">
										<div className="col">
											<button className="btn btn-primary btn-block" onClick={() => setCancelAppointment(false)}>
												Do not cancel
											</button>
										</div>
										<div className="col">
											<button
												className="btn btn-outline-primary btn-block"
												onClick={() => handleCancelAppointment()}>
												Cancel appointment
											</button>
										</div>
									</div>
								</Fragment>
							)}

							{["provider", "admin"].includes(currentUser.role) && (
								<Fragment>
									<p>
										You must select a cancellation reason. This will be shared with the patient in an automatically
										generated cancellation notification.
									</p>

									{cancellationReasons[appointment.modality === "on-demand" ? "on-demand" : "on-site"].map(
										(cancelReason) => (
											<div className="form-check" key={cancelReason}>
												<input
													type="radio"
													className="form-check-input"
													value={cancelReason}
													checked={appointment.cancelReason === cancelReason}
													onChange={(e) => setCancellationReason(e.target.value)}
												/>
												<label className="form-check-label">{cancelReason}</label>
											</div>
										)
									)}

									<div className="form-row">
										<div className="col">
											<button className="btn btn-primary btn-block" onClick={() => setCancelAppointment(false)}>
												Do not cancel
											</button>
										</div>
										<div className="col">
											<button
												className="btn btn-outline-primary btn-block"
												onClick={() => handleCancelAppointment()}
												disabled={!appointment.cancelReason}>
												Send cancellation
											</button>
										</div>
									</div>
								</Fragment>
							)}

							<Loader show={loading} message="Requesting cancellation..." />
						</Modal.Body>
					</Modal>

					{/* Saving Modal */}
					<ModalHelper show={saving}>
						<p>
							<FontAwesomeIcon icon={faCircleNotch} spin /> <em>Saving...</em>
						</p>
					</ModalHelper>
				</Fragment>
			)}
		</div>
	);
};

//Return the correct summary component based on the user's role
const AppointmentSummary = ({ role, appointment, appointmentBiometrics, handleStartRequest, handleCompleteRequest, setCancelAppointment }) => {
	if (["provider", "admin"].includes(role)) {
		return (
			<ProviderSummary
				appointment={appointment}
				appointmentBiometrics={appointmentBiometrics}
				handleStartRequest={handleStartRequest}
				handleCompleteRequest={handleCompleteRequest}
				setCancelAppointment={setCancelAppointment}
			/>
		);
	}

	if (["employee", "employer"].includes(role)) {
		return <PatientSummary appointment={appointment} />;
	}
};

const NavTabs = ({ role, productID, modality, handleSetTab, currentTab, tabs }) => {
	let showNav = false;

	if (["admin", "provider"].includes(role)) {
		showNav = true;
		/* If there is a need to show at least two of the tabs, 
		we will show the navigation, otherwise
		if it's just appointment details, do not show the nav 
		if (["onsite_biometric_screening", "onsite_thrive_fair"].includes(productID)) {
			showNav = true;
		}
		if (role === "provider") {
			showNav = true;
		}
		*/
	}

	return (
		<Fragment>
			{showNav && (
				<nav className="nav nav-fill py-3 mb-5">
					<a className={`nav-link ${currentTab === tabs.PATIENT && "active"}`} onClick={() => handleSetTab(tabs.PATIENT)}>
						Appointment Details
					</a>
					<a className={`nav-link ${currentTab === tabs.CODES && "active"}`} onClick={() => handleSetTab(tabs.CODES)}>
						CPT Codes
					</a>


					{/* Biometrics only available to providers when appointment type is biometric screening */}
					{AppointmentService.biometric_screening_types().includes(productID) && (
						<a className={`nav-link ${currentTab === tabs.BIOMETRICS && "active"}`} onClick={() => handleSetTab(tabs.BIOMETRICS)}>
							Biometrics
						</a>
					)}

					<a className={`nav-link ${currentTab === tabs.HISTORY && "active"}`} onClick={() => handleSetTab(tabs.HISTORY)}>
						Biometric History
					</a>


					{/* Provider notes only available on telehealth requests */}
					{modality === "on-demand" && role === "provider" && (
						<a
							className={`nav-link ${currentTab === tabs.PROVIDERNOTES && "active"}`}
							onClick={() => handleSetTab(tabs.PROVIDERNOTES)}>
							Provider Notes
						</a>
					)}
				</nav>
			)}
		</Fragment>
	);
};

AppointmentDetail.defaultProps = {};

export default AppointmentDetail;
